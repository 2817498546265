import { useState, useEffect, useContext } from 'react';
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import { auth } from '../../utils/firebaseconfig';
import UserContext from '../../utils/usercontext';
import LinkPrivateMenu from './LinkPrivateMenu';
import { useLinkNavigation } from '../utils/MenuFunctions';
import {ReactComponent as ReactLogo} from '../../assets/logo_kease.svg';

interface Props {
    activePage: string; // Valeur de la page active
    createLink: (link: string) => void; // Fonction pour créer un lien
  }

const PrivateMenu: React.FC<Props> = ({ activePage, createLink }) => {
    const { expanded, expandedMenu, toggleExpanded, toggleExpandedMenu } = useLinkNavigation();
    const { setUser, userProfile, setUserProfile } = useContext(UserContext);
    const navigate = useNavigate();
    const [isSmallScreen, setIsSmallScreen] = useState(false);
    const [shouldLogout, setShouldLogout] = useState(false);

    const handleClick = (useLink: any) => {
        createLink(useLink);
    };

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth < 1200);
        };
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        if (shouldLogout) {
            auth.signOut().then(() => {
                setUserProfile(null);
                setUser(null);
                navigate('/login');
            });
            setShouldLogout(false);
        }
    }, [shouldLogout, setUser, setUserProfile, navigate]);

    return (
        <>
        {auth.currentUser && (
            <Navbar className="p-0 navbar-kease" expanded={expanded} onToggle={toggleExpanded} bg="dark" variant="dark" fixed="top" expand="xl">
                <Container fluid>
                    <Navbar.Brand onClick={() => handleClick("")}>
                        <ReactLogo className="bi bi-cloud-check " width="150" height="50" />
                    </Navbar.Brand>
                    
                    <Navbar.Toggle />

                    {expandedMenu && 
                        <LinkPrivateMenu show onExpandedChange={toggleExpanded} activePage={activePage} createLink={createLink} />
                    }

                    {!expandedMenu && !isSmallScreen &&
                        <LinkPrivateMenu show onExpandedChange={toggleExpanded} activePage={activePage} createLink={createLink} />
                    }
                    
                    <Navbar.Collapse className="text-white justify-content-end m-3">
                        <Nav className="justify-content-end">
                            <NavDropdown onClick={() => toggleExpandedMenu()} drop='down' align="end" title={auth.currentUser?.email || ''}>
                                {userProfile?.statut === 'admin' && (
                                    <>
                                        <NavDropdown.Item onClick={() => handleClick("admin")}>Administrateur</NavDropdown.Item>
                                        <NavDropdown.Item onClick={() => handleClick("gestion-droits")}>Gestion des droits</NavDropdown.Item>
                                        <NavDropdown.Divider />
                                    </>
                                )}
                                <NavDropdown.Item onClick={() => handleClick("user-profile")}>Paramètres</NavDropdown.Item>
                                <NavDropdown.Item onClick={() => handleClick("contact-us")}>Contact</NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Divider />
                                <NavDropdown.Item onClick={() => setShouldLogout(true)}>Se déconnecter</NavDropdown.Item>
                            </NavDropdown>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        )}
        </>
    );
};

export default PrivateMenu;

