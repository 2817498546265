import React, { useRef, useState } from "react"
import {
    createUserWithEmailAndPassword,
  } from "firebase/auth";
import { Form, Button, Card, Alert, Col } from "react-bootstrap"
import { auth, dbCollection } from "../../utils/firebaseconfig";
import { Link, useNavigate } from "react-router-dom"
import { set, ref } from "firebase/database";

const CreateAccount: React.FC = () => {

    const emailRef = useRef<HTMLInputElement>(null);
    const passwordRef = useRef<HTMLInputElement>(null);
    const confirmpasswordRef = useRef<HTMLInputElement>(null);
    const firstnameRef = useRef<HTMLInputElement>(null);
    const lastnameRef = useRef<HTMLInputElement>(null);
    const phoneRef = useRef<HTMLInputElement>(null);
    const entityRef = useRef<HTMLInputElement>(null);
    const [error, setError] = useState("")
    const navigate = useNavigate()

    async function handleSubmit(event:any) {
        event.preventDefault()
    
        if (passwordRef.current!.value !== confirmpasswordRef.current!.value) {
          return setError("Les mots de passe ne sont pas identiques")
        }
        try {
            setError("")
            const userfirebase = await createUserWithEmailAndPassword(
                auth,
                emailRef.current!.value,
                passwordRef.current!.value
            );
            const user = userfirebase.user;
            await set(ref(dbCollection, 'users/' + user.uid), {
                email: user.email,
                firstname : firstnameRef.current!.value,
                lastname : lastnameRef.current!.value,
                phone : phoneRef.current!.value,
                entity : entityRef.current!.value,
                statut : 'en attente validation',
            });
            navigate('/dashboard')
        } catch {
          setError("Impossible de créer votre compte - veuillez réessayer ultérieurement ou contacter un administrateur")
        }
      }

    return (
    <>
        <Card className="border-0 mx-auto" style={{ width: '30rem' }}>
            <Card.Body>
                <h2 className="text-center mb-3">Créer un compte</h2>
                {error && <Alert variant="danger">{error}</Alert>}
                <Form onSubmit={handleSubmit}>

                <Form.Group className="mb-3  w-100" id="email">
                    <Form.Control type="email" ref={emailRef} placeholder="email" required />
                </Form.Group>
                <Form.Group className="mb-3 row" id="password">
                    <Col xs={6}>    
                        <Form.Control type="password" ref={passwordRef} placeholder="Mot de passe" required />
                    </Col>
                    <Col xs={6}>
                        <Form.Control type="password" ref={confirmpasswordRef} placeholder="Confirmer mot de passe" required />
                    </Col>
                </Form.Group>
                <Form.Group className="mb-3 row" id="name">
                    <Col xs={6}>    
                        <Form.Control type="name" ref={firstnameRef} placeholder="Prénom" required />
                    </Col>
                    <Col xs={6}>
                        <Form.Control type="name" ref={lastnameRef} placeholder="Nom" required />
                    </Col>
                </Form.Group>
                <Form.Group className="mb-3 row" id="contact">
                    <Col xs={6}>    
                        <Form.Control type="phone" ref={phoneRef} placeholder="Téléphone" required />
                    </Col>
                    <Col xs={6}>
                        <Form.Control type="string" ref={entityRef} placeholder="Société" required />
                    </Col>
                </Form.Group>
                <Button className="mb-3 btn-kease w-100" type="submit">
                    Créer son compte
                </Button>
                </Form>
            </Card.Body>
        </Card>
        <div className="w-100 text-center mt-2">
        Vous avez déjà un compte? <Link to="/login">Se connecter</Link>
        </div>
    </>
    )
}

export default CreateAccount