import React, { useContext, useRef, useState } from "react";
import { Form, Button, Card, Alert } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth, dbCollection } from "../../utils/firebaseconfig";
import UserContext from '../../utils/usercontext';
import { onValue, ref } from "firebase/database";

interface Props {
  createLink: (link: string) => void; // Fonction pour créer un lien
}

const Login: React.FC<Props> = ({ createLink }) => {
  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const [error, setError] = useState("");
  const { setUserProfile } = useContext(UserContext);

  function signIn() {
    setError("");
    const email = emailRef.current?.value;
    const password = passwordRef.current?.value;

    if (email && password) {
      signInWithEmailAndPassword(auth, email, password)
        .then(() => {
          const user = auth.currentUser;
          if (user) {
            const userProfileRef = ref(dbCollection, "users/" + user.uid);
            onValue(userProfileRef, (snapshot) => {
              const userProfileData = snapshot.val();
              if (userProfileData) {
                setUserProfile(userProfileData);
                createLink("dashboard"); // Navigate to dashboard after successful sign in
              } else {
                setError("Erreur lors de la récupération des données du profil utilisateur.");
              }
            });
          } else {
            setError("Erreur lors de la récupération des informations de l'utilisateur.");
          }
        })
        .catch(() => {
          setError("Impossible de vous connecter");
        });
    } else {
      setError("Veuillez remplir tous les champs.");
    }
  }

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    signIn();
  }

  return (
    <>
      <Card className="border-0 mx-auto">
        <Card.Body className="mx-auto">
          <h2 className="text-center mb-4 text-kease">Connexion</h2>
          {error && <Alert variant="danger">{error}</Alert>}
          <Form className="mx-auto" onSubmit={handleSubmit}>
            <Form.Group className="mb-3 mx-auto" id="email">
              <Form.Control
                className="mx-auto"
                type="email"
                ref={emailRef}
                placeholder="email"
                required
              />
            </Form.Group>
            <Form.Group className="mb-3 mx-auto" id="password">
              <Form.Control
                className="mx-auto"
                type="password"
                ref={passwordRef}
                placeholder="Mot de passe"
                required
              />
            </Form.Group>
            <Button className="mx-auto w-100 btn-kease border-kease" type="submit">
              Se connecter
            </Button>
          </Form>
          <br />
          <div className="mx-auto text-center mb-3">
            <Link to="/forgot-password">Oublie de votre mot de passe?</Link>
          </div>
        </Card.Body>
      </Card>
      <Card className="border-0 mx-auto">
        <Card.Text className="mx-auto">
          Vous n'avez pas encore de compte ? <Link to="/create-account">S'inscrire</Link>
        </Card.Text>
      </Card>
      <br />
    </>
  );
};

export default Login;
