import { Navigate, Outlet, Route, Routes, useRoutes } from 'react-router-dom';
import { useContext } from 'react';

import Login from '../../components/auth/Login';
import CreateAccount from '../../components/auth/CreateAccount';

import Dashboard from '../../components/auth/Dashboard';
import Profil from '../../components/auth/UserProfile';

import Userwaitvalidation from '../../components/auth/UserValidation';
import NewEngagement from '../../components/engagement/NewEngagement';
import About from '../../components/onepage/About';
import Onepage from '../../components/onepage/OnePage';
import Confidentialite from '../../components/legal/Confidentialite';
import Adaptable from '../../components/onepage/OnepageDetail/Adaptable';
import Assistance from '../../components/onepage/OnepageDetail/Assistance';
import Cloud from '../../components/onepage/OnepageDetail/Cloud';
import Collaboration from '../../components/onepage/OnepageDetail/Collaboration';
import Securite from '../../components/onepage/OnepageDetail/Securite';
import Suivi from '../../components/onepage/OnepageDetail/Suivi';
import Contact from '../../components/onepage/Contact';
import MentionsLegal from '../../components/legal/legalmentions';

import RequiredAuth from './RequiredAuth';
import ValidatedAuth from './ValidatedAuth';
import AdminAuth from './AdminAuth';
import UserContext from '../usercontext';
import Admin from '../../components/auth/admin/Admin';
import EngagementMenuWrapper from '../../components/engagement/EngagementMenuWrapper';
import EngagementDashboard from '../../components/engagement/EngagementDashboard';
import ExcelImport from '../../components/engagement/dataimport/ExcelImport';
import EngagementTeam from '../../components/engagement/EngagementTeam';
import EngagementParameters from '../../components/engagement/EngagementParameters';
import EngagementKnowledge from '../../components/engagement/works/knowledge/EngagementKnowledge';
import EngagementControleInterne from '../../components/engagement/works/interim/EngagementInterim';
import EngagementFinal from '../../components/engagement/works/final/EngagementFinal';
import EngagementReport from '../../components/engagement/works/opinion/EngagementOpinion';
import ConditionsGénérales from '../../components/legal/ConditionsGenerales';
import Dynamique from '../../components/onepage/Features/Dynamique';
import DataImport from '../../components/engagement/dataimport/DataImport';
import BGImport from '../../components/engagement/dataimport/BGImport';
import Solution from '../../components/onepage/Solution';
import Gestiondroits from '../../components/auth/admin/Gestiondroits';

interface Props {
  activePage: string; // Valeur de la page active
  createLink: (link: string) => void; // Fonction pour créer un lien
}

const AppRouter : React.FC<Props> = ({ activePage, createLink }) => {

 // Vérifiez si l'utilisateur est déjà connecté lors du chargement de l'application
 const { user, userProfile } = useContext(UserContext);
 
  return (
    <>
      <br/>
      <br/>
      <br/>
      <br/>
      <Routes>
        <Route path={'/'} element={<Onepage />}/>
        
        <Route path={'/realiser-un-audit-legal-sur-mesure'} element={<Adaptable />}/>
        <Route path={'/assistance-dediee-commissaires-aux-comptes'} element={<Assistance />}/>
        <Route path={'/avantages-mode-saas-et-cloud-logiciel-cac'} element={<Cloud />}/>
        <Route path={'/collaboration-client-echange-donnees-logiciel-audit-financier'} element={<Collaboration />}/>
        <Route path={'/securite-donnees-daudit'} element={<Securite />}/>
        <Route path={'/suivi-avancee-missions-temps-reel'} element={<Suivi />}/>

        <Route path={'/create-account'} element={<CreateAccount />}/>
        <Route path={'/about-us'} element={<About />}/>
        <Route path={'/politique-de-confidentialite'} element={<Confidentialite />}/>
        <Route path={'/conditions-generales-de-vente'} element={<ConditionsGénérales />}/>
        <Route path={'/mentions-legales'} element={<MentionsLegal />}/>
        <Route path={'/contact-us'} element={<Contact />}/>
        <Route path={'/solution'} element={<Solution />}/>

        <Route path={'/test'} element={<Dynamique />}/>

        <Route path={'/login'} element={<Login createLink={createLink}/>}/>

        {user ? (
          <>
          <Route path={'/wait-validation'} element={<RequiredAuth><Userwaitvalidation /></RequiredAuth>}/>
          <Route path={'/user-profile'} element={<RequiredAuth><Profil /></RequiredAuth>}/>

          <Route path={'/dashboard'} element={<ValidatedAuth userProfile={userProfile}><Dashboard /></ValidatedAuth>}/>
          <Route path={'/new-engagement'} element={<ValidatedAuth userProfile={userProfile}><NewEngagement /></ValidatedAuth>}/>

          <Route path="/engagement/:uid/*" element={<ValidatedAuth userProfile={userProfile} children={<EngagementMenuWrapper />} />}>
            <Route path="dashboard" element={<EngagementDashboard />} />
            <Route path="import" element={<DataImport />} />
            <Route path="import-balance" element={<BGImport />}/>
            <Route path="connaissance" element={<EngagementKnowledge />}/>
            <Route path="controle-interne" element={<EngagementControleInterne />}/>
            <Route path="examen-final" element={<EngagementFinal />}/>
            <Route path="rapports" element={<EngagementReport />}/>
            <Route path="team" element={<EngagementTeam />} />
            <Route path="parametres" element={<EngagementParameters />} />
          </Route>

          <Route path={'/admin'} element={<AdminAuth userProfile={userProfile}><Admin/></AdminAuth>}/>
          <Route path={'/gestion-droits'} element={<AdminAuth userProfile={userProfile}><Gestiondroits/></AdminAuth>}/>
          </>
          ) : (
          // Redirigez vers la page de connexion si l'utilisateur n'est pas connecté
            <>
            <Route path={'/wait-validation'} element={<Navigate to="/login" />} />
            <Route path={'/user-profile'} element={<Navigate to="/login" />}/>
            </>
          )}
        <Route path="*" element={<Onepage />}/>
      </Routes>
    </>
  );
};

export default AppRouter