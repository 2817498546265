import { useNavigate } from 'react-router-dom';
import '../../index.css';
import { useState } from 'react';

interface LinkNavigationState {
    expanded: boolean;
    expandedMenu: boolean;
    activePage:any,

    toggleExpanded: () => void;
    toggleExpandedMenu: () => void;
    createLink: (link:any) => void;
    setActivePage: (link:any) => void;
};

const initialLinkNavigationState: LinkNavigationState = {
    expanded: false,
    expandedMenu: true,
    toggleExpanded: () => {},
    toggleExpandedMenu: () => {},
    createLink: () => {},
    activePage:'',
    setActivePage: () => {},
};



export function useLinkNavigation(): LinkNavigationState {
    const navigate = useNavigate();
    const [state, setState] = useState<LinkNavigationState>(initialLinkNavigationState);
    const [activePage, setActivePage] = useState()

    function toggleExpanded() {
        setState(prevState => ({
          ...prevState,
          expanded: !prevState.expanded,
          expandedMenu: true,
        }));
    }

    function createLink(linky:any) {
        window.scrollTo(0, 0);
        setState(prevState => ({
            ...prevState,
            expanded: false,
            expandedMenu: true,
            activePage:linky,
        }));
        navigate('/' + linky, { replace: true });
    }

    function toggleExpandedMenu() {
        setState(prevState => ({
          ...prevState,
          expandedMenu: !prevState.expandedMenu,
        }));
    }
    
    return { ...state, toggleExpanded, toggleExpandedMenu, createLink };
}

export function formatfullDate(date: string) {
    const formattedDate = new Intl.DateTimeFormat('fr-FR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    }).format(new Date(date));
  
    return formattedDate;
  }

  export function formatsimpleDate(date: string) {
    const formattedDate = new Intl.DateTimeFormat('fr-FR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    }).format(new Date(date));
  
    return formattedDate;
  }