import React, { useContext, useEffect, useState } from 'react';
import EngagementMenuLeft from './EngagementMenuLeft';
import UserContext from '../../utils/usercontext';
import { Card, Col, Row } from 'react-bootstrap';
import EngagementRouter from './EngagementRouter';
import { onValue, ref } from 'firebase/database';
import { useParams } from 'react-router-dom';
import { dbCollection } from '../../utils/firebaseconfig';

const EngagementMenuWrapper: React.FC = () => {

    const { userProfile } = useContext(UserContext);
    const [data, setData] = useState();
    const [date, setDate] = useState();
    let params = useParams();
    useEffect(() => {
        onValue(ref(dbCollection, 'engagements/' + params.uid + '/name/'), avanty => {
            setData(avanty.val());
        });
        onValue(ref(dbCollection, 'engagements/' + params.uid + '/engagementDateCloture/'), avanty => {
            setDate(avanty.val());
        });
    }, []);
    
    return (
        <>
        <br/>
        <Card className="mx-auto border-0 font-0">
            <Card.Header>
            <h4 className="text-center">{data} - {date}</h4>
            </Card.Header>
        </Card>
        <Row>
            <Col className="col-2">
                <br/>
            <EngagementMenuLeft />
            </Col>
            <Col >
                <br/>
                <div className="">
                    <EngagementRouter />
                </div>
            </Col>
        </Row>
        </>
    );
}

export default EngagementMenuWrapper;
