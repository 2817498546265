import { useEffect, useState } from 'react';
import { Col, Nav, Row } from 'react-bootstrap';
import { useLinkNavigation } from '../utils/MenuFunctions';


const Confidentialite: React.FC = () => {

  useEffect(() => {
  }, []);

  const handleMenuLinkClick = (event : any) => {
    event.preventDefault(); // Empêche le comportement par défaut du lien
  
    const targetSectionId = event.target.getAttribute('href'); // Obtient l'ID de la section ciblée
    const targetSection = document.querySelector(targetSectionId); // Sélectionne la section ciblée
  
    if (targetSection) {
      const offset = targetSection.offsetTop - 100; // Calcule le décalage en soustrayant 100 pixels
      window.scrollTo({ top: offset, behavior: 'smooth' }); // Fait défiler la page jusqu'à la section avec une animation fluide
    }
  };

  const { createLink } = useLinkNavigation();
  const handleClick = (useLink:any) => {
    createLink(useLink); // Utilisation de la fonction createLink
  };

  return (
    <div className="about">
      <div className="container">
        <Row>
          <Col className="col-3 m-3">
          <Nav className="vertical-nav-menu custom-sticky-top border border-1 border-kease rounded-3">
            <p className="fw-bold m-2 ps-2">Table des matières</p>
            <Nav.Link href="#section1" className="nav-link small" onClick={handleMenuLinkClick}>
              1. Dans quels cas collectons-nous vos données à caractère personnel et quelles données sont collectées ?
            </Nav.Link>
            <Nav.Link href="#section2" className="nav-link small" onClick={handleMenuLinkClick}>
              2. Comment protégeons-nous vos données à caractère personnel ?
            </Nav.Link>
            <Nav.Link href="#section3" className="nav-link small" onClick={handleMenuLinkClick}>
              3. Dans quels cas partageons-nous vos données à caractère personnel ?
            </Nav.Link>
            <Nav.Link href="#section4" className="nav-link small" onClick={handleMenuLinkClick}>
            4. Combien de temps conservons-nous vos données à caractère personnel ?
            </Nav.Link>
            <Nav.Link href="#section5" className="nav-link small" onClick={handleMenuLinkClick}>
            5. Cookies : comment les utilisons-nous ?
            </Nav.Link>
            <Nav.Link href="#section6" className="nav-link small" onClick={handleMenuLinkClick}>
            6. Quels sont vos droits ?
            </Nav.Link>
            <Nav.Link href="#section7" className="nav-link small" onClick={handleMenuLinkClick}>
            7. Pouvons-nous modifier la Charte ?
            </Nav.Link>
            <Nav.Link href="#section8" className="nav-link small" onClick={handleMenuLinkClick}>
            8. La Commission Nationale de l'Informatique et des Libertés ("CNIL")
            </Nav.Link>
          </Nav>
          </Col>
          <Col  className="about__info mt-3">
            <h1 className="fw-bold">Charte sur le respect de la vie privée</h1>
            <br/>
            <p><em>Date de dernière mise à jour : 26 mai 2023</em></p>
            <br/>
            <p>
              La présente charte sur le respect de la vie privée (la « Charte ») a pour objectif de formaliser notre engagement quant au respect de la vie privée des utilisateurs du site internet www.kease.fr (le « Site ») exploité par PILOT CONSEILS.
            </p>
            <p>
              La Charte et les Conditions Générales du Site forment un ensemble contractuel. Tous les termes en majuscules non-définis dans la présente Charte sont définis dans les Conditions Générales consultables ici : <a onClick={() => handleClick("conditions-generales-de-vente")}>https://kease.fr/conditions-generales-de-vente</a>
            </p>
            <p>
            Dans le cadre de la mise à disposition de notre Site, nous traitons vos données à caractère personnel dans le respect du Règlement Général sur la Protection des Données 2016/679 du 27 avril 2016 (« RGPD ») et de la loi n° 78-17 du 6 janvier 1978 relative à l'informatique, aux fichiers et aux libertés, dont les dispositions ont été réécrites par l'ordonnance n° 2018-1125 du 12 décembre 2018 et dans les conditions exposées ci-après.
            </p>
            <p>
            Une donnée à caractère personnel désigne toute information se rapportant à une personne physique identifiée ou identifiable. Nous collectons et traitons des données à caractère personnel dans le cadre de la fourniture de nos Services ou de la communication à propos de ces Services exclusivement, en stricte conformité avec le RGPD.
            </p>
            <p>
              Nous collectons uniquement des données à caractère personnel adéquates, pertinentes et limitées à ce qui est nécessaire au regard des finalités pour lesquelles elles sont traitées. Ainsi, il ne vous sera jamais demandé de renseigner des données à caractère personnel considérées comme « sensibles », telles que vos origines raciales ou ethniques, vos opinions politiques, philosophiques ou religieuses.
            </p>
            <p>
              En vous enregistrant sur le Site, vous nous autorisez à traiter vos données à caractère personnel conformément à la Charte. Si vous refusez les termes de cette Charte, veuillez vous abstenir d'utiliser le Site ainsi que les Services.
            </p>
            <section id="section1">
            <h2 className="fw-bold">1. Dans quels cas collectons-nous vos données à caractère personnel et quelles données sont collectées ?</h2>
            <p>
            Nous sommes susceptibles de recueillir et de conserver vos données à caractère personnel, notamment lorsque vous :
            </p>
            <ul>
              <li>naviguez sur le Site ;</li>
              <li>créez votre compte ;</li>
              <li>payez en ligne ;</li>
              <li>vous inscrivez à notre newletter ;</li>
              <li>nous contactez ;</li>
            </ul>
            <p>
            Nous utilisons vos données à caractère personnel pour permettre la mise en œuvre et la gestion des Services du Site et répondre à vos demandes spécifiques. Nous utilisons également vos données à caractère personnel dans le but d'exploiter et d'améliorer nos Services, notre Site et notre démarche. Ces informations sont utilisées uniquement par nos soins et nous permettent de mieux adapter nos Services à vos attentes.
            </p>
            <p>
            Si vous avez décidé de recevoir des courriels et messages de notre part lors de la création de votre Compte, vous recevrez alors des messages électroniques et alphanumériques portant sur nos services et promotions. Nous utiliserons alors les données à caractère personnel que vous avez fournies lors de votre enregistrement. Vous pouvez vous désabonner de ces envois à tout moment.
            </p>
            <h3>1.1 Navigation sur le Site</h3>
            <h4>Données de connexion</h4>
            <p>
            À chaque fois que vous vous connectez à notre Site, nous recueillons des données à caractère personnel telles que, notamment, votre adresse IP et l'adresse MAC de votre ordinateur, la date et l'heure de connexion, ainsi que des informations sur le navigateur que vous utilisez.
            </p>
            <h4>Données de navigation</h4>
            <p>
              Nous recueillons également les informations permettant d'identifier de quelle façon vous accédez au Site, quelles sont les pages consultées et pendant combien de temps. Dans ce cadre, nous pouvons avoir recours à l'utilisation de Cookies tel que précisé au paragraphe 6 ci-dessous.
            </p>
            <h3>1.2 Création d'un Compte</h3>
            <p>
            L'accès à certains de nos Services nécessitent la création préalable d'un Compte. Conformément aux Conditions Générales, il vous sera demandé de renseigner un certain nombre de données à caractère personnel lors de la création de votre Compte, notamment votre identifiant et votre adresse email.
            </p>
            <h3>1.3 Paiement</h3>
            <p>
              Certains des Services disponibles sont payants (Une description des Services proposés ainsi que les prix associés sont disponibles en page Tarifs).
            </p>
            <p>
            A cette fin, vous acceptez que nous puissions avoir recours à des prestataires de services externes qui peuvent collecter des données à caractère personnel dans le but de permettre le bon fonctionnement des services de traitement des paiements par carte de crédit ou tout autre moyen de paiement et, le cas échéant, de livraison de produits ou services.
            </p>
            <p>
            Pour régler votre achat, vous devez fournir vos coordonnées de facturation ainsi que vos coordonnées de paiement, et notamment le numéro de votre carte bancaire, la date de validité, le code de sécurité et le nom du titulaire de la carte en cas de paiement par carte bancaire.
            </p>
            <p>
            Il peut également vous être demandé de fournir le nom de votre opérateur télécom, le modèle de votre téléphone portable et un numéro de portable valide afin de pouvoir fournir les instructions d'achat directement à travers votre téléphone mobile.
            </p>
            <p>
            Nous conservons les détails de vos paiements, ainsi que les détails des achats que vous effectuez. Le détail des transactions est conservé soit dans nos systèmes soit chez le prestataire externe. Cette conservation est effectuée à des fins internes, notamment pour la comptabilité, la conformité et à des fins juridiques, conformément au paragraphe 5 de la présente Charte.
            </p>
            <h3>1.4 Adhésion à notre Newsletter</h3>
            <p>
            Lors de la création de votre Compte, vous pouvez donner votre consentement préalable à la réception de nos lettres d'information concernant des actualités, des nouveaux produits, services et promotions, dans le cadre des Services.
            </p>
            <p>
            Vous pouvez également consentir directement à la réception de nos lettres d'information en renseignant votre adresse email aux endroits prévus à cet effet sur le Site.
            </p>
            <p>
            En tout état de cause, vous disposez du droit de retirer votre consentement à la réception de telles lettres d'information à tout moment et sans frais dans les conditions prévues au paragraphe 6 de la Charte.
            </p>
            <h3>1.5 Contacts</h3>
            <p>
            Afin de donner suite aux demandes que vous pourriez effectuer auprès de notre Service Client et de confirmer les informations vous concernant, nous pourrons utiliser vos nom, prénom, adresse de courrier électronique et numéro de téléphone.
            </p>
            </section>
            <section id="section2">
            <h2 className="fw-bold">2. Comment protégeons-nous vos données à caractère personnel ?</h2>
            <p>
            Nous avons mis en place des mesures de sécurité techniques et organisationnelles en vue de garantir la sécurité, l'intégrité et la confidentialité de toutes vos données à caractère personnel, afin d'empêcher que celles-ci soient déformées, endommagées ou que des tiers non autorisés y aient accès. Nous assurons un niveau de sécurité approprié, compte tenu de l'état des connaissances, des coûts de mise en œuvre et de la nature, de la portée, du contexte et des finalités du traitement ainsi que des risques et de leur probabilité.
            </p>
            <p>
            A cette fin, nous avons eu recours à un expert indépendan afin de réaliser des tests d'intrusion, ce qui a permis d'identifier de potentielles failles dans nos systèmes de traitement de l'information et d'en renforcer la sécurité.
            </p>
            <p>
            Toutefois, il est précisé qu'aucune mesure de sécurité n'étant infaillible, nous ne sommes pas en mesure de garantir une sécurité absolue à vos données à caractère personnel.
            </p>
            <p>
            Par ailleurs, il vous incombe d'assurer la confidentialité du mot de passe vous permettant d'accéder à votre Compte. Ne communiquez cette information à personne. Si vous partagez votre ordinateur, n'oubliez pas de vous déconnecter avant de quitter un Service.
            </p>
            </section >
            <section id="section3">
            <h2 className="fw-bold">3. Dans quels cas partageons-nous vos données à caractère personnel ?</h2>
            <h3>3.1 Partage de vos données personnelles avec des sociétés tierces</h3>
            <p>
            Vos données personnelles que nous traitons sont hébergées par Google Cloud Platform au sein de l'Union Européenne.  
            </p>
            <p>
            Lors de votre navigation sur le Site, vos données à caractère personnel peuvent être transmises à des prestataires extérieurs. Ces tiers assurent un service pour notre compte et en notre nom dans le but de permettre le bon fonctionnement des paiements par carte de crédit et autres Services.
            </p>
            <p>
            Conformément au RGPD, tous les transferts de données à caractère personnel vers un pays situé hors de l'Union Européenne et/ou n'offrant pas un niveau de protection considéré comme suffisant par la Commission européenne ont fait l'objet de conventions de flux transfrontière conformes aux clauses contractuelles types édictées par la Commission européenne.              
            </p>
            <h4>3.1.1 Google Analytics</h4>
            <p>
            Google Analytics nous permet de suivre les données d'utilisation vous concernant lors de la visite des différentes pages du site. Cela nous permet notamment d'optimiser nos services afin de répondre au mieux à vos besoins.
            </p>
            <p>
            Vous pouvez à tout moment empêcher la conservation de vos cookies en modifiant les paramètres de votre navigateur.
            </p>
            <p>
            Vous avez la possibilité d'empêcher la collecte par Google des données extraites par le cookie en installant le module suivant mis à disposition par Google : https://tools.google.com/dlpage/gaoptout
            </p>
            <p>
            Par ailleurs, vous pouvez consultez les règles de confidentialité de Google en suivant le lien suivant : https://policies.google.com/privacy/partners?hl=fr
            </p>

            <h3>3.2 Partage avec les autorités</h3>
            <p>
            Nous pouvons être amenés à divulguer vos données à caractère personnel aux autorités administrative ou judiciaire lorsque leur divulgation est nécessaire à l'identification, l'interpellation ou la poursuite en justice de tout individu susceptible de porter préjudice à nos droits, de tout autre utilisateur ou d'un tiers. Nous pouvons enfin être légalement tenu de divulguer vos données à caractère personnel et ne pouvons dans ce cas nous y opposer.
            </p>
            </section >
            <section id="section4">
            <h2 className="fw-bold">4. Combien de temps conservons-nous vos données à caractère personnel ?</h2>
            <p>
            Nous ne conserverons vos données à caractère personnel que le temps de votre inscription sur le Site afin d'assurer votre identification lors de votre connexion à votre Compte et de permettre la fourniture des Services.
            </p>
            <p>
            Ainsi, si vous procédez à votre désinscription du Site, vos données à caractère personnel seront effacées et uniquement conservées sous forme d'archive aux fins d'établissement de la preuve d'un droit ou d'un contrat.
            </p>
            <p>
            En tout état de cause, nous conserverons vos données à caractère personnel pendant une durée n'excédant pas celle nécessaire au regard des finalités pour lesquelles elles sont traitées conformément aux utilisations exposées dans la présente Charte et dans le respect des lois et règlements.
            </p>
            </section > 
            <section id="section5">
            <h2 className="fw-bold">5. Cookies : comment les utilisons-nous ?</h2>
            <h3>5.1 Qu'est-ce qu'un cookie ?</h3>
            <p>
            Un cookie est un fichier texte susceptible d'être déposé dans un terminal lors de la consultation d'un service en ligne avec un logiciel de navigation. Un fichier cookie permet notamment à son émetteur, pendant sa durée de validité, de reconnaître le terminal concerné à chaque fois que ce terminal accède à un contenu numérique comportant des cookies du même émetteur.
            </p>
            <p>
            En tout état de cause, les cookies déposés sur votre terminal de navigation avec votre accord sont détruits 13 mois après leur dépôt sur votre terminal.
            </p>
            <h3>5.2 A quoi servent les cookies émis sur notre Site ?</h3>
            <p>
            Les cookies que nous émettons nous permettent :
            </p>
            <ul>
              <li>d'établir des statistiques et volumes de fréquentation et d'utilisation des divers éléments composant notre Site (rubriques et contenus visités, parcours), nous permettant d'améliorer l'intérêt et l'ergonomie du Site et, le cas échéant, de nos produits et services ;</li>
              <li>d'adapter la présentation de notre Site aux préférences d'affichage de votre terminal (langue utilisée, résolution d'affichage, système d'exploitation utilisé, etc.) lors de vos visites sur notre Site, selon les matériels et les logiciels de visualisation ou de lecture que votre terminal comporte ;</li>
              <li>de mémoriser des informations relatives à un formulaire que vous avez rempli sur notre Site (inscription ou accès à votre compte) ou à des produits, services ou informations que vous avez choisis sur notre Site (service souscrit, contenu d'un panier de commande, etc.) ;</li>
              <li>de vous permettre d'accéder à des espaces réservés et personnels de notre Site, tels que votre Compte, grâce à des identifiants ou des données que vous nous avez éventuellement antérieurement confiées et de mettre en œuvre des mesures de sécurité, par exemple lorsqu'il vous est demandé de vous connecter à nouveau à un contenu ou à un service après une certain laps de temps.</li>
            </ul>
            <p>
              Lors de votre première visite sur le Site, un bandeau cookies apparaîtra en page d'accueil. Un lien cliquable permet d'en savoir plus sur la finalité et le fonctionnement des cookies et renvoie vers la présente Charte. La poursuite de la navigation sur une autre page du site ou la sélection d'un élément du Site (notamment : image, texte, lien, etc.) matérialise votre acceptation au dépôt des cookies visés sur votre ordinateur.
              </p>
            </section > 
            <section id="section6">
            <h2 className="fw-bold">6. Quels sont vos droits ?</h2>
            <p>
            Vous êtes seuls à nous avoir communiqué les données en notre possession, par l'intermédiaire du Site. Vous disposez de droits sur vos données à caractère personnel. Conformément à la réglementation en matière de protection des données à caractère personnel, notamment les articles 15 à 22 du RGPD, et après avoir justifié de votre identité, vous avez le droit de nous demander l'accès aux données à caractère personnel vous concernant, la rectification ou l'effacement de celles-ci.
            </p>
            <p>
            En outre, dans les limites posées par la loi, vous disposez également du droit de vous opposer au traitement, de le limiter, de décider du sort post-mortem de vos données, de retirer votre consentement à tout moment et du droit à la portabilité des données à caractère personnel fournies.
            </p>
            <p>
            Vous pouvez contactez nos Services afin d'exercer vos droits :
            </p>
            <ul>
              <li>à l'adresse électronique suivante : <a href="mailto:contact@kease.fr"><noindex>contact@kease.fr</noindex></a> ou</li>
              <li>à l'adresse postale suivante : Kease - 12 rue Traversière - 92100 Boulogne-Billancourt - France</li>
            </ul>
            <p>
            en joignant à votre demande une copie d'un titre d'identité.
            </p>
            <p>
            Par ailleurs, vous pouvez à tout moment vous désabonner de notre newsletter en cliquant sur le lien qui permet le désabonnement en bas de chaque email. Vous pouvez également vous désabonner en envoyant un message à l'adresse suivante : <noindex>contact@kease.fr</noindex>.
            </p>
            </section>
            <section id="section7">
            <h2 className="fw-bold">7. Pouvons-nous modifier la Charte ?</h2>
            <p>
            Nous nous réservons le droit de modifier la Charte à tout moment. Il vous est donc recommandé de la consulter régulièrement. En cas de modification, nous publierons ces changements sur cette page et aux endroits que nous jugerons appropriés en fonction de l'objet et de l'importance des changements apportés.
            </p>
            <p>
            Votre utilisation du Site après toute modification signifie que vous acceptez ces modifications. Si vous n'acceptez pas certaines modifications substantielles apportées à la présente Charte, vous devez arrêter d'utiliser le Site.
            </p>
            </section > 
            <section id="section8">
            <h2 className="fw-bold">8. La Commission Nationale de l'Informatique et des Libertés ("CNIL")</h2>
            <p>
            Nous vous rappelons que vous pouvez contacter la CNIL directement sur le site internet de la CNIL ou par courrier à l'adresse suivante : Commission Nationale de l'Informatique et des Libertés (CNIL), 3 Place de Fontenoy - TSA 80715, 75334 PARIS CEDEX 07.
            </p>
            </section > 
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Confidentialite