import { Col, Row } from 'react-bootstrap';


const MentionsLegal: React.FC = () => {

  return (
      <div className="container">
            <h1>Conditions Générales d’Utilisation de services Saas</h1>
            <h2>Article 1 - Préambule</h2>
            <p>
                Le Service proposé par Kease est conçu pour satisfaire les besoins du plus grand nombre d’utilisateurs.
            </p>
            <p>
                Kease, dans le cadre de son devoir d’information et de conseil, a mis à la disposition du Client une proposition commerciale et/ou de la
                documentation présentant le Service dont le Client reconnait avoir pris connaissance. Il appartient au Client, notamment sur la base de
                ces informations, de s'assurer de l'adéquation du Service à ses besoins propres. À cette fin, le Client peut, préalablement à l’acceptation
                du Contrat, demander à Kease toute information complémentaire et/ou d’assister à une démonstration supplémentaire du Service, à
                défaut de quoi, le Client reconnaît avoir été suffisamment informé. 
            </p>
            <p>
                Tout cahier des charges ou document d’expression de besoins établi par le Client ne sera en aucun cas pris en compte par Kease dans
                le cadre du Contrat sauf validation expresse de Kease intervenue avant la signature des présentes pour figurer en annexe des présentes.
                La fourniture par Kease d’adaptation des Services aux besoins exprimés par le Client ne peut être effectuée par Kease que dans le
                cadre d’un contrat spécifique non régi par les présentes conditions générales d’utilisation de services SaaS.
            </p>
            <p>
                Le Client est informé que les Prestations proposées par Kease sont nécessaires à la bonne utilisation du Service. Dès lors, il appartient
                au Client, eu égard à ses besoins, d’apprécier l’opportunité de recourir ou non à ces prestations.
            </p>
            <h2>Article 2 - Définitions</h2>
            <p>
                Pour l'exécution des présentes, les termes suivants doivent être entendus dans le sens défini ci-dessous :
            </p>
            <p>
                <strong>Client</strong> : désigne la personne morale ou la personne physique, co-contractante de Kease, intervenant dans le cadre de son activité
                professionnelle, commerciale, industrielle, artisanale ou libérale.
            </p>
            <p>
                <strong>Contrat</strong> : désigne la commande en ligne, validée par une personne habilitée du Client, comprenant les éléments commandés,
                les quantités, les prix, le Mandat SEPA si applicable, les présentes conditions générales d'utilisation de services SaaS.
                Les conditions générales d’utilisation de services SaaS sont consultables et téléchargeables sur le site de Kease (https://www.kease.fr)
                et peuvent également être adressées au Client à première demande et obéissent ainsi à l'Article L441-6 du Code du Commerce en ce qu'il prévoit
                que la communication par un prestataire de services doit s'effectuer par tout moyen conforme aux usages de la profession. Kease recommande
                au Client la prise de connaissance des conditions générales d’utilisation de services SaaS par ce moyen d'accès en permanence disponible.
            </p>
            <p>
                <strong>Documentation</strong> : désigne les informations fournies par Kease sous la forme d'une documentation utilisateur accompagnant le Service
                et/ou pouvant revêtir la forme d'une aide en ligne.
            </p>
            <p>
                <strong>Données Client</strong> : désigne les informations (dont les Données Personnelles) dont le Client est responsable qu'il saisit, renseigne,
                transmet et traite dans le cadre de son utilisation du Service.
            </p>
            <p>
                <strong>Données Personnelles</strong> : désigne les données à caractère personnel au sens de la directive 95/46/CE et la de la loi n° 78-17 du 6
                janvier 1978 dite Informatique et libertés, ou de toute autre règlementation applicable, que le Client collecte et dans le cadre de son
                utilisation du Service saisit, renseigne, transmet ou traite.
            </p>
            <p>
                <strong>Mises à jour</strong> : désigne les améliorations apportées aux services applicatifs standards existantes accessibles au titre du Service, et
                décidées unilatéralement par Kease, au regard des évolutions fonctionnelles et sous réserve que de telles adaptations ou évolutions ne
                rendent pas nécessaire la réécriture d’une partie substantielle des services applicatifs standards existantes. Les Mises à Jour
                comprennent également la correction d’éventuelles anomalies du Service par rapport à la Documentation. Les Mises à Jour sont
                fournies en exécution du Support.
            </p>
            <p>
                <strong>Prestations</strong> : désigne les prestations de mise en œuvre concernant le Service (analyse, paramétrage, formation) proposées par Kease
                et souscrites par le Client au titre de conditions générales prestations séparées.
            </p>
            <p>
                <strong>Engagement</strong> : désigne un dossier contenant la documentation de l'audit des comptes lui permettant d'étayer l'opinion formulée dans son rapport
                au sens des normes d'exercice professionnel.
            </p>
            <p>
                <strong>Service</strong> :  désigne les services applicatifs standards délivrés en ligne, ainsi que le Support, facturés sous forme de souscription à un
                abonnement ou de relevés de consommation. Le Service est destiné à un usage professionnel. Le Service a été conçu et développé
                pour le marché français, il ne peut être recommandé en l’état que pour des entreprises françaises situées en France métropolitaine ou, le cas échéant,
                pour des filiales d’entreprises françaises situées à l’étranger dont les besoins peuvent être satisfaits par ce Service.
            </p>
            <p>
                <strong>Support</strong> : désigne l'assistance à l’utilisation du Service et des services applicatifs standards. Le Support ne pourra être assuré par
                Kease que dans la mesure où le Client dispose des dispositifs techniques permettant la téléassistance.
            </p>
            <p>
                <strong>Utilisateur</strong> : désigne une personne physique faisant partie du personnel du Client et habilitée par ce dernier, ou un système logique
                ou physique, pouvant avoir accès au Service pour un usage professionnel, comme défini plus-avant à l’article 5.2.
            </p>
            <p>
                Ces définitions sont libellées avec une majuscule et s'entendent au singulier comme au pluriel.
            </p>
            <h2>Article 3 - Acceptation du Contrat</h2>
            <p>
                Le Client est réputé avoir pris connaissance du Contrat tel que défini à l’article 2 et l'avoir dûment accepté sans réserve.
            </p>
            <p>
                Le Contrat est matérialisé par la signature du Bon de commande et/ou de l’autorisation de prélèvement sous forme papier ou lors de la
                conclusion de la commande en ligne faisant référence aux présentes conditions générales d’utilisation de services SaaS et valant
                acceptation de l’ensemble du Contrat.
            </p>
            <p>
                Toute modification des présentes conditions générales d’utilisation de services SaaS devra faire l’objet de conditions particulières
                dûment acceptées et signées par les deux Parties. À défaut, toute modification ou altération portée sur la partie pré imprimée du Contrat
                (Bon de commande, Éléments commandés) est réputée nulle et non avenue. 
            </p>
            <p>
                Aux fins de l’acceptation à distance du Contrat, le Client reconnaît et accepte que les télécopies revêtues de la signature d’un de ses
                représentants ou préposés, reçues par Kease, ont la valeur d'une preuve écrite et peuvent lui être valablement opposées par Kease.
            </p>
            <p>
                L'acceptation du Contrat par voie électronique a entre les Parties, la même valeur probante que l'accord sur support papier. Les registres
                informatisés et conservés dans les systèmes informatiques seront conservés dans des conditions raisonnables de sécurité et considérés
                comme les preuves des communications intervenues entre les Parties. L'archivage des documents contractuels est effectué sur un
                support fiable et durable pouvant être produit à titre de preuve. 
            </p>
            <h2>Article 4 - Objet</h2>
            <p>
                Kease s'engage à fournir au Client le Service aux conditions générales d’utilisation de services SaaS définies ci-après.
            </p>
            <h2>Article 5 - Droit d'accès au Service</h2>
            <p>
                <strong>5.1.</strong> En contrepartie du paiement de l’abonnement stipulé en partie « Éléments commandés » du Bon de commande ou dans la
                commande en ligne, Kease concède au Client un droit d'accès au Service limité au nombre d’Utilisateurs nommés et/ou à toutes autres
                unités d’œuvre exprimées sous forme de quantités, seuils ou plafonds, ces éléments étant fixés en partie « Éléments commandés » et
                « Bon de commande », ou dans la commande en ligne.
            </p>
            <span>
                <strong>5.2.</strong> Par Utilisateur nommé, on entend selon les Services et les modalités d'usage :
                <ul>
                    <li>
                        soit les utilisateurs, personnes physiques, désignés par le Client disposant d'un identifiant et d'un mot de passe personnel et pouvant
                        accéder au Service ;
                    </li>
                    <li>
                        et/ou soit les systèmes logiques ou physiques accédant et opérant des traitements avec le Service (postes, device mobile etc.).
                    </li>
                </ul>
                Ce droit d'accès au Service est accordé exclusivement pour les besoins professionnels du Client.
            </span>
            <p>
                <strong>5.3.</strong> Kease détient l'ensemble des droits de propriété intellectuelle applicables relatifs au Service ou déclare, lorsqu’un tiers en détient
                la propriété intellectuelle, avoir obtenu de ce tiers le droit de commercialiser ou distribuer le Service. Le présent Contrat ne confère au
                Client aucun droit de propriété relatif au Service, à sa technologie ou aux droits de propriété intellectuelle détenus par Kease ou un tiers.
            </p>
            <p>
                <strong>5.4.</strong> Il est interdit au Client de porter atteinte de quelque manière que ce soit au Service et notamment d'utiliser le Service de manière
                non-conforme à sa destination et aux conditions fixées par le Contrat. En conséquence, le Client s'interdit notamment d'effectuer une
                ingénierie inverse du Service en vue d'élaborer un produit ou service concurrent et/ou de copier, reproduire toutes fonctionnalités,
                fonctions ou tous attributs graphiques du Service.
            </p>
            <span>
                <strong>5.5.</strong> Le Client :
                <ul>
                    <li>
                        s’engage à n’utiliser le Service que conformément à sa destination professionnelle, c’est-à-dire conformément à leur Documentation
                        et pour les seuls besoins professionnels de son activité ;
                    </li>
                    <li>
                        se porte garant du respect du présent Contrat par les Utilisateurs ;
                    </li>
                    <li>
                        est seul responsable des contenus diffusés et/ou téléchargés via les Services et assume l'entière responsabilité de l'exactitude, de
                        l'intégrité et de la légalité des Données Clients transmises à Kease dans le cadre du Service. En particulier, compte tenu de l'usage
                        autorisé du Service par le Client celui-ci s'interdit d'envoyer ou de stocker des données à caractère non professionnel et plus
                        généralement des données à caractère illicite, obscène, diffamatoire ou des données illégales ou en violation du droit d'un tiers, de la
                        protection des mineurs ou de la vie privée ;
                    </li>
                    <li>
                        s'engage à ne pas distribuer le Service, l'exploiter à des fins commerciales, le mettre à la disposition de tiers ou le louer ;
                    </li>
                    <li>
                        s'engage à ne pas altérer ou perturber l'intégrité ou l'exécution du Service ou des données qui y sont contenues ;
                    </li>
                    <li>
                        à ne pas tenter d'obtenir un accès non autorisé au Service ou aux systèmes ou réseaux qui lui sont associés.
                    </li>
                </ul>
            </span>
            <h2>Article 6 - Modalité d'exécution du Service</h2>
            <p>
                <strong>6.1.</strong> Kease s'engage à fournir le Service conformément aux dispositions du Bon de commande, qui précise notamment le
                contenu, les limitations, la durée, les procédures associées, les modalités de mise en place des Mises à Jour et du Support.
            </p>
            <span>
                <strong>6.1.</strong> Le Service sera utilisé par le Client sous ses seuls contrôles, direction et sous sa seule responsabilité.<br/>
                Par conséquent, relèvent de la responsabilité du Client :
                <ul>
                    <li>
                        la mise en œuvre de tous procédés et mesures utiles destinés à protéger ses Postes de Travail Utilisateur, ses matériels, progiciels,
                        logiciels, mots de passe, notamment contre tout virus et intrusions ;
                    </li>
                    <li>
                        le respect de la dernière version à jour des Pré Requis Techniques afin d'éviter des conséquences dommageables telles que
                        ralentissements, blocages, altérations des données ;
                    </li>
                    <li>
                        le choix du fournisseur d'accès ou du support de télécommunication, le Client devant prendre en charge les demandes administratives
                        et contracter les abonnements nécessaires dont il supportera le coût ;
                    </li>
                    <li>
                        la désignation, parmi son personnel, d'un contact privilégié de Kease agissant en tant qu'administrateur, pour le Client, du Service et
                        notamment pour ce qui concerne les aspects sécurité ;
                    </li>
                    <li>
                        l'utilisation des identifiants et des codes d'accès seront établis par le Client à l'occasion de l'exécution du Service. Il s'assurera
                        qu'aucune personne non autorisée par ses soins n'a accès au Service ;
                    </li>
                    <li>
                        les erreurs commises par son personnel dans l'utilisation du Service et des procédures qui lui permettent de se connecter au Service
                        notamment concernant les moyens d'accès et de navigation internet.
                    </li>
                </ul>
            </span>
            <p>
                Kease sera dégagé de toute responsabilité concernant la nature, le contenu des informations ou des données du Client et l'exploitation
                qui en découle. De même, Kease sera dégagé de toute responsabilité concernant la qualité et la transmission électronique des données
                lorsqu'elles emprunteront les réseaux de télécommunications et plus généralement la qualité et la fiabilité des liaisons de
                télécommunication entre les Postes de Travail Utilisateurs et le point d'accès au Service.
            </p>
            <span>
                <strong>6.3.</strong> Sont exclus du Service :
                <ul>
                    <li>
                        les travaux et interventions concernant l'installation et le bon fonctionnement du Poste de Travail Utilisateur et de l'infrastructure du
                        Client (télécommunications, réseaux, équipements de sécurité) permettant au Client d'accéder et d'utiliser le Service ;
                    </li>
                    <li>
                        la résolution de problèmes causés par une erreur ou une mauvaise manipulation des Utilisateurs ;
                    </li>
                    <li>
                        les Prestations.
                    </li>
                </ul>
            </span>
            <p>
                <strong>6.4. Garantie</strong> Kease garantit la conformité de chaque Service avec sa Documentation.
                Kease ne garantit pas que le Service soit exempt de tous défauts ou aléas mais s'engage exclusivement à remédier, avec toute la
                diligence raisonnablement possible, aux dysfonctionnements reproductibles du Service constatés par rapport à sa Documentation.
                Cette garantie de conformité ne saurait être étendue à une garantie de conformité aux besoins spécifiques ou à l’activité spécifique d'un
                Client ou d'un Utilisateur. Kease ne garantit pas l'aptitude du Service à atteindre des objectifs ou des résultats que le Client se serait
                fixés et/ou à exécuter des tâches particulières qui l'auraient motivé dans sa décision de conclure le présent Contrat. Il incombe donc au
                Client ou à tout tiers mandaté par le Client à cet effet de s'assurer de l'adéquation du Service à ses besoins ou à son activité spécifique
                sur le territoire où le Service est utilisé.
                Dans la limite de ce que permet la loi, toute autre garantie que celles exprimées dans le présent article sont expressément exclues.
            </p>
            <p>
                <strong>6.5.</strong> Il est convenu entre les Parties que Kease demeurera en tout circonstances libre de déterminer sa politique d'industrialisation. Par
                conséquent Kease pourra sans contrainte concevoir, organiser et dimensionner le Service, le modifier et le faire évoluer et ce au besoin
                avec les partenaires et fournisseurs de son choix sans accord écrit préalable du Client, dès lors que cela ne réduit pas les engagements
                de Kease au titre du présent Contrat.
            </p>
            <p>
                <strong>6.6. Evolutions</strong> Le Client est informé que les évolutions législatives peuvent, à tout moment, rendre inadaptées les services applicatifs
                standards accessibles au titre du Service. Kease, dans le cadre du Support, fera une Mise à Jour des services applicatifs standard afin
                qu’ils satisfassent aux nouvelles dispositions légales et ce sous réserve que de telles adaptations ou évolutions ne rendent pas
                nécessaire la réécriture d’une partie substantielle des services applicatifs existants.
                Le Client est également informé que l’évolution des technologies et de la demande de sa clientèle peuvent amener Kease à réaliser des
                Mises à Jour, lesquelles pourront entrainer une évolution des Pré Requis techniques dont Kease ne pourra en être tenue pour
                responsable.
            </p>
            <p>
                <strong>6.5.</strong> Kease pourra modifier le Livret Service en respectant un délai de préavis de un (1) mois en notifiant le Client et/ou l’un de ses
                administrateurs du Service par courrier et/ou informations sur le Portail et/ou tout autre moyen approprié. À l'issue du préavis de un (1)
                mois suivant la notification par Kease de la modification et à défaut de résiliation par le Client intervenue pendant cette période
                conformément à l’article 14.2, le Livret Service modifié est réputé accepté par le Client. La dernière version du Livret Service est
                accessible à tout moment sur le site web de Kease https://www.kease.fr ou à toute autre adresse de site communiquée par Kease.
                Nonobstant ce qui précède, Kease pourra modifier le Livret Service afin de se conformer à une loi ou un règlement. Dans cette
                hypothèse, Kease s'efforcera de notifier au Client ces modifications dans un délai raisonnable.
            </p>
            <span>
                <strong>6.6.</strong> Tout Service en ligne tiers sera soumis :
                <ul>
                    <li>
                        d’une part aux conditions générales de vente particulières de l’auteur concerné qui seront mises à disposition du Client par Kease ou
                        accessibles en ligne par le Client selon les informations fournies par Kease. Les conditions générales de vente particulières précitées
                        régiront les modalités d’accès au Service en ligne tiers, les modalités de fourniture du Service en ligne tiers, les modalités de protection
                        des Données Client, les dispositions juridiques relatives notamment à la propriété intellectuelle, la garantie, la résiliation, la
                        responsabilité, la loi applicable et la compétence juridictionnelle. Ces conditions devront faire l’objet d’une acceptation par le Client ;
                    </li>
                    <li>
                        d’autre part à un Livret Service associé qui sera mis à disposition du Client par Kease ou accessible en ligne par le Client selon les
                        informations fournies par Kease.   
                    </li>
                </ul>
                Par conséquent, pour tout Service en ligne tiers, le champ d’application des présentes conditions générales d’utilisation de service
                SaaS portera exclusivement sur les modalités de durée, de fixation et de révision des prix, les conditions de facturation, les conditions
                de règlement ainsi que sur les dispositions strictement liées aux prix, conditions de facturation et de règlement.
            </span>
            <h2>Article 7 - Données Client</h2>
            <span>
                <strong>7.1. Localisation des données Client</strong> Les Données Client sont localisées dans un ou plusieurs sites situés en France
                sauf dispositions contraires stipulées dans un Livret Service (ci-après le « Pays de localisation des données »).
                Dès lors que les Données Personnelles sont :
                <ul>
                    <li>
                        collectées par le Client hors du Pays de localisation des données avant d’y être transférées au titre du Service, et/ou
                    </li>
                    <li>
                        transférées par le Client, ou par Kease sur instruction du Client, hors du Pays de localisation des données, 
                    </li>
                </ul>
                il relève de la responsabilité du Client de s’assurer que la collecte, le traitement et/ou le transfert de Données Personnelles dans le
                Pays de localisation des données est autorisé par les législations locales applicables ou à défaut et lorsque cela est légalement possible
                d’encadrer ces transferts par des outils juridiques adéquats.
                Lorsque le Pays de localisation des données est la France, Kease s'engage à ne pas transférer les sites où sont localisées les Données
                Client en dehors de la France sans l'accord préalable du Client.
            </span>
            <p>
                <strong>7.2. Non utilisation des données Client</strong> Le Client est et demeure responsable des Données Client.
                Sauf utilisation décrite à l'article 7.3, Kease s’interdit d’utiliser, modifier, céder ou transférer à un tiers, en totalité ou en partie, à titre
                onéreux ou gratuit, les Données Client qui auront pu lui être communiquées par le Client à l'occasion de l'exécution du Service.
            </p>
            <p>
                <strong>7.3. Utilisation des informations statistiques</strong> Par exception à l'article 7.2 l’engagement de Kease de non utilisation des Données
                Client ne concernera pas les opérations nécessaires à l'établissement par Kease de ses factures et statistiques d'utilisation ainsi qu'à la fourniture
                de toute explication concernant l'exécution du Service.
                De même, Kease pourra compiler des informations statistiques agrégées et rendues anonymes et pourra les rendre publiques à
                condition qu'elles n'identifient pas les informations confidentielles du Client et qu'elles ne comprennent aucune donnée directement ou
                indirectement nominative. Kease conserve tous les droits de propriété intellectuelle sur les résultats de ces traitements statistiques.
            </p>
            <p>
                <strong>7.4. Déclarations relatives aux données Client</strong> Il est rappelé qu'au sens de la loi n° 78-17 du 6 janvier 1978 dite Informatique
                et libertés, Kease agit en qualité de sous-traitant, sur instructions du Client, lequel est qualifié de responsable du traitement de Données
                Personnelles mis en œuvre grâce au Service.
                En conséquence, le Client est informé qu'il lui appartient de procéder, sous sa seule responsabilité, aux démarches, déclarations,
                demandes d'autorisation prévues par les lois et règlements en vigueur concernant tout traitement qu'il effectue et données qu'il traite à
                partir du Service. Plus généralement, il appartiendra au Client de se mettre en conformité avec toute législation locale applicable
                exigeant un procédé particulier de déclaration administrative relative aux Données Personnelles. Le Client garantit respecter l’ensemble
                des obligations qui lui incombent au terme de la Loi Informatique et Libertés et/ou des législations locales relatives aux Données
                Personnelles applicables.
            </p>
            <h2>Article 8 - Sécurité du Service</h2>
            <p>
                <strong>8.1. Gestion de la Sécurité</strong>
                Kease s'engage à mettre en œuvre les moyens techniques conformes à l'état de l'art pour assurer la sécurité physique et logique des
                serveurs et réseaux qui sont sous sa responsabilité et son contrôle.
                Dès qu'elle en a connaissance, chacune des Parties signalera, dans les plus brefs délais, à l'autre Partie tout fait susceptible de
                constituer une atteinte à la sécurité physique ou logique de l'environnement de l'autre Partie (tentative d'intrusion par exemple).
            </p>
            <p>
                <strong>8.2. Sécurité d'accès aux locaux</strong>
                Sauf dispositions contraires stipulées dans le Livret Service, Kease mettra en place un contrôle d'accès aux locaux dans lesquels sont
                effectuées les prestations relatives au Service, de façon à n'en autoriser l'accès qu'aux seules personnes autorisées par Kease ou
                accompagnées par du personnel autorisé. Il prendra toutes les dispositions permettant d'éviter les intrusions.
            </p>
            <p>
                <strong>8.3. Sécurité des services applicatifs standards</strong>
                Kease mettra en œuvre les mesures nécessaires pour ne permettre l'accès au Service qu'aux personnes autorisées par Kease et qu'aux
                personnes autorisées par le Client.
            </p>
            <p>
                <strong>8.4. Sécurité des connexions</strong>
                Afin de garantir la confidentialité des données en transit entre le Poste de Travail Utilisateur et le point d’accès au Service toutes les
                connexions sont sécurisées. Les flux de données, qui empruntent des réseaux de télécommunications non sécurisés, utilisent des
                protocoles de sécurité reconnus comme par exemple le HTTPS (basé sur SSL/TLS Secure Socket Layer/Transport Layer Security ou
                SFTP (basé sur Secure Shell - SSH).
            </p>
            <span>
                <strong>8.5. Sécurité des données Client</strong>
                Kease s'engage à prendre toutes précautions utiles conformément à l'état de l'art pour préserver la sécurité des Données Client afin
                qu'elles ne soient pas, de son fait, déformées, endommagées ou communiquées à des tiers non-autorisés.
                En conséquence, Kease s'engage à respecter et à faire respecter par son personnel les obligations suivantes :
                <ul>
                    <li>
                        ne pas faire de copies des documents et des supports des Données Client qui lui sont confiés, sauf celles strictement nécessaires à
                        l'exécution du Service ;
                    </li>
                    <li>
                        ne pas utiliser les Données Client pour d'autres fins que celles du présent Contrat ;
                    </li>
                    <li>
                        ne pas divulguer les Données Client à d'autres personnes, qu'il s'agisse de personnes privées ou publiques, physiques ou morales
                        sauf si cette divulgation est exigée par la loi ou une autorité judiciaire ou administrative compétente ou est nécessaire dans le cadre
                        d'une action judiciaire.
                    </li>
                </ul>
                Kease assurera une complète étanchéité entre les Données Client et les données des autres Clients, sauf invitation de la part du Client dans l'Engagement.
            </span>
            <h2>Article 9 - Durée du Service</h2>
            <p>
                Sauf dispositions contraires et particulières, le Service est conclu à la création de l'Engagement pour une durée de douze (12) mois.
                Passé ce délai ou à la demande du Client, qui décidera d'archiver son Engagement, Kease propose un Service optionnel d'Archivage permettant au Client d'accéder
                à un Engagement pendant douze (12) mois sans pour autant pouvoir le modifier.
                Ce Service d'Archivage sera ensuite renouvelé par période d’une année par tacite reconduction pour une durée de dix (10) ans conformément à loi en vigueur.
                La Partie qui déciderait de ne pas renouveler le Service devra notifier cette décision à l’autre par Lettre Recommandée avec Accusé de Réception trois
                (3) mois de facturation avant la fin de la période en cours.
                A défaut du Service optionnel d'Archivage, le dossier sera créé pour douze (12) mois maximum, dépassé ce délai, le dossier sera supprimé de la base de données.
                Le Client devra donc avoir, avant cette échéance, récupéré les Données Client accessibles au travers des fonctionnalités du Service
                ou avoir demandé à Kease la restitution d'une copie de la dernière sauvegarde des Données Client.
            </p>
            <h2>Article 10 - Récupération et restitution des données</h2>
            <p>
                À l'échéance du Service et/ou en cas de résiliation du Contrat, les accès au Service sont fermés le dernier jour du Service ou le jour de
                la résiliation du Contrat.
                Le Client devra donc avoir, avant cette échéance, récupéré les Données Client accessibles au travers des fonctionnalités du Service
                ou avoir demandé à Kease la restitution d'une copie de la dernière sauvegarde des Données Client.
                Sauf dispositions contraires, cette restitution sera effectuée dans un format standard du marché choisi
                par Kease et sera mise à disposition du Client sous la forme d'un téléchargement ou si le volume est trop important, par envoi d'un
                support externe et ce, dans le cadre d'une prestation facturable dans la limite du coût du support externe et de son envoi sécurisé.
                Sauf dispositions contraires, à partir du soixantième (60ème) jour à compter du jour de l'échéance du
                Service ou de la résiliation du Contrat, le processus d'effacement des Données Client sera enclenché aux fins de les rendre inutilisables.
                Cet effacement s'effectuera sur les données de production ainsi que sur les données sauvegardées et ce, en fonction des durées de
                rétention des sauvegardes.
            </p>
            <h2>Article 11 - Accès au Service par les filiales du Client</h2>
            <p>
                <strong>11.1. Conditions préalables</strong>
                Une filiale du Client ne sera autorisée à utiliser le Service que si, à la date d'entrée en vigueur du Contrat,
                le Client détient le contrôle de cette filiale au sens de l'article L. 233-3 du code de commerce (une filiale respectant cette condition étant
                ci-après désignée une "Filiale"). Par exception, ne seront pas considérés comme Filiale, l’ensemble des entités ayant une activité
                directement ou indirectement, par personnes ou sociétés interposées, concurrente de celle exercée par Kease.
                Si, après la date d'entrée en vigueur du Contrat, une Filiale ne remplit plus les conditions prévues ci-dessus, ladite filiale perdra
                immédiatement et automatiquement son droit d’accès au Service dans le cadre du présent Contrat. Les Services pourront être fournis
                à cette filiale sous réserve de la signature d’un contrat SaaS avec Kease, qui prévoira notamment les conditions financières de fourniture
                des Services.
            </p>
            <p>
                <strong>11.2. Respect des dispositions du Contrat par les Filiales</strong>
                Les Filiales pourront bénéficier du Service fourni par Kease au Client au
                titre du présent Contrat dans les mêmes conditions que le Client. Le Client s'assurera que les Filiales respectent l'ensemble des
                obligations mises à sa charge au titre du Contrat, et notamment qu'elles utiliseront le Service conformément aux dispositions du Contrat.
                Le Client sera responsable solidairement du respect des dispositions du Contrat par les Filiales. En cas de non-respect de l'une
                quelconque des dispositions du Contrat par l'une des Filiales, Kease pourra s'adresser directement au Client en vue d'obtenir réparation
                sans nécessité de mise en demeure préalable de la Filiale concernée.
            </p>
            <h1>Dispositions financières et générales</h1>
            <h2>Article 12 - Dispositions financières</h2>
     
            <p>
                <strong>12.1. Prix</strong>
                Les prix des éléments commandés sont indiqués en Euros Hors Taxes et figurent en partie « Éléments commandés » du Bon de
                commande ou dans la commande en ligne.
            </p>
            <p>
                <strong>12.2. Facturation et règlement des frais de mise en Service</strong>
                Kease ne facture pas de mise en Service.
            </p>
            <p>
                <strong>12.3. Facturation et règlement des Services</strong>
                Sauf dispositions contraires et particulières, le Service sera facturé à la création d'un Engagement pour une durée maximum de douze (12) mois,
                le Service étant en fonction de l'utilisation du Service et non au nombre d'utilisateur.
                
                Dans tous les cas la facturation des Services sera effectuée par Kease sur la base de périodes calendaires civiles et non de périodes
                anniversaires de création d'Engagement. Toute création d'Engagement est due par le Client.
                Aucun prorata ne sera pris en compte, un Engagement sera créé pour un exercice avec une possibilité d'Archivage.
                Pour le Service, les factures de Kease (y compris pour les commandes en ligne) seront réglées par le Client par prélèvement automatique
                sans escompte à trente (30) jours date d’émission de facture. Le Client s'engage à fournir ses coordonnées bancaires (IBAN et BIC) et
                à compléter le Mandat SEPA sous forme papier ou électronique ; le Client restant libre de choisir la forme du Mandat SEPA, lorsque et
                tant que ces deux formes seront mises à sa disposition par Kease. Dans le cas où le Client décide de recourir au Mandat SEPA
                Interentreprises, il lui appartient de s'assurer, préalablement, que son établissement de crédit est en mesure de traiter sa demande.
                À compter de la mise en place du Mandat SEPA et dans le cas où le Client signe successivement plusieurs Engagements et choisit de régler
                à chaque fois les sommes dues à Kease par prélèvement automatique, il accepte que chacun de ces Engagements soit régi par une
                autorisation de prélèvement commune et unique dont le montant varie, en conséquence, en fonction des ajouts d'Engagements au cours du temps.
            </p>
        </div>
  );
};

export default MentionsLegal