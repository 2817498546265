import React, { useEffect, useState } from "react";
import { auth, dbCollection } from "../../utils/firebaseconfig";
import { get, ref } from "firebase/database";

const EngagementTeam: React.FC = () => {
  const [collaborateurs, setCollaborateurs] = useState<any[]>([]);
  
  useEffect(() => {
    // Récupérer le domaine de l'adresse e-mail de l'utilisateur
    const userDomain = getUserDomain(auth.currentUser.uid);

    // Récupérer les collaborateurs dont le domaine de l'adresse e-mail correspond
    const fetchCollaborateurs = async () => {
        const usersRef = ref(dbCollection, "users");
        const snapshot = await get(usersRef);
        if (snapshot.exists()) {
            const users = snapshot.val();
            const collaborateurs = Object.values(users).filter(
            (user: any) => getUserDomain(user.email) === userDomain
            );
            setCollaborateurs(collaborateurs);
        }
    };

    fetchCollaborateurs();
  }, []);

  const getUserDomain = (email: string) => {
    const parts = email.split("@");
    return parts[1];
  };

  return (
    <div>
      <h2>Collaborateurs</h2>
      <ul>
        {collaborateurs.map((collaborateur) => (
          <li key={collaborateur.uid}>{collaborateur.displayName}</li>
        ))}
      </ul>
    </div>
  );
};

export default EngagementTeam;
