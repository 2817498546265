import { Nav, Navbar } from 'react-bootstrap';
import '../../index.css';
import {ReactComponent as DashboardSvg} from '../../assets/dashboard.svg';
import {ReactComponent as AddSvg} from '../../assets/add.svg';
import {ReactComponent as ArchiveSvg} from '../../assets/archive.svg';

type LinkPrivateMenuProps = {
  onExpandedChange: () => void;
  show: boolean;
  activePage: string; // Valeur de la page active
  createLink: (link: string) => void; // Fonction pour créer un lien
};

const LinkPrivateMenu: React.FC<LinkPrivateMenuProps> = ({ onExpandedChange, show, activePage, createLink }) => {

    const handleClick = (useLink:any) => {
        createLink(useLink); // Utilisation de la fonction createLink
        onExpandedChange(); // Appel de la fonction de mise à jour de l'état expanded
    };

    if (!show) {
        return null;
    }

    return (
        <>
            <Navbar.Collapse>
                <Nav>
                    <Nav.Link onClick={() => handleClick("dashboard")} className={`m-3 d-flex align-items-center ${activePage === 'dashboard' ? 'active' : ''}`}>
                        Dashboard<span className="ms-1"></span>
                        <DashboardSvg className="bi bi-cloud-check align-self-center" width="25" height="25"/>
                    </Nav.Link>
                    <Nav.Link onClick={() => handleClick("new-engagement")} className={`m-3 d-flex align-items-center ${activePage === 'new-engagement' ? 'active' : ''}`}>  
                        Ajouter<span className="ms-1"></span>
                        <AddSvg className="bi bi-cloud-check align-self-center" width="25" height="25"/>
                    </Nav.Link>
                    <Nav.Link onClick={() => handleClick("archives")} className={`m-3 d-flex align-items-center ${activePage === 'archives' ? 'active' : ''}`}>  
                        Archives<span className="ms-1"></span>
                        <ArchiveSvg className="bi bi-cloud-check align-self-center" width="25" height="25"/>
                    </Nav.Link>
                </Nav>
            </Navbar.Collapse>       
        </>
    );
};

export default LinkPrivateMenu
