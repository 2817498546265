import React from 'react';
import { Button, Card, CardGroup, Container } from 'react-bootstrap';

const Prices: React.FC = () => {
  return (
    <>
        <br />
        <div>
          <h2 className="text-center text-kease">
            Une approche variable en fonction du nombre de dossiers
          </h2>
        </div>
        <br />
        <br />
        <CardGroup className="border-0">
          <Card className="p-3 font-kease border-0">
            <Card.Header className="kease-hover text-center rounded-top-kease text-kease">
              <h2 className="">Mini</h2>
              jusqu'à 10 dossiers
            </Card.Header>
            <Card.Body className="">
              <Card.Title>
                <div className="d-flex justify-content-center text-kease">
                  <h2 className="m-1 align-self-end">100 €</h2>
                  <p className="m-1 align-self-end">/ dossier par an</p>
                </div>
              </Card.Title>
              <br />
              <Card.Text className="align-left text-kease">
                La formule idéale :
                </Card.Text>
                <ul className="text-kease">
                  <li>pour les petits volumes</li>
                  <li>pour tester notre application</li>
                </ul>
              
            </Card.Body>
            <Card.Footer className="kease-hover text-center rounded-bottom-kease">
              <Button className="btn-kease m-3">Choisir cette formule</Button>
            </Card.Footer>
          </Card>
          <Card className="p-3 font-kease border-0">
            <Card.Header className="kease-hover text-center rounded-top-kease text-kease">
              <h2 className="">Professionnel</h2>
              de 10 à 50 dossiers
            </Card.Header>
            <Card.Body className="">
              <Card.Title>
                <div className="d-flex justify-content-center text-kease">
                  <h2 className="m-1 align-self-end">75 €</h2>
                  <p className="m-1 align-self-end">/ dossier par an</p>
                </div>
              </Card.Title>
              <br />
              <Card.Text className="align-left text-kease">
                La formule idéale :
                </Card.Text>
                <ul className="text-kease">
                    <li >pour structurer vos dossiers</li>
                    <li>pour passer en mode Saas</li>
                </ul>

            </Card.Body>
            <Card.Footer className="kease-hover text-center rounded-bottom-kease">
              <Button className="btn-kease m-3">Choisir cette formule</Button>
            </Card.Footer>
          </Card>
          <Card className="p-3 font-kease border-0">
            <Card.Header className="kease-hover text-center rounded-top-kease text-kease">
              <h2 className="">Expert</h2>
              à partir de 50 dossiers
            </Card.Header>
            <Card.Body className="">
              <Card.Title>
                <div className="d-flex justify-content-center text-kease">
                  <h2 className="m-1 align-self-end">50 €</h2>
                  <p className="m-1 align-self-end">/ dossier par an</p>
                </div>
              </Card.Title>
              <br />
              <Card.Text className="align-left text-kease">
                La formule idéale :
            </Card.Text>
                <ul className="text-kease">
                  <li>pour une digitalisation complète</li>
                  <li>pour une qualité accrue</li>
                </ul>
              
            </Card.Body>
            <Card.Footer className="kease-hover text-center rounded-bottom-kease">
              <Button className="btn-kease m-3">Choisir cette formule</Button>
            </Card.Footer>
          </Card>
        </CardGroup>
    </>
  );
};

export default Prices;
