import React from 'react';
import { Card, CardGroup, Container } from 'react-bootstrap';
import { useNavigate, Link } from 'react-router-dom';

const Features: React.FC = () => {

    const navigate = useNavigate();
    
    function Createlink(linky:any){
    navigate('/' + linky, { replace: true });
}

  return (
    <>
        <br/>
        <br/>
        <CardGroup className="card-deck">
            <Card className="features rounded-top-kease card border-0">
                <div className="text-center" onClick={(e:any) => {Createlink('avantages-mode-saas-et-cloud-logiciel-cac')}}>
                <svg className="bi bi-cloud-check align-self-center" width="100" height="100" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 511.997 511.997">
                    <circle style={{fill: '#002FA7'}} cx="255.999" cy="255.999" r="255.999"/>
                    <path style={{fill: '#FFFFFF'}} d="M97.376,233.736c0.617,0,1.225,0.013,1.836,0.042c9.036-25.27,33.176-43.348,61.546-43.348
                        c7.559,0,14.82,1.287,21.572,3.651c7.607-54.123,54.094-95.773,110.316-95.773c59.296,0,107.769,46.325,111.21,104.758
                        c31.618,1.133,56.901,27.117,56.901,59.012c0,32.617-26.437,59.054-59.054,59.054c-104.611,0-200.309,0-304.326,0
                        c-24.135,0-43.699-19.565-43.699-43.699S73.243,233.736,97.376,233.736z"/>
                    <path style={{fill: '#FFFFFF'}} d="M94.942,227.895c0.617,0,1.225,0.016,1.836,0.042c9.036-25.268,33.176-43.345,61.548-43.345
                        c7.559,0,14.82,1.287,21.572,3.651c7.607-54.123,54.094-95.773,110.316-95.773c59.296,0,107.769,46.325,111.21,104.758
                        c31.62,1.133,56.901,27.117,56.901,59.012c0,32.617-26.437,59.054-59.054,59.054c-104.611,0-200.311,0-304.326,0
                        c-24.135,0-43.699-19.565-43.699-43.699s19.565-43.699,43.699-43.699H94.942z"/>
                    <path style={{fill: '#FFFFFF'}} d="M356.219,361.036l-58.204-5.872l10.702,18.652c0,0-45.048,21.372-86.764,8.176
                        c-41.716-13.196-53.316-47.574-53.316-47.574s10.799,38.469,49.027,63.615c38.228,25.144,77.602,12.714,103.806-4.052l10.494,16.399
                        l24.257-49.341L356.219,361.036z"/>
                    <path style={{fill: '#002FA7'}} d="M188.292,265.211l58.204,5.872l-10.702-18.652c0,0,45.048-21.372,86.764-8.173
                        c41.716,13.196,53.316,47.574,53.316,47.574s-10.799-38.469-49.027-63.615c-38.228-25.144-77.602-12.716-103.806,4.052
                        l-10.494-16.399l-24.257,49.341H188.292z"/>
                    </svg>
                <div className="card-body">
                    <h5 className="card-title text-center">Cloud</h5>
                    <p className="card-text text-left">Dématérialisez l'ensemble de vos missions avec notre <strong>solution cloud agile et résiliente</strong> disponible partout, tout le temps.</p>
                </div>
                </div>
            </Card>
            <Card className="features rounded-top-kease card border-0">
                <div className="text-center" onClick={(e:any) => {Createlink('suivi-avancee-missions-temps-reel')}}>
                <svg className="bi bi-cloud-check align-self-center" width="100" height="100" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 508 508" >
                    <circle style={{fill: '#002FA7'}} cx="254" cy="254" r="254"/>
                    <path style={{fill: '#CED5E0'}} d="M388,410.7H120c-1.7,0-3-1.4-3-3V100.4c0-1.7,1.4-3,3-3h268c1.7,0,3,1.4,3,3v307.3
                        C391,409.3,389.7,410.7,388,410.7z"/>
                    <path style={{fill: '#FFFFFF'}} d="M117,109.4v289.2c0,6.7,5.4,12,12,12h214v-36c0-6.6,5.4-12,12-12h36V109.4c0-6.7-5.4-12-12-12H129
                        C122.3,97.3,117,102.7,117,109.4z"/>
                    <path style={{fill: '#E6E9EE'}} d="M343,410.7l48-48h-36c-6.6,0-12,5.4-12,12V410.7z"/>
                    <rect x="129.3" y="110.8" style={{fill: '#CED5E0'}} width="16.1" height="7.5"/>
                    <rect x="132.3" y="82.3" style={{fill: '#324A5E'}} width="10.1" height="32.3"/>
                    <rect x="162.6" y="110.8" style={{fill: '#CED5E0'}} width="16.1" height="7.5"/>
                    <rect x="165.6" y="82.3" style={{fill: '#324A5E'}} width="10.1" height="32.3"/>
                    <rect x="196" y="110.8" style={{fill: '#CED5E0'}} width="16.1" height="7.5"/>
                    <rect x="198.9" y="82.3" style={{fill: '#324A5E'}} width="10.1" height="32.3"/>
                    <rect x="229.3" y="110.8" style={{fill:' #CED5E0'}} width="16.1" height="7.5"/>
                    <rect x="232.3" y="82.3" style={{fill: '#324A5E'}} width="10.1" height="32.3"/>
                    <rect x="262.6" y="110.8" style={{fill: '#CED5E0'}} width="16.1" height="7.5"/>
                    <rect x="265.6" y="82.3" style={{fill:'#324A5E'}} width="10.1" height="32.3"/>
                    <rect x="296" y="110.8" style={{fill:'#CED5E0'}} width="16.1" height="7.5"/>
                    <rect x="298.9" y="82.3" style={{fill:'#324A5E'}} width="10.1" height="32.3"/>
                    <rect x="329.3" y="110.8" style={{fill:'#CED5E0'}} width="16.1" height="7.5"/>
                    <rect x="332.3" y="82.3" style={{fill:'#324A5E'}} width="10.1" height="32.3"/>
                    <rect x="362.6" y="110.8" style={{fill:'#CED5E0'}} width="16.1" height="7.5"/>
                    <rect x="365.6" y="82.3" style={{fill:'#324A5E'}} width="10.1" height="32.3"/>

                        <path style={{fill: '#E6E9EE'}} d="M177,214.4h-31.1c-4.1,0-7.5-3.4-7.5-7.5v-31.1c0-4.1,3.4-7.5,7.5-7.5H177c4.1,0,7.5,3.4,7.5,7.5
                            V207C184.4,211.1,181.1,214.4,177,214.4z M146.4,206.5h30.2v-30.2h-30.2V206.5z"/>
                        <path style={{fill: '#E6E9EE'}} d="M177,288.7h-31.1c-4.1,0-7.5-3.4-7.5-7.5v-31.1c0-4.1,3.4-7.5,7.5-7.5H177c4.1,0,7.5,3.4,7.5,7.5
                            v31.1C184.4,285.3,181.1,288.7,177,288.7z M146.4,280.7h30.2v-30.2h-30.2V280.7z"/>
                        <path style={{fill: '#E6E9EE'}} d="M177,362.9h-31.1c-4.1,0-7.5-3.4-7.5-7.5v-31.1c0-4.1,3.4-7.5,7.5-7.5H177c4.1,0,7.5,3.4,7.5,7.5
                            v31.1C184.4,359.5,181.1,362.9,177,362.9z M146.4,355h30.2v-30.2h-30.2V355z"/>
                        <path style={{fill: '#FF7058'}} d="M159.5,198.3c0.7,0.9,1.7,1.5,2.9,1.6c0.1,0,0.2,0,0.3,0c1.1,0,2.1-0.4,2.8-1.2l41.1-41.1
                            c1.6-1.6,1.6-4.1,0-5.6c-1.6-1.6-4.1-1.6-5.6,0l-37.9,37.9l-5.6-7.7c-1.3-1.8-3.8-2.2-5.5-0.9c-1.8,1.3-2.1,3.8-0.9,5.5
                            L159.5,198.3z"/>
                        <path style={{fill: '#FF7058'}} d="M201,226.2l-37.9,37.9l-5.6-7.7c-1.3-1.8-3.8-2.2-5.5-0.9c-1.8,1.3-2.1,3.8-0.9,5.5l8.4,11.4
                            c0.7,0.9,1.7,1.5,2.9,1.6c0.1,0,0.2,0,0.3,0c1.1,0,2.1-0.4,2.8-1.2l41.1-41.1c1.6-1.6,1.6-4.1,0-5.6
                            C205.1,224.7,202.6,224.7,201,226.2z"/>
                        <path style={{fill: '#FF7058'}} d="M201,300.5l-37.9,37.9l-5.6-7.7c-1.3-1.8-3.8-2.2-5.5-0.9c-1.8,1.3-2.1,3.8-0.9,5.5l8.4,11.4
                            c0.7,0.9,1.7,1.5,2.9,1.6c0.1,0,0.2,0,0.3,0c1.1,0,2.1-0.4,2.8-1.2l41.1-41.1c1.6-1.6,1.6-4.1,0-5.6
                            C205.1,298.9,202.6,298.9,201,300.5z"/>
                        <rect x="216.6" y="175.4" style={{fill: '#002FA7'}} width="116.9" height="9.1"/>
                        <rect x="216.6" y="198.4" style={{fill: '#002FA7'}} width="70.6" height="9.1"/>
                        <rect x="216.6" y="249.6" style={{fill: '#002FA7'}} width="116.9" height="9.1"/>
                        <rect x="216.6" y="272.6" style={{fill: '#002FA7'}} width="70.6" height="9.1"/>
                        <rect x="216.6" y="323.8" style={{fill: '#002FA7'}} width="116.9" height="9.1"/>
                        <rect x="216.6" y="346.8" style={{fill: '#002FA7'}} width="70.6" height="9.1"/>
                </svg>
                <div className="card-body">
                    <h5 className="card-title text-center">Suivi en temps réel</h5>
                    <p className="card-text text-left">Profitez d'un suivi <strong>de l'avancée de vos missions en temps réel</strong> vous permettant de faire un point rapide avec vos équipes</p>
                </div>
                </div>
            </Card>
            <Card className="features rounded-top-kease card border-0">
                <div className="text-center" onClick={(e:any) => {Createlink('realiser-un-audit-legal-sur-mesure')}}>
                <svg className="bi bi-cloud-check align-self-center" width="100" height="100"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 508 508">
                    <circle style={{fill: '#002FA7'}} cx="254" cy="254" r="254"/>
                    <path style={{fill: '#FFFFFF'}} d="M439.1,272.2v-36.5l-27-5c-3.9-26.8-14.4-51.4-29.9-72.1l15.5-22.6L372,110.2l-22.6,15.5
                        c-20.7-15.4-45.3-26-72.1-29.9l-5-27h-36.5l-5,27c-26.8,3.9-51.4,14.4-72.1,29.9L136,110.2L110.2,136l15.5,22.6
                        c-15.4,20.7-26,45.4-29.9,72.1l-27,5v36.5l27,5c3.9,26.8,14.4,51.4,29.9,72.1L110.2,372l25.8,25.8l22.6-15.5
                        c20.7,15.4,45.4,26,72.1,29.9l5,27h36.5l5-27c26.8-3.9,51.4-14.4,72.1-29.9l22.6,15.5l25.8-25.8l-15.5-22.6
                        c15.4-20.7,26-45.3,29.9-72.1L439.1,272.2z"/>
                    <circle style={{fill: '#002FA7'}} cx="254" cy="254" r="119.5"/>
                    <circle style={{fill: '#FFFFFF'}} cx="254" cy="254" r="103.8"/>
                    <circle style={{fill: '#002FA7'}} cx="254" cy="254" r="59.6"/>
                </svg>
                <div className="card-body">
                    <h5 className="card-title text-center">Personnalisable</h5>
                    <p className="card-text text-left">Notre solution répond aux cabinets qui souhaitent <strong>personnaliser leur démarche d'audit</strong>.</p>
                </div>
                </div>
            </Card>
        </CardGroup>
        <br/>
        <CardGroup className="card-deck">
            <Card className="features rounded-bottom-kease card border-0">
                <div className="text-center" onClick={(e:any) => {Createlink('securite-donnees-daudit')}}>
                <svg className="bi bi-cloud-check align-self-center" width="100" height="100" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 505 505">
                    <circle style={{fill: '#002FA7'}} cx="252.5" cy="252.5" r="252.5"/>
                    <path style={{fill: '#FFFFFF'}} d="M218.6,235v-36.1c0-18.7,15.2-33.9,33.9-33.9s33.9,15.2,33.9,33.9V235h22.3v-36.1
                        c0-31-25.2-56.2-56.2-56.2s-56.2,25.2-56.2,56.2V235H218.6z"/>
                    <path style={{fill: '#FFFFFF'}} d="M324.2,334.7H180.8c-0.6,0-1.1-0.5-1.1-1.1V231.2c0-0.6,0.5-1.1,1.1-1.1h143.3c0.6,0,1.1,0.5,1.1,1.1
                        v102.5C325.2,334.3,324.7,334.7,324.2,334.7z"/>
                    <path style={{fill: '#002FA7'}} d="M266.8,273.3c0-7.9-6.4-14.3-14.3-14.3c-7.9,0-14.3,6.4-14.3,14.3c0,4.9,2.5,9.2,6.2,11.8l-2.8,20.7
                        h11h11l-2.8-20.7C264.4,282.5,266.8,278.2,266.8,273.3z"/>
                    <path style={{fill: '#FFFFFF'}} d="M252.5,437.1c-101.8,0-184.6-82.8-184.6-184.6S150.7,67.9,252.5,67.9s184.6,82.8,184.6,184.6
                        S354.3,437.1,252.5,437.1z M252.5,83.7c-93.1,0-168.8,75.7-168.8,168.8s75.7,168.8,168.8,168.8s168.8-75.7,168.8-168.8
                        S345.6,83.7,252.5,83.7z"/>
                </svg>
                <div className="card-body">
                    <h5 className="card-title text-center">Sécurité</h5>
                    <p className="card-text text-left">Vos <strong>informations sont cryptées</strong>, les droits d'accès sont restreints. Les données sont hébergées en France conformément à la demande du H3C.</p>
                </div>
                </div>
            </Card>
            
            <Card className="features rounded-bottom-kease card border-0">
                <div className="text-center" onClick={(e:any) => {Createlink('assistance-dediee-commissaires-aux-comptes')}}>
                <svg className="bi bi-cloud-check align-self-center" width="100" height="100" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 508 508">
                    <circle style={{fill: '#002FA7'}} cx="250" cy="250" r="250"/>
                    <path style={{fill: '#FFFFFF'}} d="M365.7,416.9c-5.1,0-10.3-0.7-15.4-2.2l-89.2-25.4l3-10.6l89.2,25.4c13.6,3.9,27.9,1.2,39.2-7.3
                        c11.3-8.5,17.8-21.5,17.8-35.7v-24.4h11v24.4c0,17.6-8.1,33.8-22.2,44.5C389.3,413.1,377.6,416.9,365.7,416.9z"/>
                    <path style={{fill: '#FFFFFF'}} d="M422.7,274H405v-45.5c0-84.3-67.8-152.9-151-152.9c-83.3,0-151,68.6-151,152.9v42.2H85.3v-42.2
                        C85.3,134.5,161,58,254,58s168.7,76.5,168.7,170.6V274z"/>
                    <path style={{fill: '#FFFFFF'}} d="M400.9,250.6c0-4.7-2.4-9.1-6.5-11.5c-7.5-4.4-15.9-6.8-24.7-6.8c-1.3,0-2.5,0.1-3.8,0.2
                        c-11.8,0.9-20.9,10.8-20.9,22.7v97.5c0,11.8,9.1,21.7,20.9,22.7c1.3,0.1,2.5,0.2,3.8,0.2c8.8,0,17.2-2.4,24.7-6.8
                        c4.1-2.4,6.5-6.8,6.5-11.5V250.6z"/>
                    <path style={{fill: '#E6E9EE'}} d="M369.9,232.4c-0.1,0-0.2,0-0.2,0c-1.3,0-2.5,0.1-3.8,0.2c-11.8,0.9-20.9,10.8-20.9,22.7v97.5
                        c0,11.8,9.1,21.7,20.9,22.7c1.3,0.1,2.5,0.2,3.8,0.2c0.1,0,0.2,0,0.2,0V232.4z"/>
                    <path style={{fill: '#CED5E0'}} d="M400.9,350.8H428c1.5,0,2.7-1.2,2.7-2.7v-88.2c0-1.5-1.2-2.7-2.7-2.7h-27.1V350.8z"/>
                    <path style={{fill: '#FFFFFF'}} d="M107.1,250.6c0-4.7,2.4-9.1,6.5-11.5c7.5-4.4,15.9-6.8,24.7-6.8c1.3,0,2.5,0.1,3.8,0.2
                        c11.8,0.9,20.9,10.8,20.9,22.7v97.5c0,11.8-9.1,21.7-20.9,22.7c-1.3,0.1-2.5,0.2-3.8,0.2c-8.8,0-17.2-2.4-24.7-6.8
                        c-4.1-2.4-6.5-6.8-6.5-11.5C107.1,357.3,107.1,250.6,107.1,250.6z"/>
                    <path style={{fill: '#E6E9EE'}} d="M138.1,232.4c0.1,0,0.2,0,0.2,0c1.3,0,2.5,0.1,3.8,0.2c11.8,0.9,20.9,10.8,20.9,22.7v97.5
                        c0,11.8-9.1,21.7-20.9,22.7c-1.3,0.1-2.5,0.2-3.8,0.2c-0.1,0-0.2,0-0.2,0V232.4z"/>
                    <path style={{fill: '#CED5E0'}} d="M107.1,350.8h-27c-1.5,0-2.7-1.2-2.7-2.7v-88.2c0-1.5,1.2-2.7,2.7-2.7h27.1v93.6H107.1z"/>
                    <path style={{fill: '#FFFFFF'}} d="M196.4,181.1c1.6-2.3,2.5-4.6,2.5-6.8s-0.7-4-2.1-5.5c-1.4-1.5-3.3-2.2-5.5-2.2
                        c-4.1,0-8,2.9-11.7,8.8l-11.7-6.9c3.1-4.7,6.4-8.3,10-10.7s8.3-3.6,13.9-3.6s10.6,1.8,14.8,5.4c4.3,3.6,6.4,8.4,6.4,14.6
                        c0,3.4-0.9,6.6-2.6,9.7c-1.7,3.1-4.9,7-9.5,11.8l-11.8,12.2H215V221h-46v-10.8l19.1-19.6C192,186.6,194.7,183.5,196.4,181.1z"/>
                    <path style={{fill: '#FFFFFF'}} d="M249.6,192.8v-11.4h14v11.4h7.4v12.6h-7.4V221h-14v-15.6h-26.2v-11.1l23.2-38.5h15.9l-22.1,37.1h9.2
                        V192.8z"/>
                    <path style={{fill: '#FFFFFF'}} d="M295.5,193.9V221h-14v-69.3h14v24.6c4.2-4.3,9-6.4,14.2-6.4c5.3,0,9.7,1.9,13.3,5.6
                        c3.6,3.7,5.4,8.7,5.4,14.9v30.5h-14v-27.4c0-7.7-2.8-11.6-8.5-11.6c-2.8,0-5.2,1-7.3,3C296.6,187,295.5,190,295.5,193.9z"/>
                    <ellipse transform="matrix(0.2467 -0.9691 0.9691 0.2467 -171.7163 545.902)" style={{fill: '#FFFFFF'}} cx="265.282" cy="383.404" rx="20.9" ry="31.6"/>
                </svg>
                <div className="card-body">
                    <h5 className="card-title text-center">Assistance</h5>
                    <p className="card-text text-left">Bénéficiez d'une <strong>assistance client disponible 24/7</strong> et d'un interlocuteur dédié parce c'est plus simple, vous pouvez vous concentrer sur votre métier.</p>
                </div>
                </div>
            </Card>
            <Card className="features rounded-bottom-kease card border-0">
                <div className="text-center" onClick={(e:any) => {Createlink('collaboration-client-echange-donnees-logiciel-audit-financier')}}>
                <svg className="bi bi-cloud-check align-self-center" width="100" height="100" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 508 508">
                    <circle style={{fill: '#002FA7'}} cx="254" cy="254" r="254"/>
                    <path style={{fill: '#FFFFFF'}} d="M308.8,346.4c-2.4,0-4.8,0-7.2,0c-12.4,0-24.8,0-36.8-0.4v112h-21.6v-52c-14.4,0-29.2,0-43.6,0
                        c0.4-2.4,0.8-4.8,0.8-7.2c0-2.8-0.4-5.2-0.8-8c14.4,0,29.2,0,43.6,0v-99.2c-14.4-0.4-29.2-0.4-43.6-0.4c0.4-2.4,0.8-4.8,0.8-7.2
                        c0-2.8-0.4-5.6-0.8-8c14.4,0,29.2,0,43.6,0.4v-99.2c-14.4-0.4-29.2-0.4-43.6-0.4c0.4-2.4,0.8-4.4,0.8-6.8c0-2.8-0.4-5.6-0.8-8.4
                        c14.8,0,29.2,0,43.6,0.4V50h21.6v52c14.4,0,29.2,0.4,43.6,0c-0.4,2.4-0.8,4.8-0.8,7.2c0,2.8,0.4,5.2,0.8,8h-6.8
                        c-12.4,0-24.8,0-36.8,0v99.2c14.4,0.4,29.2,0.4,43.6,0.4c-0.4,2.4-0.8,4.8-0.8,7.2c0,2.8,0.4,5.6,0.8,8c-2.4,0-4.8,0-6.8,0
                        c-12.4,0-24.8,0-36.8-0.4v99.2c14.4,0.4,29.2,0.4,43.6,0.4c-0.4,2.4-0.8,4.4-0.8,6.8C307.6,341.2,308,344,308.8,346.4z"/>
                    <circle style={{fill: '#FFFFFF'}} cx="347.6" cy="109.2" r="39.6"/>
                    <circle style={{fill: '#FFFFFF'}} cx="347.6" cy="224" r="39.6"/>
                    <circle style={{fill: '#FFFFFF'}} cx="347.6" cy="338.4" r="39.6"/>
                    <circle style={{fill: '#FFFFFF'}} cx="160.4" cy="398.8" r="39.6"/>
                    <circle style={{fill: '#FFFFFF'}} cx="160.4" cy="284" r="39.6"/>
                    <circle style={{fill: '#FFFFFF'}} cx="160.4" cy="169.6" r="39.6"/>
                </svg>
                <div className="card-body">
                    <h5 className="card-title text-center">Echangez</h5>
                    <p className="card-text text-left">Facilitez la <strong>collaboration avec vos clients</strong> grâce au partage simplifié des fichiers, la communication est un vrai gain de temps</p>
                </div>
                </div>
            </Card>
        </CardGroup>
        

    </>
  );
};

export default Features