import React from 'react';
import {  Button, ButtonGroup, Card, CardGroup, Container, Form } from 'react-bootstrap';
import { ReactComponent as ReactPlateforme } from '../../assets/plateforme_kease.svg';
import { useLinkNavigation } from '../utils/MenuFunctions';

const References: React.FC = () => {
  const { expanded, toggleExpanded, createLink } = useLinkNavigation();
  const handleClick = (useLink: any) => {
    createLink(useLink); // Utilisation de la fonction createLink
  };
  
  return (
    <>
        <div className="p-5">
        <Card className="bgtransparent border-0 d-flex">
            <Card.Title className="text-nowrap" >
                <p className="">
                  <span className="small">+50 cabinets nous font confiance</span><br/>
                </p>
            </Card.Title>
        </Card>
        </div> 
    </>
  );
};

export default References