import React from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import { useLinkNavigation } from '../utils/MenuFunctions';

interface Props {
  activePage: string; // Valeur de la page active
  createLink: (link: string) => void; // Fonction pour créer un lien
}

const AppFooter: React.FC<Props> = ({ activePage, createLink }) => {

  return (
    <>
      <Navbar className="p-0 navbar-kease" bg="dark" variant="dark" expand="xl">
        <Container fluid>
           <Nav className="mx-auto">
            <Nav className="mx-auto">
                <Nav.Link onClick={() => createLink("")} className={`m-3 ${activePage === '' ? 'active' : ''}`}>
                  Tous droits réservés &#169; {new Date().getFullYear()} kease{' '}
                </Nav.Link>
              </Nav>
              <Navbar.Collapse className="mx-auto">
                <Nav>
                  <Nav.Link onClick={() => createLink("mentions-legales")} className={`m-3 ${activePage === 'mentions-legales' ? 'active' : ''}`}>
                      Mentions légales{' '}
                  </Nav.Link>
                  <Nav.Link onClick={() => createLink("conditions-generales-de-vente")} className={`m-3 ${activePage === 'conditions-generales-de-vente' ? 'active' : ''}`}>  
                      Conditions générales{' '}
                  </Nav.Link>
                  <Nav.Link onClick={() => createLink("politique-de-confidentialite")} className={`m-3 ${activePage === 'politique-de-confidentialite' ? 'active' : ''}`}>  
                      Politique de confidentialité{' '}
                  </Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Nav>
        </Container>
      </Navbar>
    </>
  );
};

export default AppFooter

