import { Col, Row } from 'react-bootstrap';
import aboutImg from '../../assets/logo_kease_white.svg';

const About: React.FC = () => {

  return (

        <Row>
          <Col  className="about__info">
            <h2>Notre mission et nos valeurs</h2>
            <p>
                <strong>kease</strong> c'est la solution de gestion pour les cabinets de commissariat aux comptes qui souhaites évoluer.
                Nous avons fait le choix de nous concentrer sur une solution cloud afin de répondre à ce besoin que nous avons identifié.
                Une interface simplifiée afin que tout le monde puisse utiliser le logiciel sans passer son temps avec un call center pour savoir quoi-faire.
            </p>

            <p>
                Apporter un véritable confort d'utilisation, rapidité, accès, fiabilité et sécurité. 
            </p>

            <p>
              Parce que nous sommes une start-up, notre écosystème ne contient que des praticiens et nous avons adapté notre produit à nos clients et futures clients.
            </p>
          </Col>
          <Col >
            <div className="about__img">
              <img src={aboutImg} alt="about" />
            </div>
          </Col>
        </Row>

  );
};

export default About