import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from "react"
import { Form, Button, Card, Col, Dropdown, CardGroup, Container, Row } from "react-bootstrap"



const EngagementKnowledge: React.FC = () => {

    return (
        <>
        <Container fluid>
                    
                    <p>Prise de connaissance</p>
        </Container>
        </>
    )
}

export default EngagementKnowledge;

