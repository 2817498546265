import { useEffect, useState } from 'react';
import { ref, push, serverTimestamp, onValue, update } from 'firebase/database';
import { auth, dbCollection } from "../../../utils/firebaseconfig";
import { useParams } from 'react-router-dom';
import { Card, Container, Row, Col, Form, Button } from 'react-bootstrap';
import * as XLSX from 'xlsx';
import { read, utils } from 'xlsx';


const BalanceUpload: React.FC = () => {
  const [file, setFile] = useState<File | null>(null);
  const [dateClosing, setDateClosing] = useState();
  const params = useParams<{ uid: string }>();
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    const fetchDateClosing = () => {
      const dateClosingRef = ref(dbCollection, `engagements/${params.uid}/engagementDateCloture`);
      onValue(dateClosingRef, (snapshot) => {
        const closingDate = snapshot.val();
        setDateClosing(closingDate);
      });
    };

    fetchDateClosing();
  }, [params.uid]);

  async function handleFileUpload() {
    if (!file) return;
    if (file) {
      setFile(file);
      await readFileContents(file);
    }
  };

  const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0];
    if (selectedFile) {
      setFile(selectedFile);
    }
  };

  const readFileContents = async (uploadedFile: File) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const fileContents = event.target?.result as string;
      let jsonData;
  
      if (uploadedFile.name.endsWith('.txt')) {
        jsonData = convertFromTXT(fileContents);
      } else if (uploadedFile.name.endsWith('.xlsx')) {
        convertFromXLSX(uploadedFile)
          .then((convertedData: any[]) => {
            jsonData = convertedData;
            if (jsonData) {
              importBalanceToFirebase(jsonData);
            }
          })
          .catch((error) => {
            console.error('Error converting Excel to JSON:', error);
          });
      } else {
        // Gérer le cas des autres extensions de fichier
      }
  
      if (jsonData) {
        importBalanceToFirebase(jsonData);
      }
    };

    reader.readAsText(uploadedFile);
  };

  const convertFromTXT = (fileContents: string) => {
    const lines = fileContents.split('\n');
    const headers = lines[0].split('\t');
    const jsonData = [];
  
    for (let i = 1; i < lines.length; i++) {
      const values = lines[i].split('\t');
      const entry: { [key: string]: string } = {};
  
      for (let j = 0; j < headers.length; j++) {
        const cleanKey = (headers[j] ?? '')
          .replace(/[€_$#\[\]\/]/g, '')
          .replace(/[éèê]/g, 'e')
          .replace(/[àâ]/g, 'a')
          .replace(/[î]/g, 'i')
          .replace(/[ô]/g, 'o')
          .replace(/[û]/g, 'u')
          .trim();
        let cleanValue = (values[j] ?? '')
          .replace(/[€_$#\[\]\/]/g, '')
          .replace(/[éèê]/g, 'e')
          .replace(/[àâ]/g, 'a')
          .replace(/[î]/g, 'i')
          .replace(/[ô]/g, 'o')
          .replace(/[û]/g, 'u')
          .trim();
          if (cleanValue === '-') {
            cleanValue = '0'; // Mettre à jour cleanValue avec la valeur modifiée
          }
        entry[cleanKey] = cleanValue || '0';
      }
  
      jsonData.push(entry);
    }
  
    return jsonData;
  };
  

  const convertFromXLSX = (file: File) => {
    return new Promise<any[]>((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.onload = (e) => {
        try {
          const arrayBuffer = e.target?.result;
          const workbook = read(arrayBuffer, { type: 'array' });
          const worksheet = workbook.Sheets[workbook.SheetNames[0]];
          const jsonData = utils.sheet_to_json(worksheet, { raw: false });
          resolve(jsonData);
        } catch (error) {
          reject(error);
        }
      };
      fileReader.readAsArrayBuffer(file);
    });
  };

  const importBalanceToFirebase = (jsonData: any[]) => {
    const userId = auth.currentUser?.uid;
    if (userId) {
      const balanceRef = ref(dbCollection, `engagements/${params.uid}/balances`);
      const newBalanceRef = push(balanceRef);
      const newBalanceData = {
        idbalance: newBalanceRef.key,
        data: jsonData,
        cloture: dateClosing,
        dateimport: serverTimestamp(),
      };

      update(ref(dbCollection, `engagements/${params.uid}/balances/` + newBalanceRef.key), newBalanceData)
      .then(() => {
        setSuccess(true);
      })
      .catch((error) => {
        console.error('Error updating balance data:', error);
      });
    }
  };

  return (
    <>
        <Container fluid>
        {success && <p className="text-success">Balance importée avec succès!</p>}
          <Card className="border-0">
            <Row>
            <Col>
            <Form.Group controlId="formFile" className="mb-3">
              <Form.Control type="file" onChange={handleFileSelect}/>
            </Form.Group>
            </Col>
            <Col>
              <Button className="mb-3 btn-kease" type="submit" onClick={handleFileUpload}>Importer</Button>
            </Col>
            </Row>
          </Card>

        </Container>
      </>

  );
};

export default BalanceUpload;
