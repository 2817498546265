import { Container, Nav, Navbar, Button } from 'react-bootstrap';
import '../../index.css';
import { ReactComponent as ReactLogo } from '../../assets/logo_kease.svg';

import { useLinkNavigation } from '../utils/MenuFunctions';
import { useState } from 'react';

interface Props {
  activePage: string; // Valeur de la page active
  createLink: (link: string) => void; // Fonction pour créer un lien
}

const PublicMenu: React.FC<Props> = ({ activePage, createLink }) => {

  const { expanded, toggleExpanded } = useLinkNavigation();
 
  return (
    <>
      <Navbar className="p-0 navbar-kease" expanded={expanded} onToggle={toggleExpanded} bg="dark" variant="dark" fixed="top" expand="xl">
        <Container fluid>
          <Navbar.Brand className="app-header-logo" onClick={() => createLink("")}>
            <ReactLogo className="bi bi-cloud-check" width="150" height="50" />{' '}
          </Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse>
            <Nav className="me-auto">
              <Nav.Link onClick={() => createLink("solution")} className={`m-3 ${activePage === 'solution' ? 'active' : ''}`}>
                Solution{' '}
              </Nav.Link>
              <Nav.Link onClick={() => createLink("pricing")} className={`m-3 ${activePage === 'pricing' ? 'active' : ''}`}>
                Tarifs{' '}
              </Nav.Link>
              <Nav.Link onClick={() => createLink("ressources")} className={`m-3 ${activePage === 'ressources' ? 'active' : ''}`}>
                Ressources{' '}
              </Nav.Link>
              <Nav.Link onClick={() => createLink("contacts")} className={`m-3 ${activePage === 'contacts' ? 'active' : ''}`}>
                Nous Contacter{' '}
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>

          <Navbar.Collapse className="mx-auto justify-content-end">
            <Nav>
              <Button onClick={() => createLink("login")} className="border-2 btn btn-cap border-cap rounded-kease m-3">
                Se&nbsp;connecter
              </Button>
              <br className="d-none d-xs-none d-md-none" />
              <Button onClick={() => createLink("create-account")} className="border-2 btn btn-kease border-cap rounded-kease m-3">
                S'inscrire
              </Button>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default PublicMenu;
