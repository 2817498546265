import React, { useEffect, useState } from 'react';

interface AuthProps {
  progress: any;
  hovered: boolean;
}

const CircularProgressBar: React.FC<AuthProps> = ({ progress, hovered }) => {

  const [circleRadius, setCircleRadius] = useState<number>(0);

  const angle = (progress / 100) * 360;

  useEffect(() => {
    const fetchData = async () => {
      const radius = (progress / 100) * 100;
      setCircleRadius(radius);
    };

    fetchData();
  }, [progress]);


  const progressBarStyle: React.CSSProperties = {
    width: '150px',
    height: '150px',
    borderRadius: '50%',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const progressCircleStyle: React.CSSProperties = {
    width: '100%',
    height: '100%',
    borderRadius: '50%',
    background: `conic-gradient(#002FA7 ${progress}%, #def ${progress}% 100%)`,
    position: 'absolute',
    clipPath: 'polygon(50% 0, 100% 0, 100% 100%, 0 100%, 0 0)',
  };

  const hoverProgressCircleStyle: React.CSSProperties = {
    background: `conic-gradient(#FFFFFF ${progress}%, #002FA7 ${progress}% 100%)`,
  };

  const progressCenterStyle: React.CSSProperties = {
    width: `80%`,
    height: `80%`,
    borderRadius: '50%',
    background: '#fff',
    position: 'absolute',
    clipPath: 'polygon(0% 0%, 0% 100%, 100% 100%, 100% 0%, 50% 0%)',
  };

  const hoverProgressCenterStyle: React.CSSProperties = {
    background: '#002FA7',
  };

  const zerocircleStyle: React.CSSProperties = {
    width: '10%',
    height: '10%',
    borderRadius: '50%',
    background: '#002FA7',
    position: 'absolute',
    top: '5%',
    left: '50%',
    transform: `translate(-50%, -50%) rotateZ(0deg)`,
    transformOrigin: `50% 100px`,
  };

  const hoverZerocircleStyle: React.CSSProperties = {
    background: '#FFffff',
  };

  const gradientcircleStyle: React.CSSProperties = {
    width: '10%',
    height: '10%',
    borderRadius: '50%',
    background: '#002FA7',
    position: 'absolute',
    top: '5%',
    left: '50%',
    transform: `translate(-50%, -50%) rotateZ(${angle}deg)`,
    transformOrigin: `50% 75px`,
  };

  const hoverGradientcircleStyle: React.CSSProperties = {
    background: '#FFffff',
  };

  const textStyle: React.CSSProperties = {
    fontSize: '30px',
    fontWeight: 'bold',
    color: '#002FA7',
  };

  const hoverTextStyle: React.CSSProperties = {
    color: '#FFffff',
  };

  const zeroCircle = progress > 0 && <div style={{...zerocircleStyle, ...(hovered ? hoverZerocircleStyle : {}) }}/>;
  const gradientCircle = progress > 0 && <div className=" " style={{...gradientcircleStyle, ...(hovered ? hoverGradientcircleStyle : {}) }} />;



  return (
    <>
    <div style={progressBarStyle}>
      <div style={{...progressCircleStyle, ...(hovered ? hoverProgressCircleStyle : {}) }} />
      {zeroCircle}
      {gradientCircle}
      <div className="d-flex align-items-center justify-content-center" style={{...progressCenterStyle, ...(hovered ? hoverProgressCenterStyle : {}) }}>
        <div className=" " style={{...textStyle, ...(hovered ? hoverTextStyle : {}) }}
          >
          {progress}%
        </div>
      </div>
    </div>
    </>
  );
};

export default CircularProgressBar;
