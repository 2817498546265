import { Card, CardGroup, Col, Row } from "react-bootstrap";
import { auth, dbCollection } from "../../../utils/firebaseconfig";
import { orderByChild, query, ref, onValue } from 'firebase/database';
import { useEffect, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { formatfullDate } from "../../utils/MenuFunctions";

const DataImport: React.FC = () => {
  const [balances, setBalances] = useState<any[]>([]);
  const [grandLivres, setGrandLivres] = useState<any[]>([]);
  const [fecs, setFecs] = useState<any[]>([]);
  const navigate = useNavigate();
  const location = useLocation();
  let params = useParams();
  
  useEffect(() => {
    const fetchBalances = () => {
      const userId = auth.currentUser?.uid;
      if (userId) {
        const balancesRef = ref(dbCollection, 'engagements/' + params.uid + '/balances/');
        const balancesQuery = query(balancesRef, orderByChild('dateimport'));

        onValue(balancesQuery, (snapshot) => {
          const balances: any[] = [];
          snapshot.forEach((childSnapshot) => {
            const balance = childSnapshot.val();
            balances.push(balance);
        });

        // Tri des balances par ordre décroissant de dateimport
        const sortedBalances = balances.sort((a, b) => {
            const dateA = new Date(a.dateimport).getTime();
            const dateB = new Date(b.dateimport).getTime();
            return dateB - dateA;
        });

          setBalances(sortedBalances);
        });
      }
    };

    const fetchGrandLivres = () => {
      const userId = auth.currentUser?.uid;
      if (userId) {
        const grandLivresRef = ref(dbCollection, 'engagements/' + params.uid + '/grand-livres/');
        const grandLivresQuery = query(grandLivresRef, orderByChild('dateimport'));

        onValue(grandLivresQuery, (snapshot) => {
          const grandLivres: any[] = [];
          snapshot.forEach((childSnapshot) => {
            const grandLivre = childSnapshot.val();
            grandLivres.push(grandLivre);
          });

        // Tri des Grands-livres par ordre décroissant de dateimport
        const sortedGrandLivres = grandLivres.sort((a, b) => {
            const dateA = new Date(a.dateimport).getTime();
            const dateB = new Date(b.dateimport).getTime();
            return dateB - dateA;
        });

        setGrandLivres(sortedGrandLivres);
        });
      }
    };

    const fetchFecs = () => {
      const userId = auth.currentUser?.uid;
      if (userId) {
        const fecsRef = ref(dbCollection, 'engagements/' + params.uid + '/fecs/');
        const fecsQuery = query(fecsRef, orderByChild('dateimport'));

        onValue(fecsQuery, (snapshot) => {
          const fecs: any[] = [];
          snapshot.forEach((childSnapshot) => {
            const fec = childSnapshot.val();
            fecs.push(fec);
          });

        // Tri des Fecs par ordre décroissant de dateimport
        const sortedFecs = fecs.sort((a, b) => {
            const dateA = new Date(a.dateimport).getTime();
            const dateB = new Date(b.dateimport).getTime();
            return dateB - dateA;
        });

        setFecs(sortedFecs);
        });
      }
    };

    fetchBalances();
    fetchGrandLivres();
    fetchFecs();
  }, []);

  const handleSelect = (selectedKey: string) => {
    const segments = location.pathname.split("/");
    const uid = segments[2];
    const newURL = segments.slice(0, 3).join("/") + selectedKey;
    navigate(newURL);
  };

  return (
    <>
      <Card className="border-0">
        <Card.Body>
          <h2 className="text-kease">Vous voulez importer quel élément comptable?</h2>
        </Card.Body>
      </Card>

      <div className="mx-auto">
        <Row xs={1} md={2} lg={3} xl={4} className="g-4">
          <CardGroup className="w-100">
            <Col>
              <Card className="border border-1 rounded border-kease h-100 mx-3">
                <Card.Header onClick={() => handleSelect("/import-balance")} className="kease-font">
                  <h3 className="font-kease text-center text-white">Balance</h3>
                </Card.Header>
                <Card.Body>
                  {balances.length === 0 ? (
                    <p className="text-center">Aucune donnée importée</p>
                  ) : (
                    <ul className="list-group">
                      {balances.map((balance) => (
                        <li
                          key={balance.id}
                          className="list-group-item d-flex justify-content-between align-items-center"
                        >
                          {formatfullDate(balance.dateimport)}
                        </li>
                      ))}
                    </ul>
                  )}
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card className="border border-1 rounded border-kease h-100 mx-3">
                <Card.Header onClick={() => handleSelect("/import-grand-livre")} className="kease-font">
                  <h3 className="font-kease text-center text-white">Grand-livre</h3>
                </Card.Header>
                <Card.Body>
                  {grandLivres.length === 0 ? (
                    <p className="text-center">Aucune donnée importée</p>
                  ) : (
                    <ul className="list-group">
                      {grandLivres.map((grandLivre) => (
                        <li
                          key={grandLivre.id}
                          className="list-group-item d-flex justify-content-between align-items-center"
                        >
                          {grandLivre.dateimport}
                        </li>
                      ))}
                    </ul>
                  )}
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card className="border border-1 rounded border-kease h-100 mx-3">
                <Card.Header onClick={() => handleSelect("/import-fec")} className="kease-font">
                  <h3 className="font-kease text-center text-white">FEC</h3>
                </Card.Header>
                <Card.Body>
                  {fecs.length === 0 ? (
                    <p className="text-center">Aucune donnée importée</p>
                  ) : (
                    <ul className="list-group">
                      {fecs.map((fec) => (
                        <li
                          key={fec.id}
                          className="list-group-item d-flex justify-content-between align-items-center"
                        >
                          {fec.dateimport}
                        </li>
                      ))}
                    </ul>
                  )}
                </Card.Body>
              </Card>
            </Col>
          </CardGroup>
        </Row>
      </div>
    </>
  );
};

export default DataImport;
