import React, { Dispatch, SetStateAction, useRef, useState } from "react"
import { Form, Button, Card, Col, Dropdown, CardGroup } from "react-bootstrap"
import { auth, dbCollection } from "../../utils/firebaseconfig";
import { useNavigate } from "react-router-dom"
import { ref, push, child, update } from "firebase/database";

const NewEngagement: React.FC = () => {

    const [engagementSiren, setEngagementSiren] = useState([])
    const [engagementSiret, setEngagementSiret] = useState([])
    const [engagementDepot, setDepot] = useState([])
    const [engagementNameDate, setengagementNameDate] = useState(null)
    const [engagementDate, setengagementDate] = useState(new Date());

    const urlSiren = 'https://api.insee.fr/entreprises/sirene/V3/siren/'
    const urlSiret = 'https://api.insee.fr/entreprises/sirene/V3/siret/'

    const urlDepot = 'https://bodacc-datadila.opendatasoft.com/api/records/1.0/search/?dataset=annonces-commerciales&sort=record_timestamp&facet=publicationavis&refine.familleavis=dpc&q='
    const [date, setDate] = useState(new Date());
    const [error, setError] = useState("")

    const [NatureengagementRef, setSelectedValue] = useState("Selectionner");

    async function EngagementSearch(engagementSiren: React.MutableRefObject<HTMLInputElement>) {

        const response = await fetch(`${urlSiren}${engagementSiren.current.value}`, {
        headers: {
            Accept: "application/json",
            Authorization: "Bearer 8c39efc6-151a-3f20-be35-3e049e30c090",
        },
        });

        const siren = await response.json();
        setEngagementSiren([siren]);
    
        const siretResponse = await fetch(
        `${urlSiret}${siren.uniteLegale.siren}${siren.uniteLegale.periodesUniteLegale[0].nicSiegeUniteLegale}`,
        {
            headers: {
            Accept: "application/json",
            Authorization: "Bearer 8c39efc6-151a-3f20-be35-3e049e30c090",
            },
        }
        );
        const siret = await siretResponse.json();
        setEngagementSiret([siret]);

        try {
        const depotResponse = await fetch(`${urlDepot}${siren.uniteLegale.siren}`, {
        headers: {
            Accept: "application/json",
        },
        });
        const depot = await depotResponse.json();
        setDepot([depot]);
        const dateString = depot.records[0].fields.depot.substring(17,27);
        const date = new Date(dateString); 
        date.setFullYear(date.getFullYear() + 1);
        setengagementNameDate((parseInt(depot.records[0].fields.depot.substring(19,21), 10) + 1)+depot.records[0].fields.depot.substring(22,24)+depot.records[0].fields.depot.substring(25,27)+" "+SocieteClientRef.current.value)
        setengagementDate(date);
        } catch (error) {
            setengagementNameDate("")
            setengagementDate(new Date())
      }
    }

    const engagementnameRef = useRef<HTMLInputElement>(null);
    const SirenClientRef = useRef<HTMLInputElement>(null);
    const SiretClientRef = useRef<HTMLInputElement>(null);
    const SocieteClientRef = useRef<HTMLInputElement>(null);
    const ActiviteClientRef = useRef<HTMLInputElement>(null);
    const AdresseClientRef = useRef<HTMLInputElement>(null);
    const CodePostalClientRef = useRef<HTMLInputElement>(null);
    const VilleClientRef = useRef<HTMLInputElement>(null);
    const engagementClotureRef = useRef<HTMLInputElement>(null);

    const navigate = useNavigate()

    async function handleSubmit() {
    
        try {
            setError("")

            const newEngagementKey = push(child(ref(dbCollection), 'engagements/')).key

            if (NatureengagementRef==="Sélectionner"){
                setSelectedValue("Social")
            }

            const engagementData = {
                uid: newEngagementKey,
                name: engagementnameRef.current.value,
                entitySiren : SirenClientRef.current.value,
                entityName : SocieteClientRef.current.value,
                entityActivite : ActiviteClientRef.current.value,
                entitySiret : SiretClientRef.current.value,
                entityAdress : AdresseClientRef.current.value,
                entityPostalCode : CodePostalClientRef.current.value,
                entityTown : VilleClientRef.current.value,
                engagementDateCloture : engagementClotureRef.current.value,
                engagementNature : NatureengagementRef,
                Progress: 0,
                usersaccess: auth.currentUser.uid,
                data: {
                    interim: {},
                    knowledge: {},
                    final: {},
                    opinion: {}
                  }
              };

            const engagementDatasimple = {
                uid: newEngagementKey,
                name: engagementnameRef.current.value,
            };

            // Mettre à jour les données dans Firebase et obtenir les promesses
            const updateEngagementDataPromise = update(
                ref(dbCollection, 'engagements/' + newEngagementKey),
                engagementData
            );
            const updateEngagementAccessPromise = update(
                ref(dbCollection, 'users/' + auth.currentUser.uid + '/engagements-access/' + newEngagementKey),
                engagementDatasimple
            );
        
            // Attendre que les deux mises à jour soient terminées
            await Promise.all([updateEngagementDataPromise, updateEngagementAccessPromise]);
        
            // Naviguer vers le tableau de bord après les mises à jour réussies
            navigate('/dashboard');
        } catch {
          setError("Impossible de créer votre compte - veuillez réessayer ultérieurement ou contacter un administrateur")
        }
      }

    async function cancelSubmit(){
        try {
            setError("")
            navigate('/dashboard')
        } catch {
          setError("Impossible de créer votre compte - veuillez réessayer ultérieurement ou contacter un administrateur")
        }
    }

    return (
    <>
        <br/>
        <p></p>
        <h2 className="w-100 text-center mb-3">Nouvel engagement</h2>
        <br/>
        <CardGroup>    
        <Card className="border-0 mx-auto">
            <Card.Title>
                <h2 className="text-center">Informations Société</h2>
            </Card.Title>
            <Card.Body>
                <Form >
                    <Form.Group className="mb-3 row">
                        
                        <Form.Group className="mb-3 row">
                            <Col xs={6}>    
                                <strong>Numéro SIREN :</strong>
                            </Col>
                            <Col xs={6}>
                                <Form.Control type="string" ref={SirenClientRef} placeholder="Numéro SIREN de la Société" required />
                            </Col>
                        </Form.Group>
                        <Form.Group className="mb-3 row">
                            <Col xs={6}></Col>
                            <Col xs={6}><Button className="text-center btn-kease w-100" onClick={(e:any) => {EngagementSearch(SirenClientRef)}}>
                            Rechercher
                            </Button>
                            </Col>
                        </Form.Group>
                        
                        {
                            engagementSiren.map(siren => {
                                return (
                                    <Form>
                                        <Form.Group className="mb-3 row">
                                            <Form.Group className="mb-3 row">
                                                <Col xs={6}>    
                                                    <strong>Société : </strong>
                                                </Col>
                                                <Col xs={6}>
                                                    <Form.Control type="string" ref={SocieteClientRef} 
                                                        defaultValue={siren.uniteLegale.periodesUniteLegale[0].denominationUniteLegale}
                                                        required />
                                                </Col>
                                            </Form.Group>

                                            <Form.Group className="mb-3 row">
                                                <Col xs={6}>    
                                                    <strong>Activité : </strong>
                                                </Col>
                                                <Col xs={6}>
                                                    <Form.Control type="string" ref={ActiviteClientRef} 
                                                        defaultValue={siren.uniteLegale.periodesUniteLegale[0].activitePrincipaleUniteLegale}
                                                        required />
                                                </Col>
                                            </Form.Group>

                                            <Form.Group className="mb-3 row">
                                                <Col xs={6}>    
                                                    <strong>Siret : </strong>
                                                </Col>
                                                <Col xs={6}>
                                                    <Form.Control type="string" ref={SiretClientRef} 
                                                        defaultValue={siren.uniteLegale.siren+siren.uniteLegale.periodesUniteLegale[0].nicSiegeUniteLegale}
                                                        required />
                                                </Col>
                                            </Form.Group>
                                        </Form.Group>
                                    </Form>
                                )
                            })
                        }


                        {
                            engagementSiret.map(siret => {
                                return (
                                    <Form>
                                        <Form.Group className="mb-3 row">
                                            <Form.Group className="mb-3 row">
                                                <Col xs={6}>    
                                                    <strong>Adresse : </strong>
                                                </Col>
                                                <Col xs={6}>
                                                    <Form.Control type="string" ref={AdresseClientRef} 
                                                        defaultValue={
                                                            siret.etablissement.adresseEtablissement.numeroVoieEtablissement+" "
                                                            +siret.etablissement.adresseEtablissement.typeVoieEtablissement+" "
                                                            +siret.etablissement.adresseEtablissement.libelleVoieEtablissement
                                                            }
                                                        required />
                                                </Col>
                                            </Form.Group>
                                            <Form.Group className="mb-3 row">
                                                <Col xs={6}>    
                                                    <strong>Code Postal : </strong>
                                                </Col>
                                                <Col xs={6}>
                                                    <Form.Control type="string" ref={CodePostalClientRef} 
                                                        defaultValue={siret.etablissement.adresseEtablissement.codePostalEtablissement}
                                                        required />
                                                </Col>
                                            </Form.Group>
                                            <Form.Group className="mb-3 row">
                                                <Col xs={6}>    
                                                    <strong>Ville : </strong>
                                                </Col>
                                                <Col xs={6}>
                                                    <Form.Control type="string" ref={VilleClientRef} 
                                                        defaultValue={siret.etablissement.adresseEtablissement.libelleCommuneEtablissement}
                                                        required />
                                                </Col>
                                            </Form.Group>
                                        </Form.Group>
                                    </Form>
                                )
                            })
                        }
                    </Form.Group>
                </Form>
            </Card.Body>
        </Card>

        <Card className="border-0 mx-auto">
            <Card.Title>
                <h2 className="text-center">Informations Engagement</h2>
            </Card.Title>
            <Card.Body>
                {
                    engagementDepot.map(depot => {
                        return (
                            <Form>
                                <Form.Group className="mb-3 row">
                                    <Form.Group className="mb-3 row">
                                        <Col xs={6}>    
                                            <strong>Nom de l'engagement:</strong>
                                        </Col>
                                        <Col xs={6}>
                                            <Form.Control type="string" ref={engagementnameRef}
                                                defaultValue={engagementNameDate}
                                                required />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group className="mb-3 row">
                                        <Col xs={6}>    
                                            <strong>Date de clôture : </strong>
                                        </Col>
                                        <Col xs={6}>
                                            <Form.Control type="date" ref={engagementClotureRef} 
                                                defaultValue={engagementDate.toISOString().substring(0, 10)}                                                
                                                required />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group className="mb-3 row">
                                        <Col xs={6}>    
                                            <strong>Nature de l'engagement :</strong>
                                        </Col>
                                        <Col xs={6}>
                                            <Dropdown onSelect={(eventKey:any) => setSelectedValue(eventKey)}>
                                                    <Dropdown.Toggle className="mb-3 btn-kease w-50" >{NatureengagementRef}</Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item eventKey="Social">Social</Dropdown.Item>
                                                        <Dropdown.Item eventKey="Consolidation">Consolidation</Dropdown.Item>
                                                    </Dropdown.Menu>
                                            </Dropdown>
                                        </Col>
                                    </Form.Group>
                                </Form.Group>
                            </Form>
                        )
                    })
                }

            </Card.Body>
        </Card>
        </CardGroup>  
        
        <Form>
            <Form.Group className="mb-3 row text-center">
                <Col xs={6}> 
                    <Button className="mb-3 btn-warning w-50" type="submit" onClick={cancelSubmit}>
                        Annuler
                    </Button>
                </Col>
                <Col xs={6}> 
                    <Button className="mb-3 btn-kease w-50" type="submit" onClick={handleSubmit}>
                        Créer l'engagement
                    </Button>
                </Col>
            </Form.Group>
        </Form>
            
    </>
    )
}

export default NewEngagement