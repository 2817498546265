import { Card, CardGroup, Col, Container, Form, Row } from 'react-bootstrap';
import aboutImg from '../../assets/logo_kease_white.svg';
import { useNavigate } from 'react-router-dom';

const Dynamique: React.FC = () => {
  const navigate = useNavigate();
  function handleNav(direction: string) {
    navigate('/'+direction)
  }

  return (
    <>

  <Container>
  <CardGroup className="">
        <Card className="border border-1 border-kease rounded-kease p-3 m-3">
            <br/>
            <Card.Title>
                <h2 className="text-center text-kease">Importez vos balances, FEC, ou grands-livres</h2>
            </Card.Title>
            <Card.Body>
                <Form >
                    <Form.Group className="mb-1 row">
                        <p className="align-middle">
                        Kease utilise les données comptables que vous lui fournissez pour construire automatiquement les différents états financiers. Vous avez 3 moyens d’importer vos données sur kease :
                        </p>
                        <ul className="tick-marck-features mb-1">
                            <li> <span className="text-decoration-underline">Import de balance</span> : Importez simplement les balances de votre client, le traitement est rapide. Cela vous permettra non seulement d’obtenir vos états financiers. Vous aurez aussi la possibilité de modifier les affectations comptables.</li>
                            <li> <span className="text-decoration-underline">Import de grand livre</span> : Si votre client dispose d'une comptabilité analytique, vous avez la possibilité d’importer son grand livre en y ajoutant les catégories analytiques. Cela vous permettra non seulement d’obtenir vos états financiers mais également d’avoir une vue détaillée sur le résultat des différentes catégories analytiques.</li>
                            <li> <span className="text-decoration-underline">Import de fichier d’écriture comptable</span> : Le FEC c’est un fichier qui comprend l’ensemble des écritures comptables d’une entreprise. Le format du FEC est normé et Kease est capable de le lire et de reconstituer automatiquement vos états de synthèse sous un format dynamique et personnalisable.</li>
                        </ul>
                    </Form.Group>
                    <br/>
                    <Form.Group className="row">
                    <a className="small" onClick={(e:any) => {handleNav("contact-us")}}><strong>En savoir plus</strong></a>
                    </Form.Group>
                </Form>
            </Card.Body>
        </Card>

        <Card className="border-0 mx-auto p-3 m-3">
            <Card.Body>
            </Card.Body>
        </Card>
        </CardGroup> 
      </Container>
  <br/>
  </>
  );
};

export default Dynamique