import { useEffect, useState } from 'react';
import { Col, Nav, Row } from 'react-bootstrap';


const ConditionsGénérales: React.FC = () => {

  const [isMenuVisible, setMenuVisible] = useState(false);

  useEffect(() => {

  }, []);

  const handleMenuLinkClick = (event : any) => {
    event.preventDefault(); // Empêche le comportement par défaut du lien
  
    const targetSectionId = event.target.getAttribute('href'); // Obtient l'ID de la section ciblée
    const targetSection = document.querySelector(targetSectionId); // Sélectionne la section ciblée
  
    if (targetSection) {
      const offset = targetSection.offsetTop - 100; // Calcule le décalage en soustrayant 100 pixels
      window.scrollTo({ top: offset, behavior: 'smooth' }); // Fait défiler la page jusqu'à la section avec une animation fluide
    }
  };

  return (
    <div className="about">
      <div className="container">
        <Row>
          <Col className="col-3 m-3">
          <Nav className="vertical-nav-menu custom-sticky-top border border-1 border-kease rounded-3">
            <p className="fw-bold m-2 ps-2">Table des matières</p>
            <Nav.Link href="#article1" className="nav-link small" onClick={handleMenuLinkClick}>
            Article 1 - Mentions légales
            </Nav.Link>
            <Nav.Link href="#article2" className="nav-link small" onClick={handleMenuLinkClick}>
            Article 2 - Champ d'application
            </Nav.Link>
            <Nav.Link href="#article3" className="nav-link small" onClick={handleMenuLinkClick}>
            Article 3 - Description des services
            </Nav.Link>
            <Nav.Link href="#article4" className="nav-link small" onClick={handleMenuLinkClick}>
            Article 4 - Conditions d'inscription
            </Nav.Link>
            <Nav.Link href="#article5" className="nav-link small" onClick={handleMenuLinkClick}>
            Article 5 - Commandes
            </Nav.Link>
            <Nav.Link href="#article6" className="nav-link small" onClick={handleMenuLinkClick}>
            Article 6 - Conditions tarifaires
            </Nav.Link>
            <Nav.Link href="#article7" className="nav-link small" onClick={handleMenuLinkClick}>
            Article 7 - Modes de paiement
            </Nav.Link>
            <Nav.Link href="#article8" className="nav-link small" onClick={handleMenuLinkClick}>
            Article 8 - Obligations du prestataire
            </Nav.Link>
            <Nav.Link href="#article9" className="nav-link small" onClick={handleMenuLinkClick}>
            Article 9 - Obligations du client
            </Nav.Link>
            <Nav.Link href="#article10" className="nav-link small" onClick={handleMenuLinkClick}>
            Article 10 - Réclamations
            </Nav.Link>
            <Nav.Link href="#article11" className="nav-link small" onClick={handleMenuLinkClick}>
            Article 11 - Responsabilité du prestataire
            </Nav.Link>
            <Nav.Link href="#article12" className="nav-link small" onClick={handleMenuLinkClick}>
            Article 12 - Systèmes d'enregistrement
            </Nav.Link>
            <Nav.Link href="#article13" className="nav-link small" onClick={handleMenuLinkClick}>
            Article 13 - Données personnelles
            </Nav.Link>
            <Nav.Link href="#article14" className="nav-link small" onClick={handleMenuLinkClick}>
            Article 14 - Liens hypertextes
            </Nav.Link>
            <Nav.Link href="#article15" className="nav-link small" onClick={handleMenuLinkClick}>
            Article 15 - Propriété intellectuelle
            </Nav.Link>
            <Nav.Link href="#article16" className="nav-link small" onClick={handleMenuLinkClick}>
            Article 16 - Durée - Suspension - Résiliation
            </Nav.Link>
            <Nav.Link href="#article17" className="nav-link small" onClick={handleMenuLinkClick}>
            Article 17 - Confidentialité
            </Nav.Link>
            <Nav.Link href="#article18" className="nav-link small" onClick={handleMenuLinkClick}>
            Article 18 - Notifications
            </Nav.Link>
            <Nav.Link href="#article19" className="nav-link small" onClick={handleMenuLinkClick}>
            Article 19 - Autonomie et absence de renonciation
            </Nav.Link>
            <Nav.Link href="#article20" className="nav-link small" onClick={handleMenuLinkClick}>
            Article 20 - Modification
            </Nav.Link>
            <Nav.Link href="#article21" className="nav-link small" onClick={handleMenuLinkClick}>
            Article 21 - Litiges
            </Nav.Link>
            <Nav.Link href="#article22" className="nav-link small" onClick={handleMenuLinkClick}>
            Article 22 - Droit applicable et langue du contrat
            </Nav.Link>
          </Nav>
          </Col>
          <Col className="about__info mt-3">
            <h1 className="fw-bold">Conditions Générales d'Utilisation</h1>
            <br/>
            <p><em>Date de dernière mise à jour : 26 mai 2023</em></p>
            <br/>
            <section id="article1">
            <h2 className="fw-bold">Article 1 - Mentions légales</h2>
            <p>
            Le présent site, accessible à l'URL www.kease.fr (le « Site »), est édité par :
            </p>
            <p>
            PILOT CONSEILS (ci-après « kease ») au capital de 5 000 euros, inscrite au R.C.S. de Nanterre sous le numéro 882 372 204, dont le siège social est situé au 12 rue Traversière - 92100 Boulotgne-Billancourt, représentée par Romain PILOT dûment habilité,
            </p>
            <p>
            (Ci-après désigné le « Vendeur »).
            </p>
            <p>
            Le numéro individuel TVA de l'Exploitant est le suivant : FR10882372204.
            </p>
            <p>
            Le Site est hébergé par la société SAS OVH, située 2 rue Kellermann - 59100 Roubaix - France.
            </p>
            <p>
            Le Directeur de la publication du Site est Romain PILOT.
            </p>
            <p>
            Le Service Client du Prestataire peut être contacté par email en vous adressant à <a href="mailto:contact@kease.fr"><noindex>contact@kease.fr</noindex></a>.
            </p>
            </section>
            <section id="article2">
            <h2 className="fw-bold">Article 2 - Champ d'application</h2>
            <p>
            Les présentes conditions générales de services (les « Conditions Générales ») ont pour objet de définir et de régir les rapports contractuels entre le Prestataire et tout professionnel (un « Client ») ayant créé un compte et désirant bénéficier des services proposés sur le Site.
            </p>
            <p>
            La fourniture des services proposés aux Clients sur le Site est conditionnée à l'acceptation préalable sans restriction ni réserve des présentes Conditions Générales.
            </p>
            <p>
            Les Conditions Générales sont mises à la disposition des Clients sur le Site où elles sont directement consultables et peuvent également lui être communiquées sur simple demande par tout moyen.
            </p>
            <p>
            Les Conditions Générales sont applicables nonobstant toute stipulation contraire figurant dans tous documents émanant du Client, et notamment dans ses conditions générales d'achat.
            </p>
            <p>
            Les Conditions Générales sont applicables sous réserve de toute stipulation contraire figurant au bon de commande ou à des conditions particulières le cas échéant conclues entre la Société et le Client concerné.
            </p>
            </section > 
            <section id="article3">
            <h2 className="fw-bold">Article 3 - Description des services</h2>
            <p>
            Le Site a pour objet la fourniture en ligne des services suivants :
            </p>
            <ul>
                <li>Interface de création et de gestion d'organisations et d'engagements ;</li>
                <li>Collecte d'un(e) ou plusieur(e)s Balances, Fichiers des Ecritures Comptables (ci-après « FEC ») ou Grands-Livres ;</li>
                <li>Accès à une plateforme permettant de structurer une démarche d'audit financier avec plusieurs approches proposées et générés automatiquement sur la base (i) des informations contenues dans le ou les balances (ou FEC ou Grands-Livres) importés, (ii) d'une classification comptable standard et modifiable et (iii) de calculs standards.</li>
                <li>L'utilisateur aura alors accès à plusieurs fonctionnalités lui permettant de manipuler l'ensembles des données générées.</li>
                <li>Les données de la société analysée peuvent être comparées aux informations financières d'autres entreprises françaises qui sont récupérées par API à partir de la plateforme Pappers. Ces données sont récupérées par Pappers à partir des bases de données Sirene® de l'INSEE et RNCS CA de l'INPI.</li>
            </ul>
            <p>(ci-après désignés les « Services »).</p>
            <p>
            Les Services présentés sur le Site font chacun l'objet d'un descriptif mentionnant leurs caractéristiques essentielles. Cette description est susceptible de comporter des descriptions, photographies et graphismes qui ne sont communiqués qu'à titre illustratif et peuvent être modifiées/mises à jour sur le Site.
            </p>
            <p>
            Les analyses (tableaux et graphiques) générées automatiquement le sont sur la base d'une classification des comptes et de calculs propres à Kease et sur lesquels des éléments d'explication sont données sur le site. Toutefois, l'utilisateur pourrait avoir une appréciation différente de cette classification et/ou des méthodes de calculs utilisées.
            </p>
            <p>
            Aussi, kease décline toute responsabilité quant à l'exactitude ou à la pertinence que ces analyses pourraient revêtir pour l'utilisateur.
            </p>
            </section > 
            <section id="article4">
            <h2 className="fw-bold">Article 4 - Conditions d'inscription</h2>
            <p>
            Tout Client désirant pleinement bénéficier du Site et des Services devra :
            </p>
            <ul>
                <li>disposer de la pleine capacité et agir à des fins strictement professionnelles ;</li>
                <li>créer son espace personnel sur le Site en renseignant les différents champs du formulaire de création d'un compte (dénomination et adresse électronique) ;</li>
                <li>confirmer son acceptation des Conditions Générales ;</li>
                <li>confirmer son inscription.</li>
            </ul>
            <p>
            L'accès aux Services est possible à partir d'un ordinateur, d'un smartphone ou d'une tablette en se connectant au Site.
            </p>
            <p>
            L'utilisation des Services nécessite une connexion à internet haut débit et à internet mobile le cas échéant.
            </p>
            <p>
            Les Clients font leur affaire personnelle de la mise en place des moyens informatiques et de télécommunication permettant l'accès au Site.
            </p>
            <p>
            Lors de la création d'un compte avec courrier électronique, le Client est invité à choisir un mot de passe, qui constitue la garantie de la confidentialité des informations qui seront contenues dans son compte.
            </p>
            <p>
            Aux fins de validation de l'inscription, le Prestataire adresse au Client un courrier électronique de confirmation à l'adresse email renseignée par ce dernier. Le Client procède alors à l'activation de son compte en cliquant sur le lien hypertexte prévu à cet effet dans le courrier électronique de confirmation.
            </p>
            <p>
            Chaque Client garantit la sincérité et l'exactitude des informations fournies pour les besoins de son inscription, s'engage à lui notifier toute modification ultérieure et garantit que lesdites informations ne portent pas atteinte aux droits de tiers.
            </p>
            <p>
            Le Client peut modifier ces informations, identifiants et mot de passe à partir de son compte sur le Site.
            </p>
            <p>
            Le Client s'engage à ne pas divulguer ou transférer son compte, ses identifiants et mots de passe et est seul responsable de leur utilisation jusqu'à leur désactivation. Il doit informer immédiatement le Prestataire de toute perte ou utilisation non autorisée de son compte.
            </p>
            <p>
            Le Prestataire se réserve le droit de supprimer le compte de tout Client qui aurait fourni des informations erronées.
            </p>
            </section > 
            <section id="article5">
            <h2 className="fw-bold">Article 5 - Commandes</h2>
            <p>
            Tout Client désirant bénéficier des Services en ligne sur le Site devra :
            </p>
            <ul>
                <li>se connecter à son compte Client ;</li>
                <li>sélectionner les Services auxquels il souhaite souscrire ;</li>
                <li>renseigner les différentes informations demandées lors de la souscription à l'une des formules d'abonnement (nom, prénom, adresse, adresse électronique, numéro de téléphone, etc.) ;</li>
                <li>confirmer son acceptation des Conditions Générales ;</li>
                <li>confirmer son acceptation de la souscription aux Services ;</li>
                <li>choisir son mode de paiement (voir explications complémentaires dans l'article 6 - Conditions tarifaires) ;</li>
                <li>effectuer le paiement des Services.</li>
            </ul>

            <p>
            Sauf disposition expresse figurant sur le Site, le Client ne pourra pas modifier sa commande après l'avoir validée, laquelle sera ferme et définitive.
            </p>
            <p>
            Un descriptif des services inclus dans chacun des abonnements proposés est disponible sur le site en consultant la page suivante : Tarifs.
            </p>
            <p>
            Dès réception du paiement des Services compris dans la commande, le Prestataire adressera au Client un courrier électronique de confirmation de sa commande à l'adresse email renseignée par ce dernier.
            </p>
            <p>
            Le courrier électronique de confirmation de la commande résume les caractéristiques essentielles du/des Service(s) commandé(s), le prix total, et tout autre élément pertinent. Cet email indiquera en outre un numéro de suivi de la commande du Client.
            </p>
            <p>
            En passant commande sur le Site, le Client accepte expressément que le Prestataire lui envoie une facture par voie électronique.
            </p>
            <p>
            Pour lutter contre la fraude, le Prestataire ou ses prestataires de paiement ou de livraison peuvent être amenés à demander des justificatifs supplémentaires au Client ou prendre attache avec ce dernier, au moment de l'acceptation et/ou de l'expédition de la commande. En cas de refus non justifié du Client de délivrer les informations et/ou justificatifs demandés, le Prestataire se réserve le droit de ne pas accepter ou d'annuler la commande sans que cela ne puisse faire l'objet d'une quelconque contestation.
            </p>
            <p>
            Le Prestataire se réserve également le droit de ne pas accepter ou d'annuler la commande de tout Client qui aurait fourni des informations erronées, qui ne procèderait pas au paiement des Services, avec lequel existerait un litige relatif au paiement d'une commande antérieure ou qui présenterait un niveau de commande anormalement élevé.
            </p>
            </section > 
            <section id="article6">
            <h2 className="fw-bold">Article 6 - Conditions tarifaires</h2>
            <p>
            Les Services sont fournis aux tarifs en vigueur du Prestataire figurant sur le Site au jour de la commande, exprimé en euros hors taxes.
            </p>
            <p>
            L'accès aux services et leur utilisation nécessitent d'avoir souscrit préalablement à une formule d'abonnement proposée sur le site ou à une formule sur-mesure dont les conditions spécifiques seront envoyées papr le Prestataire au Client le cas échéant.
            </p>
            <p>
            Les services inclus dans chacune des formules d'abonnement sont détaillées en page Tarifs sur le site, sauf pour les tarifs sur-mesure dont les conditions spécifiques seront envoyées par mail par le Prestataire au Client.
            </p>
            <p>
            Le paiement s'effectuera en ligne via une interface de paiement sécurisée.
            </p>
            <p>
            Le tarif est ferme et ne peut être révisé pendant la durée d'exécution des Services, les parties renonçant expressément à se prévaloir des dispositions de l'article 1195 du Code Civil.
            </p>
            <p>
            Le Prestataire se réserve toutefois le droit de modifier ses prix à tout moment pour tous Services qui seraient souscrits postérieurement à cette modification.
            </p>
            <p>
            Des éventuelles réductions de prix, ristournes et remises pourront s'appliquer aux Services dans des conditions prévues sur le Site ou dans tout autre document qui serait communiqué au Client. En cas de tarif promotionnel, le Prestataire s'engage à appliquer ce tarif à toute commande passée durant la période de la promotion.
            </p>
            <p>
            En cas de règlement anticipé par le Client, aucun escompte ne sera pratiqué. En aucun cas les paiements ne peuvent être suspendus ni faire l'objet d'une quelconque compensation sans accord préalable et écrit entre le Prestataire et le Client. Toute suspension, déduction ou compensation opérée unilatéralement par le Client sera traitée comme un défaut de paiement et entraînera toutes les conséquences d'un retard de paiement.
            </p>
            <p>
            Par ailleurs, il est expressément convenu que le Client est valablement mis en demeure de payer par la seule exigibilité de l'obligation, conformément aux dispositions de l'article 1344 du Code civil. En cas de retard de paiement de l'une quelconque des échéances, le Prestataire se réserve notamment le droit, sans qu'aucune indemnité ne soit due au Client, de :
            </p>
            <ul>
                <li>exiger le paiement immédiat de l'ensemble des sommes à devoir au titre des Services, celles-ci devenant immédiatement exigibles quelle que soit leur échéance initialement prévue ;</li>
                <li>refuser toute nouvelle commande ou exiger pour toute nouvelle commande un paiement comptant ou une garantie pour la bonne exécution des engagements ;</li>
                <li>imputer tout paiement partiel en premier lieu sur la partie non privilégiée de la créance puis sur les sommes dont l'exigibilité est la plus ancienne ;</li>
                <li>réduire, suspendre ou annuler l'accès aux Services, 10 jours après mise en demeure restée sans effet, effectuée par le Prestataire auprès du Client ;</li>
                <li>appliquer, sans mise en demeure préalable, à toutes les sommes dues, dès le premier jour de retard et jusqu'à complet paiement, des pénalités de retard calculées au taux visé à l'article L.441-6 du Code de Commerce ; et/ou</li>
                <li>exiger le paiement d'une indemnité forfaitaire de 40 € pour frais de recouvrement, pour chaque facture payée en retard, et le paiement d'une indemnité égale à 10% des sommes lui restant dues, sans préjudice de la réparation de tout dommage effectivement subi.</li>
            </ul>
            <p>
            Toute modification des tarifs résultant d'une augmentation de la taxe sur la valeur ajoutée ou la création de toute nouvelle taxe assise sur le prix des Services sera immédiatement et automatiquement appliquée.
            </p>
            </section > 
            <section id="article7">
            <h2 className="fw-bold">Article 7 - Modes de paiement</h2>
            <p>
            Le Client reconnaît expressément que toute commande effectuée sur le Site est une commande avec obligation de paiement, qui nécessite le paiement d'un prix contre la fourniture du Service commandé.
            </p>
            <p>
            Le Site utilise la solution de paiement en ligne « Stripe ». Le prestataire bancaire « Stripe » est certifié pour la norme « PCI-DSS » qui garantit la confidentialité et la sécurité des données bancaires et assure le bon déroulement et la sécurité de la transaction financière. La Société n'intervient pas dans ces transactions bancaires qui sont effectuées sous la responsabilité du prestataire bancaire « Stripe ».
            </p>
            <p>
            Lorsque le paiement s'effectue par carte bancaire : Le paiement s'effectue directement sur les serveurs bancaires sécurisés de la banque du Prestataire, les coordonnées bancaires du Client ne transitent pas sur le Site. Les coordonnées bancaires communiquées lors du paiement sont protégées par un procédé de cryptage SSL (Secure Socket Layer). Les cartes bancaires émises par des banques domiciliées hors de France doivent obligatoirement être des cartes bancaires internationales. Le paiement via ces cartes bancaires s'effectue au comptant, sans escompte.
            </p>
            <p>
            Le cas échéant, la commande validée par le Client ne sera considérée comme confirmée que lorsque le Prestataire aura pu s'assurer de la validité du règlement.
            </p>
            </section > 
            <section id="article8">
            <h2 className="fw-bold">Article 8 - Obligations du prestataire</h2>
            <p>
            Le Prestataire s'engage à mettre en œuvre toutes les diligences requises pour l'exécution des Services et de ses obligations au titre des présentes Conditions Générales et/ou de toute autre documentation qui serait conclue avec les Clients, dans le respect des dispositions législatives et réglementaires et des droits de tiers.
            </p>
            <p>
            Le Prestataire déclare disposer des compétences, de l'expérience et des moyens nécessaires pour fournir les Services, et assumera la pleine responsabilité tant de l'exécution des Services que de l'organisation du travail de son personnel le cas échéant.
            </p>
            <p>
            Le Prestataire édite les Services disponibles sur le Site et héberge les contenus du Site mis en ligne par les Clients. Le Prestataire agit en tant que prestataire technique et n'effectue aucun contrôle sur la licéité, l'exactitude, la qualité ou la sincérité des contenus mis en ligne par les Clients sous leur responsabilité.
            </p>
            <p>
            En conséquence, les Clients reconnaissent au Prestataire la qualité d'hébergeur du Site au sens de l'article 6 de la loi n°2004-575 du 21 juin 2004 pour la confiance dans l'économie numérique.
            </p>
            <p>
            Toutefois, le Prestataire s'engage à retirer promptement tout contenu manifestement illicite qui serait porté à sa connaissance, notamment lorsque l'existence d'un tel contenu lui aura été notifiée par un Client dans les conditions prévues par la réglementation applicable.
            </p>
            <p>
            Par ailleurs, le Prestataire s'efforce d'assurer l'accès et le bon fonctionnement du Site vingt-quatre heures sur vingt-quatre, sept jours sur sept.
            </p>
            <p>
            Le Prestataire ne peut cependant exclure que l'accès et le fonctionnement du Site soient interrompus notamment en cas de force majeure, de dysfonctionnements des équipements ou du réseau internet des Clients, de défaillance des opérateurs de télécommunication, d'interruption de fourniture d'électricité, d'utilisation anormale, illicite ou frauduleuse du Site par un Client ou un tiers, de décision des autorités compétentes, ou pour tout autre motif.
            </p>
            <p>
            Le Prestataire se réserve également le droit d'apporter au Site et aux Services toutes les modifications et améliorations de son choix liées à l'évolution technique ou au bon fonctionnement.
            </p>
            <p>
            Les interruptions générales et temporaires du Site et des Services seront, dans la mesure du possible, notifiées via le Site avant qu'elles n'interviennent, sauf lorsque ces interruptions ont un caractère d'urgence.
            </p>
            </section > 
            <section id="article9">
            <h2 className="fw-bold">Article 9 - Obligations du client</h2>
            <p>
            Chaque Client s'engage à accéder et utiliser le Site et les Services de manière loyale et conformément aux lois en vigueur et aux présentes Conditions Générales.
            </p>
            <p>
            Les données et informations communiquées ou mises en ligne par les Clients devront être exactes, sincères et loyales et seront communiquées sous leur seule responsabilité.
            </p>
            <p>
            Plus généralement, chaque Client s'engage à :
            </p>
            <ul>
                <li>s'assurer du respect, en toutes circonstances, des obligations légales, sociales, administratives et fiscales applicables à son statut de professionnel ;</li>
                <li>ne pas modifier, en cours d'exécution des Services, leur nature ou leurs modalités de fourniture, sauf accord préalable et écrit du Prestataire ;</li>
                <li>verser le prix des Services dans les conditions prévues aux présentes ;</li>
                <li>ne pas diffuser de contenu illicite ou ayant pour effet de diminuer, de désorganiser, de ralentir ou d'interrompre la circulation normale des données sur le Site ;</li>
                <li>signaler immédiatement au Prestataire toute difficulté, réserve ou tout litige survenu au cours de l'exécution des Services ou toute utilisation anormale, abusive ou frauduleuse du Site dont il aurait connaissance.</li>
            </ul>
            <p>
            Dans l'hypothèse où un Client serait responsable d'une infraction à la législation en vigueur ou d'une atteinte aux droits des tiers, le Prestataire se réserve le droit de fournir, à la demande de toute autorité légitime (juridiction, autorité administrative, services de police), tous renseignements permettant ou facilitant l'identification du Client contrevenant.
            </p>
            </section > 
            <section id="article10">
            <h2 className="fw-bold">Article 10 - Réclamations</h2>
            <p>
            En cas d'inexécution ou d'exécution défectueuse des Services, le Client devra notifier le Prestataire et formuler ses griefs et réserves dans un délai de trente (30) jours calendaires suivant la date où il en a connaissance, afin de permettre aux parties de faire leurs meilleurs efforts en vue de parvenir à un règlement amiable de la situation.
            </p>
            </section > 
            <section id="article11">
            <h2 className="fw-bold">Article 11 - Responsabilité du prestataire</h2>
            <p>
            Le Prestataire est tenu à une obligation de moyens au titre de la fourniture des Services.
            </p>
            <p>
            Chaque Client déclare être informé des contraintes et des limites des réseaux Internet et ne pourra en aucun cas rechercher la responsabilité du Prestataire au titre de dysfonctionnements dans l'accès aux Services, des vitesses d'ouverture et de consultation des pages des Services, de l'inaccessibilité temporaire ou définitive des Services ou de l'utilisation frauduleuse par des Clients ou des tiers du Site.
            </p>
            <p>
            La responsabilité du Prestataire ne saurait davantage être engagée :
            </p>
            <ul>
                <li>en cas de manquement à une quelconque obligation résultant d'un cas fortuit ou d'un évènement de force majeure au sens de l'article 1218 du Code Civil, y compris, mais sans y être limités, des évènements imprévisibles tels que grèves, arrêts de travail, troubles sociaux, fermetures d'usines, inondations, incendies, défaut de production ou de transport non-consécutif à son fait personnel, rupture d'approvisionnement, guerres, émeutes, insurrections et plus généralement toute circonstance ou événement empêchant la Société d'exécuter convenablement ses obligations ;</li>
                <li>dans le cas où les informations, données, instructions, directives, matériels ou supports communiqués par le Client sont erronés ou incomplets, et plus généralement dans le cas où l'inexécution ou l'exécution défectueuse des Services résulte en tout ou partie du comportement, d'un manquement ou d'une carence du Client ;</li>
                <li>dans le cas où certains services ou fonctionnalités ne seraient pas accessibles sur le Site en raison d'une désactivation par un Client des cookies via l'interface du logiciel de navigation ;</li>
                <li>dans le cas où les fonctionnalités du Site s'avèreraient incompatibles avec certains équipements et/ou fonctionnalités du matériel informatique d'un Client.</li>
            </ul>
            <p>
            Chaque Client est par ailleurs responsable des contenus et informations importés, stockés et/ou publiés sur le Site et s'engage à n'employer aucune mesure technique de nature à permettre le contournement des mesures techniques de protection mises en place par le Prestataire en vue d'éviter toute utilisation frauduleuse du Site et des Services.
            </p>
            <p>
            Chaque Client prend également sous sa seule responsabilité l'ensemble des mesures de nature à assurer l'intégrité et la sauvegarde de l'ensemble de ses données, fichiers et documents et renonce à rechercher la responsabilité du Prestataire en cas de dommages survenus aux données, fichiers ou à tout autre document qu'il aurait pu confier au Prestataire dans le cadre de l'utilisation du Site et/ou des Services.
            </p>
            <p>
            Plus généralement, chaque Client s'engage par ailleurs à garantir le Prestataire de toute réclamation, revendication, ou opposition et plus généralement de toute procédure qui serait formée à son encontre à raison de l'utilisation qui aurait été faite par le Client du Site ou des Services.
            </p>
            <p>
            En tout état de cause, le Prestataire n'encourt aucune responsabilité au titre de tous dommages ou préjudices indirects ou immatériels tels que préjudice financier, perte de chance, perte de profit, perte de contrat, perte de commande, perte de clientèle, perte d'exploitation, préjudice ou trouble commercial ou préjudice d'image, qui pourraient résulter de la fourniture défectueuse ou de l'absence de fourniture des Services.
            </p>
            <p>
            Le Prestataire ne peut garantir l'intégrité des informations présentées sur le site et lors de l'utilisation de la plateforme par l'utilisateur, et ne peut non plus garantir l'absence de modification ou d'altération de ces données par un tiers (notamment dans le cas de virus, d'intrusion ou de piratage). Le Prestataire ne peut pas non plus garantir l'exhaustivité, l'exactitude, la fiabilité et la pertinence des informations présentées sur le Site.
            </p>
            <p>
            Kease, ne pourra être tenue que de la réparation des conséquences pécuniaires des dommages directs et prévisibles du fait de l'utilisation des Services. En conséquence, Kease ne pourra en aucun cas être tenue responsable de pertes ou dommages indirects ou imprévisibles de l'Utilisateur ou des tiers. Est assimilée à un préjudice indirect et en conséquence, n'ouvre pas droit à réparation, toute action dirigée contre l'Utilisateur par un tiers.
            </p>
            <p>
            Dans tous les cas, si la responsabilité de Kease devait néanmoins être retenue, celle-ci ne pourrait excéder un montant égal au prix hors taxes perçu auprès du Client au titre de la fourniture des Services au cours des douze (12) derniers mois.
            </p>
            <p>
            Conformément aux dispositions de l'article 2254 du Code Civil, toute action judiciaire d'un Client à l'égard du Prestataire est atteinte par la prescription à l'expiration d'une durée d'un (1) an suivant la date à laquelle le Client concerné a eu connaissance ou est présumé avoir eu connaissance du fait dommageable.
            </p>
            </section > 
            <section id="article12">
            <h2 className="fw-bold">Article 12 - Systèmes d'enregistrement</h2>
            <p>
            Les registres informatisés, conservés dans les systèmes informatiques du Prestataire et de ses partenaires dans des conditions raisonnables de sécurité, seront considérés comme les preuves des communications et actions des Clients et du Prestataire. L'archivage de ces éléments est effectué sur un support fiable et durable de manière à correspondre à une copie fidèle et durable au sens de la réglementation applicable.
            </p>
            <p>
            Chaque Client reconnaît la valeur de preuve des systèmes d'enregistrement automatisés du Site et déclare renoncer à les contester en cas de litige.
            </p>
            </section > 
            <section id="article13">
            <h2 className="fw-bold">Article 13 - Données personnelles</h2>
            <p>
            Pour davantage d'informations concernant l'utilisation de données à caractère personnel par le Prestataire, veuillez lire attentivement la Charte sur le respect de la vie privée (la «Charte»). Vous pouvez à tout moment consulter cette Charte sur le Site.
            </p>
            </section > 
            <section id="article14">
            <h2 className="fw-bold">Article 14 - Liens hypertextes</h2>
            <p>
            Les liens hypertextes disponibles sur le Site peuvent renvoyer vers des sites tiers ou partenaires. Ils sont fournis uniquement pour la convenance du Client, afin de faciliter l'utilisation des ressources disponibles sur l'Internet. Si le Client utilise ces liens, il quittera le Site et acceptera alors d'utiliser les sites tiers à ses risques et périls ou le cas échéant conformément aux conditions qui les régissent.
            </p>
            <p>
            En tout état de cause, l'existence d'un lien hypertexte vers le Site en provenance d'un site tiers ou sur le Site vers un site tiers ou de partenaire ne saurait engager la responsabilité du Prestataire à quelque titre que ce soit et notamment quant à la disponibilité, aux contenus et aux produits et/ou services disponibles sur ou à partir de ce site tiers ou partenaire.
            </p>
            <p>
            Le Client n'est pas autorisé à créer sur un site tiers un ou plusieurs liens hypertextes reliant à la page d'accueil du Site ou vers sa page profil, sauf autorisation préalable et écrite du Prestataire.
            </p>
            </section > 
            <section id="article15">
            <h2 className="fw-bold">Article 15 - Propriété intellectuelle</h2>
            <p>
            Le Prestataire est seul titulaire de tous les contenus présents sur le Site, notamment et sans limitation, tous textes, fichiers, images animées ou non, photographies, vidéos, logos, dessins, modèles, logiciels, marques, identité visuelle, base de données, structure du Site et tous autres éléments de propriété intellectuelle et autres données ou informations qui sont protégés par les lois et règlements français et internationaux relatifs notamment à la propriété intellectuelle.
            </p>
            <p>
            En conséquence, aucun des contenus du Site ne pourra en tout ou partie être modifié, reproduit, copié, dupliqué, vendu, revendu, transmis, publié, communiqué, distribué, diffusé, représenté, stocké, utilisé, loué ou exploité de toute autre manière, à titre gratuit ou onéreux, par un Client ou par un tiers, quel que soient les moyens et/ou les supports utilisés, qu'ils soient connus ou inconnus à ce jour, sans l'autorisation préalable et écrite du Prestataire, et le Client est seul responsable de toute utilisation et/ou exploitation non autorisée.
            </p>
            <p>
            En outre, toute extraction, intégration, compilation, ou utilisation à des fins commerciales d'informations contenues dans les bases de données accessibles sur le Site, ainsi que toute utilisation de logiciels, robots, systèmes d'exploration de données et autres outils de collecte de données est strictement interdite aux Clients.
            </p>
            <p>
            Le Prestataire concède toutefois aux Clients, sous réserve du respect par ces derniers des présentes Conditions Générales, un droit non exclusif et non cessible d'accéder aux contenus présents sur le Site dont il détient la pleine propriété, de les télécharger et de les imprimer dans le cadre d'une utilisation personnelle et non commerciale.
            </p>
            <p>
            De manière réciproque, chaque Client autorise expressément le Prestataire à reproduire, diffuser, héberger, stocker, reproduire, communiquer, publier, modifier, adapter, traduire et afficher tout ou partie de son contenu sur le Site, par tous moyens ou procédés, à des fins d'exploitation, d'amélioration, de vérification, de promotion, de marketing, de publicité du Site ou dans le cadre de la mise en place de partenariats. Cette licence non-exclusive, transférable et sous-licenciable est valable pour le monde entier, sans redevance, pour la durée de l'inscription du Client ou jusqu'à la suppression du contenu de son compte.
            </p>
            <p>
            Dans le cadre de son module de comparatif avec d'autres sociétés françaises, Kease utilise les données fournies par le prestataire Pappers. Dans le cadre de ces prestations, Kease s'engage à respecter les Conditions Générales d'Utilisation mises en place par Pappers (https://www.pappers.fr/mentions-legales). L'utilisateur s'engage également à respecter ces mêmes termes et conditions.
            </p>
            </section > 
            <section id="article16">
            <h2 className="fw-bold">Article 16 - Durée - Suspension - Résiliation</h2>
            <p>
            En cas de vente ponctuelle ou en application de conditions particulières, les présentes Conditions Générales sont conclues pour la durée de fourniture des Services, telle que mentionnée le cas échéant dans des conditions particulières ou dans le bon de commande.
            </p>
            <p>
            Dans le cas d'une vente par abonnement, le Client s'engage pour une durée déterminée à un prix déterminé. Les informations relatives au prix et à la durée de l'abonnement sont spécifiées lors de la souscription à l'abonnement. Au cours de la souscription à l'abonnement, le Client s'engage à payer le montant correspondant à l'utilisation des services sur la durée spécifiée dans l'abonnement. Sauf résiliation dans les conditions explicitées ci-après, l'abonnement sera reconduit automatiquement pour une période identique à celle durant laquelle le Client est déjà engagé. La reconduction sera applicable au prix du jour durant lequel la reconduction intervient. Un courrier électronique sera envoyé au Client à l'adresse indiquée lors de l'inscription, afin de prévenir ce dernier de l'arrivée à échéance de son abonnement, ainsi que de la reconduction automatique de l'abonnement en cas d'absence de résiliation de la part du Client. Cette notification interviendra au plus tard 5 jours avant l'arrivée du terme de l'abonnement. Tout mois commencé devra être payé par le Client.
            </p>
            <p>
            Le Prestataire se réserve le droit de suspendre l'accès d'un Client au Site et aux Services de façon permanente ou de façon temporaire, en cas de manquement par ledit Client à ses obligations résultant des présentes Conditions Générales.
            </p>
            <p>
            Le Client pourra résilier à tout moment et sans préavis le Contrat de fourniture de Services. Par ailleurs, le Prestataire ou le Client pourra résilier les Conditions Générales de plein droit de manière anticipée par l'envoi d'une notification écrite :
            </p>
            <ul>
                <li>en cas de survenance d'un cas de force majeure tel que visé à l'article 11 ci-avant ;</li>
                <li>après avoir notifié l'autre partie en cas de manquement grave de celle-ci à ses obligations ou au titre des lois et règlements applicables, auquel il n'aurait pas été remédié dans un délai de quinze (15) jours (lorsqu'il peut être remédié à ce manquement) suivant notification écrite indiquant la nature du manquement et la nécessité d'y remédier.</li>
            </ul>
            </section > 
            <section id="article17">
            <h2 className="fw-bold">Article 17 - Confidentialité</h2>
            <p>
            Pendant la durée des présentes, chaque partie pourra prendre connaissance ou recevoir des informations, documents et/ou données confidentiels à propos de l'autre partie. De ce fait, chaque partie s'engage, tant en son nom qu'au nom de ses préposés pour lesquels elle se porte fort, à préserver la stricte confidentialité de toutes les informations, documents et/ou données confidentiels de toute nature en rapport avec les résultats, l'activité ou la clientèle de l'autre partie ou toute information reçue ou obtenue d'une partie dans le cadre des relations contractuelles établies.
            </p>
            <p>
            Le présent engagement de confidentialité des parties est valable, tant pour la durée des présentes que pendant une durée de deux (2) ans suivant leur expiration ou résiliation.
            </p>
            </section > 
            <section id="article18">
            <h2 className="fw-bold">Article 18 - Notifications</h2>
            <p>
            Toute notification ou convocation écrite requise ou permise en vertu des stipulations des présentes sera valablement effectuée si elle est adressée par lettre remise en main propre ou par porteur contre reçu de livraison, par courrier recommandé avec avis de réception, ou par courrier électronique (sauf en cas de résiliation des présentes), adressé aux coordonnées de la partie concernée, chaque partie élisant domicile en son siège social.
            </p>
            <p>
            Tout changement de coordonnées d'une partie pour les besoins des présentes devra être notifié à l'autre partie selon les modalités prévues ci-dessus.
            </p>
            <p>
            Les notifications adressées en mains propres ou par porteur seront présumées avoir été faites à leur date de remise au destinataire, telle qu'attestée par le reçu de livraison. Les notifications faites par courrier recommandé avec avis de réception seront présumées avoir été faites à la date de leur première présentation à l'adresse du destinataire. Les notifications faites par courrier électronique seront présumées avoir été faites à la date d'envoi du courrier électronique.
            </p>
            </section > 
            <section id="article19">
            <h2 className="fw-bold">Article 19 - Autonomie et absence de renonciation</h2>
            <p>
            Si l'une quelconque des stipulations des présentes Conditions générales était déclarée nulle ou inapplicable pour quelque cause que ce soit en application d'une loi, d'un règlement ou à la suite d'une décision de justice devenue définitive, elle serait réputée non écrite et les autres stipulations resteraient en vigueur.
            </p>
            <p>
            Le fait pour le Prestataire de ne pas se prévaloir à titre temporaire ou permanent d'une ou de plusieurs stipulations des Conditions Générales n'emportera en aucun cas renonciation.
            </p>
            </section > 
            <section id="article20">
            <h2 className="fw-bold">Article 20 - Modification</h2>
            <p>
            Le Prestataire se réserve le droit de modifier à tout moment et sans préavis le contenu ou la localisation du Site, les Services et les présentes Conditions Générales.
            </p>
            <p>
            Toute utilisation du Site ou des Services consécutive à une modification apportée aux Conditions Générales emportera acceptation par chaque Client desdites modifications. La version la plus récente et en vigueur des Conditions Générales sera toujours disponible à l'adresse suivante : https://kease.fr/conditions-generales-de-vente
            </p>
            <p>
            Lorsque les modifications apportées aux Conditions Générales seront considérées par le Prestataire comme substantielles, celles-ci seront portées à la connaissance des Clients par courrier électronique.
            </p>
            </section > 
            <section id="article21">
            <h2 className="fw-bold">Article 21 - Litiges</h2>
            <p>
            Les litiges qui pourront survenir dans le cadre des relations contractuelles établies entre le Client et le Prestataire devront être résolus, dans la mesure du possible, de manière amiable.
            </p>
            <p>
            A défaut de règlement amiable dans un délai d'un mois à compter de la saisine de l'une des parties, tous les litiges auxquels les Conditions Générales pourraient donner lieu, concernant tant leur validité, interprétation, exécution, résiliation, leurs conséquences et leurs suites, seront soumis au tribunal de commerce de Nanterre.
            </p>
            </section > 
            <section id="article22">
            <h2 className="fw-bold">Article 22 - Droit applicable et langue du contrat</h2>
            <p>
            Les présentes Conditions Générales et les opérations qui en découlent sont régies et soumises au droit français. Elles sont rédigées en langue française. En cas de traduction en une ou plusieurs langues étrangères, seul le texte français fera foi en cas de litige.
            </p>
            </section > 
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ConditionsGénérales