import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from "react"
import { Form, Button, Card, Col, Dropdown, CardGroup, Container, Row } from "react-bootstrap"
import img1 from "../../../assets/table-working.jpg"


const Adaptable: React.FC = () => {
    


    return (
        <>
        <h1>Logiciel d'audit financier adaptable</h1>
            <p>Notre logiciel d'audit financier est conçu pour s'adapter à vos besoins uniques.
                Avec une variété de fonctionnalités personnalisables, vous pouvez être sûr que notre outil sera en mesure de vous aider à réaliser vos audits de manière simple et adapté à vos clients.</p>

                <Card className="card border-0 flex-row">
                    <img src={img1} className="card-img-top imgkease" alt="Table de travail"/>
                    <Card.Body>
                        <Card.Title> <h2>Personnalisation de la démarche d'audit</h2></Card.Title>
                        <Card.Text>
                        <p>
                            Créez une démarche d'audit personnalisés pour chaque client.
                            Ajustez la démarche et les tests en fonction de vos besoins.
                        </p>
                        <p>
                            La mise en place d'une démarche d'audit efficace est cruciale pour garantir la performance financière de toute entreprise. Cependant, chaque entreprise a des besoins uniques en matière de gestion financière, il est donc important de disposer d'une démarche d'audit adaptée à ses besoins. C'est là que KEASE, le logiciel d'audit financier, entre en jeu.
                        </p>
                        <p>
                            KEASE propose une démarche d'audit sur mesure adaptée à chaque entreprise. Le logiciel est conçu pour s'adapter à la structure et aux processus financiers uniques de chaque entreprise, ce qui permet de mettre en place une démarche d'audit efficace qui répond parfaitement à ses besoins. Les entreprises peuvent ainsi améliorer leur performance financière grâce à une démarche d'audit adaptée à leur situation.
                        </p>
                        <p>
                            L'objectif de KEASE est de fournir une démarche d'audit sur mesure qui soit facile à utiliser et à adapter. Le logiciel est facile à utiliser, ce qui permet aux entreprises de commencer à utiliser le logiciel rapidement sans avoir à consacrer beaucoup de temps à la formation. Les entreprises peuvent ainsi se concentrer sur l'amélioration de leur performance financière plutôt que sur la gestion du logiciel.
                        </p>
                        <p>
                            En utilisant KEASE, les entreprises peuvent configurer leurs tableaux de bord, leurs indicateurs clés et leurs rapports pour mieux suivre et comprendre leur performance financière. Les entreprises peuvent également configurer les workflows et les processus pour mieux gérer leurs finances et améliorer leur efficacité.
                        </p>
                        <p>
                            Enfin, KEASE propose une démarche d'audit sur mesure qui est complètement sécurisée et conforme aux réglementations financières. Les entreprises peuvent ainsi être assurées que leur démarche d'audit est conforme aux réglementations financières en vigueur et que leurs informations financières sont sécurisées.
                        </p>
                        <p>
                            En résumé, KEASE propose une démarche d'audit sur mesure qui est adaptée à chaque entreprise. Le logiciel est conçu pour être facile à utiliser, adaptable et sécurisé, ce qui permet aux entreprises de mettre en place une démarche d'audit efficace qui répond parfaitement à leurs besoins en matière de gestion financière. KEASE est la solution idéale pour les entreprises soucieuses d'améliorer leur performance financière tout en bénéficiant d'une démarche d'audit sur mesure adaptée à leur situation.
                        </p>
                        </Card.Text>
                    </Card.Body>
                </Card>
                <br/>
                <Card className="card border-0 flex-row">
                    
                    <Card.Body>
                        <Card.Title> <h2>Adaptable à chaque société</h2></Card.Title>
                        <Card.Text>
                        <p>
                            Suivez vos dépenses en temps réel et gagnez en transparence grâce à notre outil de suivi des dépenses. Identifiez rapidement les dépenses excessives et prenez des mesures pour les réduire.
                        </p>
                        <p>
                            L'adaptabilité et la personnalisation sont des éléments clés pour tout logiciel d'audit financier qui souhaite répondre aux besoins variés et uniques des entreprises. KEASE, le logiciel d'audit financier basé sur le cloud, comprend parfaitement ces besoins et propose une solution adaptable et personnalisable pour les entreprises.
                        </p>
                        <p>
                            KEASE a été conçu pour s'adapter à chaque entreprise, peu importe sa taille ou son secteur d'activité. Le logiciel est entièrement personnalisable pour s'adapter à la structure et aux processus financiers uniques de chaque entreprise. Cela permet aux entreprises de travailler de manière plus efficace et d'améliorer leur performance financière grâce à une solution qui répond parfaitement à leurs besoins.
                        </p>
                        <p>
                            En utilisant KEASE, les entreprises peuvent personnaliser les tableaux de bord, les indicateurs clés et les rapports pour mieux suivre et comprendre leur performance financière. Cela permet de prendre des décisions plus informées et de mieux gérer leurs finances. Les entreprises peuvent également configurer les workflows et les processus pour mieux gérer leurs finances et améliorer leur efficacité.
                        </p>
                        <p>
                            En outre, KEASE est facile à utiliser et à adapter, ce qui permet aux entreprises de commencer à utiliser le logiciel rapidement sans avoir à consacrer beaucoup de temps à la formation ou à la configuration. Les entreprises peuvent ainsi se concentrer sur l'amélioration de leur performance financière plutôt que sur la gestion du logiciel.
                        </p>
                        <p>
                            En résumé, KEASE est un logiciel d'audit financier adaptable et personnalisable qui permet aux entreprises de travailler de manière plus efficace et de mieux gérer leur performance financière. Les entreprises peuvent bénéficier d'une solution sur mesure qui répond parfaitement à leurs besoins en matière de gestion financière. KEASE est la solution idéale pour les entreprises soucieuses d'améliorer leur performance financière tout en bénéficiant d'une solution adaptable et personnalisable.
                        </p>
                        </Card.Text>
                    </Card.Body>
                    <img src={img1} className="card-img-top imgkease" alt="Table de travail"/>
                </Card>
                <br/>
        </>
    )
}

export default Adaptable