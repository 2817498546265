import { useRef, useState } from "react"
import { Form, Button, Card, Alert, Col, CardGroup } from "react-bootstrap"
import { dbCollection } from "../../utils/firebaseconfig";
import { ref, push, child, set } from "firebase/database";
import { useNavigate } from "react-router-dom";

const Contact: React.FC = () => {

    const NameRef = useRef<HTMLInputElement>(null);
    const emailRef = useRef<HTMLInputElement>(null);
    const MessageRef = useRef<HTMLInputElement>(null);
    const phoneRef = useRef<HTMLInputElement>(null);
    const navigate = useNavigate();
    
    const [PersonnalDataCheckedRef, setSwitchState] = useState(false);
    const handleChange=()=>{
        setSwitchState(!PersonnalDataCheckedRef)
     }

    const [error, setError] = useState("")
    const [valid, setValid] = useState("")
    const [show, setShow] = useState(false)
    const [isvalid, setIsValid] = useState(false)

    const ContactRef= useRef(null)

    const [validated, setValidated] = useState(false);

    const CriteriaTest = (event:any) => {
      const form = event.currentTarget

      if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
      }
      setValidated(true);
    };

    async function handleSubmit(event:any) {
        event.preventDefault()
        if(isvalid){
            setIsValid(!isvalid)
        }
        
        if(PersonnalDataCheckedRef===false) {
            setShow(!show)
            setError("Vous n'avez pas autorisé Kease à accéder à vos données personnelles.")
        } else if (PersonnalDataCheckedRef===true) {
            CriteriaTest(event)
            if(validated===false) {
                setShow(!show)
                setError("Vous n'avez pas saisie l'ensemble des informations")
            } else if (validated===true) {
                try {
                    setError("")

                    const newContactKey = push(child(ref(dbCollection), 'contacts/')).key

                    const engagementData = {
                        uid: newContactKey,
                        email: emailRef.current.value,
                        name: NameRef.current.value,
                        phone : phoneRef.current.value,
                        message : MessageRef.current.value,
                        personnal_data_accepted : PersonnalDataCheckedRef,
                    };

                    await set(ref(dbCollection, 'contacts/' + newContactKey), engagementData)
                    if(show){
                        setShow(!show)
                    }
                    setIsValid(!isvalid)
                    setValid("Votre message a bien été envoyé")
                    ContactRef.current.reset()
                } catch {
                    setShow(!show)
                    setError("Impossible d'envoyer votre message - veuillez réessayer ultérieurement ou nous appeler")
                }
            }
        }
      }

    function handleNav(direction: string) {
        navigate('/'+direction)
    }

    return (
    <>
        <br/>

        <CardGroup>
        
        <Card className="kease-font border-0 rounded-0 pb-3">
            <br/>
            <Card.Title>
                <h2 className="text-center">Avançons ensemble</h2>
            </Card.Title>
            <Card.Body>
                <Form >
                    <Form.Group className="mb-5 row">
                        <p className="align-middle">
                            Apporter un véritable confort d'utilisation, rapidité, accès, fiabilité et sécurité.
                            Kease est la solution intuitive et mobile pour faciliter la prise en main de vos utilisateurs
                            et pour vous permettre d'avoir un suivi en temps réel sur l'avancée de vos missions.
                        </p>
                        <ul className="tick-marck-ul mb-3">
                            <li> Pilotez votre audit</li>
                            <li> Augmentez votre productivité</li>
                            <li> Suivez votre avancement en temps réel</li>
                            <li> Maîtrisez vos coûts</li>
                            <li> Comptez sur un outil fiable et sécurisé</li>
                        </ul>
                    </Form.Group>
                    <br/>
                    <Form.Group className="mb-5 row form-inline">
                    <p className="col-6">Une présentation de kease vous intéresse, suivez-le lien :</p>
                    <a className="col-6 h5 text-white" onClick={(e:any) => {handleNav("about-us")}}>Qui sommes nous?</a>
                    </Form.Group>
                    
                </Form>
            </Card.Body>
        </Card>

        <Card className="border mx-auto border-kease border-5 pb-3">
            <br/>
            <Card.Title>
                <h2 className="text-center">Je veux être contacté</h2>
            </Card.Title>
            <Card.Body>
                <Alert className="container mb-3" show={show} variant="danger">
                    {error}
                </Alert>
                <Alert className="container mb-3" show={isvalid} variant="success">
                    {valid}
                </Alert>
                <Form ref={ContactRef} noValidate validated={validated} onSubmit={handleSubmit}>
                    <Form.Group className="mb-3 row">
                        <Col xs={3}>
                            <Form.Label>Nom</Form.Label>
                        </Col>
                        <Col xs={9}>
                            <Form.Control type="string" ref={NameRef} placeholder="Prénom et Nom" required />
                        </Col>
                    </Form.Group>
                    <Form.Group className="mb-3 row">
                        <Col xs={3}>
                            <Form.Label>Email</Form.Label>
                        </Col>
                        <Col xs={9}>
                            <Form.Control type="email" ref={emailRef} placeholder="email" required />
                        </Col>
                    </Form.Group>
                    <Form.Group className="mb-3 row">
                        <Col xs={3}>
                            <Form.Label>Téléphone</Form.Label>
                        </Col>
                        <Col xs={9}>
                            <Form.Control
                                type="telephone"
                                pattern="[0-9]{2}[0-9]{2}[0-9]{2}[0-9]{2}[0-9]{2}"
                                ref={phoneRef}
                                placeholder="0700000000"
                                required
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group className="mb-3 row">
                        <Col xs={3}>
                            <Form.Label>Message</Form.Label>
                        </Col>
                        <Col xs={9}>
                            <Form.Control 
                                as="textarea"
                                placeholder="Votre message"
                                ref={MessageRef as any} 
                                rows={3}
                                required
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group className="mb-3 row">
                        <Col xs={3}>
                        <Form.Switch
                            type="checkbox"
                            defaultChecked={PersonnalDataCheckedRef}
                            onChange={handleChange}
                            >
                        </Form.Switch>
                        </Col>
                        <Col xs={9}>
                            <Form.Text >
                                En cochant, vous acceptez que Kease, utilise vos données personnelles à des fins de prospection commerciale et statistiques.
                                <br/>
                                Plus d’infos en <a href="/donnees-personnelles">cliquant ici</a>.
                            </Form.Text>
                        </Col>
                    </Form.Group>
                    <br/>
                    <Button className="d-grid gap-2 col-6 mx-auto mb-3 btn-kease w-25" type="submit">
                        Envoyer
                    </Button>
                </Form>
            </Card.Body>
        </Card>
        </CardGroup>  
        <br/> 
    </>
    )
}

export default Contact