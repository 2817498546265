import { Card, Col, Row } from "react-bootstrap";
import { auth, dbCollection } from "../../utils/firebaseconfig";
import { ref, onValue } from "firebase/database";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import CircularProgressBar from "../utils/CircularProgressBar";

const Dashboard: React.FC = () => {

  const UserAccessEngagementsRef = ref(dbCollection, 'users/' + (auth.currentUser?.uid || '') + '/engagements-access/');
  const [cards, setCards] = useState<any[]>([]);
  const [hovered, setHovered] = useState<boolean[]>([]);

  useEffect(() => {

      onValue(UserAccessEngagementsRef, (snapshot) => {
        const newCards: any[] = [];
        snapshot.forEach((childSnapshot) => {
          const engagementRef = ref(dbCollection, 'engagements/' + childSnapshot.key);
          onValue(engagementRef, (Snapshot) => {
            const avanty = Snapshot.child('Progress').val();
            const nature = Snapshot.child('engagementNature').val();
            const closing = Snapshot.child('engagementDateCloture').val();

            newCards.push({
              key: childSnapshot.key,
              name: childSnapshot.val(),
              progress: avanty,
              nature: nature,
              closing: closing,
            });
          });
        });

        setCards(newCards);
      });


  }, [UserAccessEngagementsRef]);

  const navigate = useNavigate();

  function engagementDetailsGo(valueRef: string) {
    navigate('/engagement/' + valueRef + '/dashboard/', { replace: true });
  }

  const handleMouseEnter = (index: number) => {
    setHovered((prevHovered) => {
      const newHovered = [...prevHovered];
      newHovered[index] = true;
      return newHovered;
    });
  };

  const handleMouseLeave = (index: number) => {
    setHovered((prevHovered) => {
      const newHovered = [...prevHovered];
      newHovered[index] = false;
      return newHovered;
    });
  };

  return (
    <>
      <Card className="border-0 mx-auto">
        <Card.Body>
          <h2 className="text-center mb-2">DashBoard</h2>
          <br />
        </Card.Body>
      </Card>

        <Row xs={1} md={2} lg={2} xl={3} className="g-4 mx-auto  d-flex justify-content-center">
          {cards.map((card, index) => (
            <Card
              key={card.key}
              className="border-0 m-5 pt-3 roudedcard features"
              onClick={() => engagementDetailsGo(card.key)}
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={() => handleMouseLeave(index)}
              
            >
              <Row>
                <Col className="col-5 percentkease align-middle m-auto">
                  <CircularProgressBar progress={card.progress}
                  hovered={hovered[index]} // Utilisation de hovered spécifique à chaque carte
                  />
                </Col>
                <Col className="col-7">
                  <Card.Body>
                    <Card.Title className="text-nowrap">{card.name.name}</Card.Title>
                    <Card.Text className="text-nowrap m-0">
                      Nature: {card.nature}
                    </Card.Text>
                    <Card.Text className="text-nowrap m-0">
                      Clôture: {card.closing}
                    </Card.Text>
                  </Card.Body>
                </Col>
              </Row>
              <br />
            </Card>
          ))}
        </Row>
      
    </>
  );
}

export default Dashboard;
