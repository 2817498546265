import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from "react"
import { Form, Button, Card, Col, Dropdown, CardGroup, Container, Row } from "react-bootstrap"
import img1 from "../../../assets/table-working.jpg"


const Securite: React.FC = () => {
    


    return (
        <>
          <h1>Sécurité des données et informations sensibles</h1>
          <p>La sécurité de vos données et informations sensibles est notre priorité absolue. Nous mettons en œuvre les meilleures pratiques en matière de sécurité, de cryptage des données, d'hébergement en France et de gestion des droits d'accès pour garantir la protection de vos informations.</p>
    
          <Card className="card border-0 flex-row">
                <img src={img1} className="card-img-top imgkease" alt="Table de travail"/>
                <Card.Body>
                    <Card.Title> <h2>Cryptage des données de haut niveau</h2></Card.Title>
                    <Card.Text>
                      <p>
                        Nous garantissons la sécurité de vos données en les cryptant à l'aide d'algorithmes de chiffrement de haut niveau. Vous pouvez être sûr que vos informations resteront privées et protégées.
                      </p>
                      <p>
                        Le cryptage des données est un élément crucial pour garantir la sécurité des informations de nos clients. Chez Kease, nous comprenons l'importance de la sécurité des données pour nos clients et nous avons mis en place des mesures de cryptage pour assurer la protection des informations les plus sensibles.
                      </p>
                      <p>
                        Notre logiciel d'audit financier, Kease, utilise des algorithmes de cryptage de pointe pour garantir la sécurité de toutes les données de nos clients. Le cryptage des données consiste à chiffrer les informations pour qu'elles soient illisibles pour les personnes non autorisées. De cette manière, même en cas de fuite de données, les informations sensibles seront protégées.
                      </p>
                      <p>
                        L'utilisation d'un cryptage de niveau militaire garantit la protection des informations sensibles de nos clients, telles que les informations financières, les données personnelles et les informations commerciales. Chez Kease, nous mettons en œuvre des mesures de sécurité supplémentaires pour garantir la protection des informations les plus sensibles.
                      </p>
                      <p>
                        En utilisant notre logiciel d'audit financier, nos clients peuvent être assurés que leurs informations sensibles seront protégées grâce à notre cryptage de niveau militaire. Kease se consacre à offrir la meilleure protection possible pour les données de nos clients, ce qui nous permet de constamment améliorer notre logiciel pour répondre aux besoins de nos clients.
                      </p>
                      <p>
                        Notre engagement en faveur de la protection des données de nos clients montre notre engagement envers la transparence et la sécurité dans l'industrie de l'audit financier. Nous croyons qu'en garantissant la sécurité des informations de nos clients, nous pouvons construire des relations de confiance avec eux et les aider à atteindre leurs objectifs financiers.
                      </p>
                      <p>
                        En conclusion, chez Kease, nous comprenons l'importance de la sécurité des données et nous avons mis en place des mesures de cryptage pour garantir la protection des informations les plus sensibles de nos clients. Nous croyons que la protection des données est un élément crucial pour construire des relations de confiance avec nos clients et les aider à atteindre leurs objectifs financiers.
                      </p>
                    </Card.Text>
                </Card.Body>
                
          </Card>
          <br/>

          <Card className="card border-0 flex-row">
              
              <Card.Body>
                  <Card.Title> <h2>Hébergement en France</h2></Card.Title>
                  <Card.Text>
                    <p>
                      Nous savons que la protection de vos données est une priorité. C'est pourquoi nous nous engageons à héberger vos données en France, en conformité avec les lois locales en matière de protection des données.
                    </p>
                    <p>
                      L'hébergement des données en France est un enjeu majeur pour les entreprises qui souhaitent protéger les informations sensibles de leurs clients. En effet, les entreprises doivent respecter les réglementations en matière de protection des données personnelles, telles que la RGPD. C'est pourquoi de plus en plus de clients exigent que leurs données soient hébergées en France, pour garantir leur confidentialité.
                    </p>
                    <p>
                      Le logiciel Kease, logiciel d'audit financier, répond à ces besoins en proposant un hébergement de données en France, conforme aux recommandations du H3C. Le H3C est un organisme français qui s'engage à protéger les données personnelles en France en veillant à ce que les entreprises respectent les règles de protection des données. En choisissant Kease, les entreprises peuvent être sûres que leurs données seront stockées en France, dans des centres de données sécurisés, conformément aux normes du H3C.
                    </p>
                    <p>
                      L'hébergement des données en France est un avantage supplémentaire pour les entreprises qui utilisent Kease. En effet, en choisissant un logiciel d'audit financier qui propose un hébergement de données en France, les entreprises peuvent se concentrer sur leur cœur de métier sans avoir à se soucier de la protection de leurs données. De plus, en choisissant Kease, les entreprises peuvent être sûres que leurs données seront protégées contre les risques de fuites ou de vols de données, ce qui est important pour la protection de leur réputation.
                    </p>
                    <p>
                      En utilisant Kease, les entreprises peuvent ainsi disposer d'un outil performant pour l'audit financier, tout en bénéficiant d'un hébergement de données en France, conforme aux recommandations du H3C. Cela permet aux entreprises de travailler en toute sérénité, sans avoir à se soucier de la protection de leurs données sensibles.
                    </p>
                    <p>
                      En conclusion, le choix de Kease pour l'audit financier offre de nombreux avantages, notamment en matière de sécurité des données. En proposant un hébergement de données en France, conforme aux recommandations du H3C, Kease assure à ses clients une protection optimale de leurs données personnelles, ce qui est essentiel pour la confiance des entreprises et des clients.
                    </p>
                  </Card.Text>
              </Card.Body>
              <img src={img1} className="card-img-top imgkease" alt="Table de travail"/>
          </Card>
          <br/>

          <Card className="card border-0 flex-row">
              <img src={img1} className="card-img-top imgkease" alt="Table de travail"/>
              <Card.Body>
                  <Card.Title> <h2>Gestion des droits d'accès rigoureuse</h2></Card.Title>
                  <Card.Text>
                    <p>
                      Nous comprenons l'importance de la gestion des droits d'accès pour garantir la sécurité de vos données. C'est pourquoi nous avons mis en place une gestion rigoureuse des droits d'accès pour vous permettre de contrôler qui peut accéder à vos informations.
                    </p>
                    <p>
                      L'utilisation d'un logiciel d'audit financier peut être une tâche complexe, surtout lorsqu'il s'agit de gérer les différents droits d'accès pour chaque mission d'audit. Cependant, avec le logiciel Kease, vous pouvez être assuré d'avoir une gestion personnalisée des droits d'accès pour chaque mission.
                    </p>
                    <p>
                      L'avantage de la gestion personnalisée des droits d'accès est qu'elle permet de garantir la sécurité des données de votre entreprise. En effet, seules les personnes autorisées auront accès aux informations nécessaires à la mission d'audit. De plus, cela facilite également la gestion des tâches, car chacun peut se concentrer sur les tâches qui lui sont attribuées sans avoir à se soucier de la sécurité des données.
                    </p>
                    <p>
                      Avec Kease, vous pouvez définir les droits d'accès pour chaque utilisateur en fonction de leur rôle dans la mission d'audit. Vous pouvez également déterminer les niveaux de visibilité pour chaque utilisateur, ce qui permet de contrôler les informations auxquelles ils ont accès.
                    </p>
                    <p>
                      La gestion personnalisée des droits d'accès est un élément clé de la sécurité des données pour les entreprises qui utilisent un logiciel d'audit financier. Avec Kease, vous pouvez être assuré que vos informations sensibles seront protégées en tout temps. De plus, cela permet de faciliter la collaboration entre les différents membres de l'équipe d'audit.
                    </p>
                    <p>
                      En somme, la gestion personnalisée des droits d'accès proposée par Kease est un atout pour toute entreprise qui cherche à améliorer la sécurité de ses données et à faciliter la collaboration entre les membres de l'équipe d'audit. Alors pourquoi ne pas opter pour le logiciel Kease pour votre prochaine mission d'audit financier ?
                    </p>
                  </Card.Text>
              </Card.Body>
              
          </Card>
          <br/>

        </>
    )
}

export default Securite