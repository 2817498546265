import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from "react"
import { Form, Button, Card, Col, Dropdown, CardGroup, Container, Row } from "react-bootstrap"
import { auth, dbCollection } from "../../utils/firebaseconfig";
import { matchPath, useParams } from "react-router-dom";
import { ref, push, child, update, onValue } from "firebase/database";
import EngagementMenuLeft from "./EngagementMenuLeft";


const EngagementParameters: React.FC = () => {

    return (
        <>
        <Container fluid>
                    
                    <p>Parametres</p>
        </Container>
        </>
    )
}

export default EngagementParameters;

