import React from 'react';
import {  Button, ButtonGroup, Card, CardGroup, Container, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as ReactPlateforme } from '../../assets/plateforme_kease.svg';
import { useLinkNavigation } from '../utils/MenuFunctions';

const Photo: React.FC = () => {
  const { expanded, toggleExpanded, createLink } = useLinkNavigation();
  const handleClick = (useLink: any) => {
    createLink(useLink); // Utilisation de la fonction createLink
  };
  
  return (
    <>
        <div className="bg-kease-right p-5">
        <CardGroup className="mx-auto m-5 d-flex align-items-center justify-content-center card-group-wrapper">
        <Card className="bgtransparent border-0 d-flex justify-content-center m-5 align-items-center">
            <Card.Title className="text-nowrap" >
                <h1 className="text-white">
                  <span className="small">La plateforme pour mener vos</span><br/>
                  <span className="text-cap">missions d'audit financier</span><br/>
                  <span className="small">pour les Commissaires aux Comptes</span>
                </h1>
            </Card.Title>
            <Card.Body className="d-flex align-items-center">
              <ButtonGroup>
                <Button onClick={() => handleClick("contact-us")} className="border-0 btn btn-cap border-cap rounded-kease m-3 text-nowrap">
                  Essayer gratuitement
                </Button>
                <Button onClick={() => handleClick("contact-us")} className="border-2 btn btn-kease border-cap rounded-kease m-3 text-nowrap">
                  Réserver une démo
                </Button>
                
              </ButtonGroup>
            </Card.Body>
        </Card>

        <Card className="bgtransparent border-0 mx-auto p-3 m-3 d-flex justify-content-center align-items-center">
          <ReactPlateforme className="bi bi-cloud-check" width="300" height="300" />
        </Card>
        </CardGroup> 
        </div> 
    </>
  );
};

export default Photo