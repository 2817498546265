import React from "react"
import { Card, Col } from "react-bootstrap";

const Userwaitvalidation: React.FC = () => {



  return (
    <>
    <br/>
        <Card className="w-50 mx-auto" border="primary" >
          <Card.Header>
            <h2 className="text-center">Votre compte est en cours de vérification</h2>
          </Card.Header>
          <br/>
          <Card.Title>
              
          </Card.Title>
          <Card.Body className="text-center">
            <p>Un email a été envoyé à notre équipe.<br/>
             Notre équipe vérifie vos informations.</p>
             <br/>
          </Card.Body>
          <Card.Footer className="text-muted text-center">Il faut compter un maximum de 24h de délai de traitement.</Card.Footer>
        </Card>
        <br/>

    </>
  )
}

export default Userwaitvalidation;