import { Route, Routes } from 'react-router-dom';
import EngagementDashboard from './EngagementDashboard';
import ExcelImport from './dataimport/ExcelImport';
import EngagementTeam from './EngagementTeam';
import EngagementParameters from './EngagementParameters';
import EngagementKnowledge from './works/knowledge/EngagementKnowledge';
import EngagementControleInterne from './works/interim/EngagementInterim';
import EngagementFinal from './works/final/EngagementFinal';
import EngagementReport from './works/opinion/EngagementOpinion';
import DataImport from './dataimport/DataImport';
import BGImport from './dataimport/BGImport';


const EngagementRouter: React.FC = () => {

    return (
        <>
            <Routes>
                <Route path="/dashboard" element={<EngagementDashboard/>}/>
                <Route path="/import" element={<DataImport />}/>
                <Route path="/import-balance" element={<BGImport />}/>

                <Route path="/planification" element={<EngagementKnowledge />}/>
                <Route path="/planification/prise-de-connaissance" element={<EngagementKnowledge />}/>
                <Route path="/planification/prise-de-connaissance/juridique" element={<EngagementKnowledge />}/>
                <Route path="/planification/prise-de-connaissance/organigramme" element={<EngagementKnowledge />}/>
                <Route path="/planification/prise-de-connaissance/historic" element={<EngagementKnowledge />}/>
                <Route path="/planification/prise-de-connaissance/secteur" element={<EngagementKnowledge />}/>
                <Route path="/planification/prise-de-connaissance/kpi" element={<EngagementKnowledge />}/>
                <Route path="/planification/prise-de-connaissance/budget" element={<EngagementKnowledge />}/>
                <Route path="/planification/strategie/maintien-mandat" element={<EngagementKnowledge />}/>
                <Route path="/planification/strategie/lettre-de-mission" element={<EngagementKnowledge />}/>
                <Route path="/planification/strategie/independance" element={<EngagementKnowledge />}/>
                <Route path="/planification/strategie/experience-avec-entité" element={<EngagementKnowledge />}/>
                <Route path="/planification/strategie/evenements-significatifs" element={<EngagementKnowledge />}/>
                <Route path="/planification/strategie/nouveaux-standards" element={<EngagementKnowledge />}/>
                <Route path="/planification/strategie/seuils" element={<EngagementKnowledge />}/>
                <Route path="/planification/strategie/financement-terrorisme" element={<EngagementKnowledge />}/>
                <Route path="/planification/strategie/utilisation-experts" element={<EngagementKnowledge />}/>
                <Route path="/planification/strategie/repartition-des-travaux" element={<EngagementKnowledge />}/>
                <Route path="/planification/strategie/fraudes-et-erreurs" element={<EngagementKnowledge />}/>
                <Route path="/planification/strategie/parties-liees" element={<EngagementKnowledge />}/>
                <Route path="/planification/strategie/informations-communiquees-par-l-entite" element={<EngagementKnowledge />}/>
                <Route path="/planification/strategie/calendrier" element={<EngagementKnowledge />}/>
                <Route path="/planification/strategie/parties-liees" element={<EngagementKnowledge />}/>
                <Route path="/planification/evaluation-des-risques/risques-significatifs" element={<EngagementKnowledge />}/>
                <Route path="/planification/evaluation-des-risques/estimations" element={<EngagementKnowledge />}/>
                <Route path="/planification/evaluation-des-risques/anomalies-significatives" element={<EngagementKnowledge />}/>
                <Route path="/planification/evaluation-des-risques/controle-interne-exercice-precedent" element={<EngagementKnowledge />}/>
                <Route path="/planification/prise-de-connaissance/contacts" element={<EngagementKnowledge />}/>

                <Route path="/interim" element={<EngagementControleInterne />}/>
                <Route path="/final" element={<EngagementFinal />}/>
                <Route path="/opinion" element={<EngagementReport />}/>
                <Route path="/team" element={<EngagementTeam />}/>
                <Route path="/parametres" element={<EngagementParameters />}/>
            </Routes>
        </>
    );
}

export default EngagementRouter;
