import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from "react"
import { Form, Button, Card, Col, Dropdown, CardGroup, Container, Row } from "react-bootstrap"
import { useNavigate } from "react-router-dom";
import img1 from "../../../assets/table-working.jpg"


const Suivi: React.FC = () => {
    
    const navigate = useNavigate();
    
    function Createlink(linky:any){
    navigate('/' + linky, { replace: true });
    }

    return (
        <>
            <h1>Suivi en temps réel de l'avancée de vos missions grâce à notre logiciel d'audit financier</h1>
            <p>Notre logiciel d'audit financier offre un moyen rapide et efficace de suivre l'avancement de vos missions en temps réel. Grâce à cet outil, vous pouvez facilement faire un point sur l'état de vos projets et communiquer avec vos équipes sans délai.</p>
            
            <Card className="card border-0 flex-row">
                <img src={img1} className="card-img-top imgkease" alt="Table de travail"/>
                <Card.Body>
                    <Card.Title> <h2>Les avantages du suivi en temps réel de vos missions</h2></Card.Title>
                    <Card.Text>
                        <ul>
                            <li>Une vue d'ensemble complète de l'avancement de vos projets</li>
                            <li>Une communication en temps réel avec vos équipes</li>
                            <li>Une réduction du temps et des efforts consacrés à la coordination</li>
                        </ul>
                        <p>
                            Nous garantissons la sécurité de vos données en les cryptant à l'aide d'algorithmes de chiffrement de haut niveau. Vous pouvez être sûr que vos informations resteront privées et protégées.
                        </p>
                        <p>
                            L'avancement en temps réel est un critère important pour le suivi des missions d'audit. Les entreprises cherchent à maximiser la qualité et l'efficacité de leur travail, et le logiciel Kease propose une solution optimale pour suivre les avancements de chaque mission d'audit.
                        </p>
                        <p>
                            Avec le suivi en temps réel, les entreprises peuvent surveiller les progrès de chaque mission en temps réel, ce qui permet une meilleure planification et une allocation plus efficace des ressources. En outre, les entreprises peuvent également suivre l'avancement des différentes tâches liées à chaque mission et prendre des mesures pour résoudre rapidement les problèmes éventuels.
                        </p>
                        <p>
                            En utilisant le logiciel Kease, les entreprises peuvent également mieux comprendre les tendances du travail et les problèmes qui se posent fréquemment. Cela permet de mieux identifier les zones à améliorer et d'adopter une approche proactive pour garantir le succès des missions d'audit.
                        </p>
                        <p>
                            De plus, le suivi en temps réel offre également une transparence totale pour les clients. Les entreprises peuvent partager les données de suivi avec leurs clients pour montrer les progrès réalisés sur chaque mission d'audit, ce qui peut renforcer la confiance et la collaboration entre les parties.
                        </p>
                        <p>
                            En conclusion, le suivi en temps réel de l'avancement des missions d'audit est un avantage crucial pour les entreprises. Le logiciel Kease offre une solution complète pour le suivi des missions d'audit, permettant aux entreprises d'optimiser leur travail et d'assurer la qualité et l'efficacité de chaque mission.
                        </p>

                    </Card.Text>
                </Card.Body>
                
            </Card>
            <br/>
            
            <Card className="card border-0 flex-row">

                <Card.Body>
                    <Card.Title> <h2>Comment notre logiciel d'audit financier peut vous aider</h2></Card.Title>
                    <Card.Text>
                    <p>Notre logiciel d'audit financier utilise les dernières technologies pour fournir une expérience de suivi en temps réel fluide et intuitive. Il offre des fonctionnalités telles que :</p>
                        <ul>
                            <li>La mise à jour automatique des données en temps réel</li>
                            <li>L'accès à des données en temps réel depuis n'importe où</li>
                            <li>Une interface utilisateur facile à utiliser</li>
                        </ul>
                        <p>
                            Le logiciel Kease, logiciel d'audit financier, est un outil incroyablement précieux pour les entreprises qui cherchent à optimiser leur processus d'audit. Ce qui le distingue des autres outils similaires sur le marché, c'est son engagement envers la mise à jour automatique des données en temps réel, l'accès à ces données depuis n'importe où et son interface utilisateur facile à utiliser.
                        </p>
                        <p>
                            Tout d'abord, la mise à jour automatique des données en temps réel permet aux entreprises d'être toujours informées des dernières informations et d'avoir une vue complète sur leurs finances. Cela signifie également qu'il n'y a pas de temps d'arrêt pour la mise à jour manuelle des données et que les entreprises peuvent agir rapidement sur les informations qu'elles reçoivent.
                        </p>
                        <p>
                            Ensuite, l'accès à des données en temps réel depuis n'importe où est extrêmement pratique pour les entreprises qui ont des employés en déplacement ou travaillant à distance. Ils peuvent accéder aux informations dont ils ont besoin depuis leur ordinateur portable, leur téléphone ou tout autre appareil connecté à Internet, ce qui leur permet de travailler efficacement même en dehors de l'entreprise.
                        </p>
                        <p>
                            Enfin, Kease a une interface utilisateur facile à utiliser qui rend l'accès aux informations simple et intuitif. Cela signifie qu'il n'y a pas besoin de formation coûteuse pour les employés pour comprendre comment utiliser le logiciel, ce qui permet aux entreprises de gagner du temps et de l'argent.
                        </p>
                        <p>
                            En conclusion, Kease est un logiciel d'audit financier de premier plan qui offre un moyen efficace et pratique de suivre les finances de l'entreprise. Avec sa mise à jour automatique des données en temps réel, son accès à des données depuis n'importe où et son interface utilisateur facile à utiliser, Kease est un choix judicieux pour toutes les entreprises qui cherchent à optimiser leur processus d'audit.
                        </p>
                              
                    </Card.Text>
                </Card.Body>
                <img src={img1} className="card-img-top imgkease" alt="Table de travail"/>
            </Card>
            <br/>
            
            <p>Pour en savoir plus sur comment notre logiciel d'audit financier peut vous aider à suivre l'avancement de vos missions en temps réel, <strong><a onClick={(e:any) => {Createlink('contact-us')}}>contactez-nous</a></strong> dès aujourd'hui.
            </p>
        </>
    )
}

export default Suivi