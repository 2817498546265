import { Container, Navbar } from 'react-bootstrap';

import Features from './Features';
import Photo from './Photo';
import Contact from './Contact';
import Prices from './Prices';
import References from './References';

const Onepage: React.FC = () => {

  return (
    <>
    <Container fluid className="p-0">
      <Photo/>
      <References/>
    </Container>
      <Features/>
      <Prices/>
      <Contact/>
    
    </>
  );
};

export default Onepage