import '../../index.css';
import PublicMenu from './PublicMenu';
import PrivateMenu from './PrivateMenu';
import { useContext } from 'react';
import UserContext from '../../utils/usercontext';

interface Props {
    activePage: string; // Valeur de la page active
    createLink: (link: string) => void; // Fonction pour créer un lien
  }

const MenuItems : React.FC<Props> = ({ activePage, createLink }) => {
    const { user } = useContext(UserContext);

    if (!user) {
        // Redirigez vers la page de connexion si l'utilisateur n'est pas connecté
        return <PublicMenu activePage={activePage} createLink={createLink} />;

    } else {
        // Redirigez vers une page non autorisée pour les autres rôles d'utilisateur
        return <PrivateMenu activePage={activePage} createLink={createLink}  />;
    }

};

export default MenuItems


