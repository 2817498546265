import { useEffect, useState } from 'react';
import AppRouter from './utils/approuter/Approuter';
import AppFooter from './components/appfooter/AppFooter';
import { onAuthStateChanged } from 'firebase/auth';
import { auth, dbCollection } from './utils/firebaseconfig';
import UserContext from './utils/usercontext';
import { onValue, ref } from 'firebase/database';
import MenuItems from './components/appheader/MenuItems';
import { useLinkNavigation } from './components/utils/MenuFunctions';


const App: React.FC = () => {

  const [user, setUser] = useState<any>(null);
  const [userProfile, setUserProfile] = useState<any>(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // L'utilisateur est connecté, mettre à jour le contexte avec les informations de l'utilisateur
        const userProfileRef = ref(dbCollection, 'users/' + auth.currentUser.uid);
  
        onValue(userProfileRef, (snapshot) => {
            const userProfileData = snapshot.val();
            setUserProfile(userProfileData);
        });
        setUser(user);
      }
    });
    // Nettoyage de l'écouteur lors du démontage du composant
    return () => unsubscribe();
  }, []);
  
  const { activePage, createLink } = useLinkNavigation();

  return (

        <UserContext.Provider value={{ user, userProfile, setUser, setUserProfile }}>

          <header className="App-header mx-auto">  

            <MenuItems activePage={activePage} createLink={createLink} />

          </header>

          <AppRouter activePage={activePage} createLink={createLink}/>
          
          <AppFooter activePage={activePage} createLink={createLink}/>

        </UserContext.Provider>

    
  );
}

export default App;
