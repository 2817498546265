import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from "react"
import { Form, Button, Card, Col, Dropdown, CardGroup, Container, Row } from "react-bootstrap"
import img1 from "../../../assets/table-working.jpg"


const Assistance: React.FC = () => {
    


    return (
        <>
            <h1>Concentrez-vous sur votre métier avec notre assistance téléphonique dédiée</h1>
            <p>Notre assistance téléphonique dédiée vous offre un soutien personnalisé pour que vous puissiez vous concentrer sur ce que vous savez faire de mieux. Nous sommes là pour vous aider à résoudre tout problème technique ou pour répondre à toutes vos questions.</p>
            
            <Card className="card border-0 flex-row">
                <img src={img1} className="card-img-top imgkease" alt="Table de travail"/>
                <Card.Body>
                    <Card.Title> <h2>Soutien personnalisé</h2></Card.Title>
                    <Card.Text>
                    <p>
                        Notre équipe d'assistance est formée pour répondre à toutes vos questions et résoudre tout problème technique en temps réel. Nous sommes là pour vous aider à chaque étape du processus.
                    </p>
                    </Card.Text>
                </Card.Body>
                
            </Card>
            <br/>
            
            <Card className="card border-0 flex-row">
                
                <Card.Body>
                    <Card.Title> <h2>Disponibilité 24/7</h2></Card.Title>
                    <Card.Text>
                    <p>
                        Notre assistance téléphonique est disponible 24 heures sur 24 et 7 jours sur 7 pour répondre à toutes vos questions ou pour résoudre tout problème technique. Nous sommes toujours là pour vous aider.
                    </p>
                    </Card.Text>
                </Card.Body>
                <img src={img1} className="card-img-top imgkease" alt="Table de travail"/>
            </Card>
            <br/>

            <Card className="card border-0 flex-row">
                <img src={img1} className="card-img-top imgkease" alt="Table de travail"/>
                <Card.Body>
                    <Card.Title> <h2>Temps de réponse rapide</h2></Card.Title>
                    <Card.Text>
                    <p>
                        Nous savons que chaque minute compte lorsqu'il s'agit de résoudre un problème technique. C'est pourquoi nous avons un temps de réponse rapide pour vous assurer que votre question ou votre problème sera résolu le plus rapidement possible.
                    </p>
                    </Card.Text>
                </Card.Body>
                
            </Card>
            <br/>

        </>
    )
}

export default Assistance